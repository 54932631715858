import type { ActionsEnum } from 'types-shared/extensionTypes';
import { VariableTypeEnum } from 'types-shared/workflowTypes';
import type {
  WorkflowActionsOptions,
  Target,
  TemplateVariable,
  WorkflowActionV2Base,
} from 'types-shared/workflowTypes';
import { v4 as uuid } from 'uuid';
import { createTarget } from '../../../utils/helper';

export const createBaseV2Action = (
  actionType: ActionsEnum,
  legacyOptions?: WorkflowActionsOptions,
): {
  variable: TemplateVariable; // Preview variable
  target: Target; // Dummy target for backwards compatibility
  action: WorkflowActionV2Base;
} => {
  const target = createTarget(uuid());
  const variable: TemplateVariable = {
    id: uuid(),
    data: ['Not Set'],
    type: VariableTypeEnum.Template,
  };
  const baseV2Action: WorkflowActionV2Base = {
    id: uuid(),
    actionType,
    actionVersion: '2.0',
    // MARK: Backwards compatibility
    targetId: target.id,
    variableId: variable.id,
    options: legacyOptions,
  };

  return {
    action: baseV2Action,
    target,
    variable,
  };
};
