"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toggleContextsSchema = void 0;
var types_shared_1 = require("types-shared");
exports.toggleContextsSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
        body: types_shared_1.z.object({
            enabled: types_shared_1.z.boolean(),
            clear: types_shared_1.z.boolean().optional(),
        }),
    }),
    response: types_shared_1.z.object({
        success: types_shared_1.z.boolean(),
    }),
};
