import values from 'lodash/values';
import omit from 'lodash/omit';
import { useEffect, useMemo, useState, useRef, useCallback } from 'react';

import {
  type DocumentVariable,
  QueryValueTypeEnum,
  QueryVariable,
  VariableTypeEnum,
  type Variable,
  type VariableMap,
} from 'types-shared';
import {
  Button,
  Input,
  Switch,
  CheckCircleIcon,
  IconButton,
  Spinner,
  SendIcon,
  DocumentIcon,
  CloseIcon,
} from 'ui-kit';
import { EditorStore } from '../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { v4 as uuid } from 'uuid';
import { VariableChip } from '../../../../../components/VariableChip';
import { clsx } from 'clsx';
import * as pdfjsLib from 'pdfjs-dist';
import { type RenderPdfProps } from './AnnotatedPDF';
import {
  useResolveLinearizedFile,
  useTransformWithQuery,
  useTransformApiReqAlt,
} from '../../../hooks';
import { LinearProgress } from '@mui/material';
import { formatBlobFileSize } from '../../../utils/helper';
import { useFeatureFlag } from '../../../../../utils/helper';
import { FeatureFlag } from '../../../../../utils/constants';

pdfjsLib.GlobalWorkerOptions.workerSrc = `${window.location.origin}/pdf.worker.min.mjs`;

const defaultVariable: QueryVariable = {
  name: '',
  type: VariableTypeEnum.Query,
  id: uuid(),
  data: {
    query: [''],
    sourceIds: [],
    valueType: QueryValueTypeEnum.String,
  },
};

const linkVariableToSource = (
  sourceId: string,
  variable: QueryVariable,
): QueryVariable => {
  return {
    ...variable,
    data: {
      ...variable.data,
      sourceIds: [sourceId],
    },
  };
};

interface DocumentVariablesProps {
  fileBlob?: Blob;
  variable: Variable;
  variablesMap: VariableMap;
  fileId: string;
  setAnnotatedPDFData: React.Dispatch<
    React.SetStateAction<RenderPdfProps | undefined>
  >;
  onSwapFile?: () => void;
  updateVariable: (variable: Variable) => void;
}

export default function DocumentVariables({
  fileBlob,
  variable,
  fileId,
  setAnnotatedPDFData,
  onSwapFile,
  updateVariable,
}: DocumentVariablesProps) {
  const initialisedRef = useRef(false);
  const [promptChanged, setPromptChanged] = useState(false);
  const [promptResult, setPromptResult] = useState<string>();
  const [linearizedFileResult, setLinearizedFileResult] = useState<{
    url: string;
    status: 'error' | 'processing' | 'ready';
    error?: string;
  } | null>(null);
  const [promptData, setPromptData] = useState<string>();
  const { mutateAsync: getLinearizedFileUrl, status } =
    useResolveLinearizedFile();
  const {
    mutateAsync: getTransformationResponse,
    status: transformationStatus,
  } = useTransformWithQuery();
  const {
    mutateAsync: getTransformationResponseAlt,
    status: transformationStatusAlt,
  } = useTransformApiReqAlt();

  const isTemporalProcessingEnabled = useFeatureFlag(
    FeatureFlag.TemporalDocumentProcessing,
    false,
  );

  const processingIsDone =
    linearizedFileResult?.status === 'ready' ||
    linearizedFileResult?.status === 'error' ||
    linearizedFileResult?.url;

  const isProcessing = !processingIsDone || status === 'pending';

  const isProcessingQuery = isTemporalProcessingEnabled
    ? transformationStatus === 'pending'
    : transformationStatusAlt === 'pending';

  const handleGetLinearizedFileUrl = useCallback(
    async (fileIdReceived: string) => {
      const result = await getLinearizedFileUrl({ fileId: fileIdReceived });
      setLinearizedFileResult(result);

      if (result.url) {
        try {
          // Fetch the content of the .txt file
          const fileResponse = await fetch(result.url);
          if (!fileResponse.ok) {
            throw new Error(`Failed to fetch file: ${fileResponse.statusText}`);
          }
          const content = await fileResponse.text();

          // Update result with file content
          setPromptData(content);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching .txt file:', error);
        }
      }
    },
    [getLinearizedFileUrl],
  );

  const { variablesMap, addVariables, deleteVariable } = EditorStore(
    useShallow((s) => ({
      variablesMap: s.variables,
      addVariables: s.addVariables,
      deleteVariable: s.deleteVariable,
    })),
  );

  const queryVariables = useMemo(() => {
    return values(variablesMap).filter(
      (_variable) =>
        QueryVariable.safeParse(_variable).success &&
        (_variable as QueryVariable).data.sourceIds.includes(variable.id),
    );
  }, [variablesMap, variable]);
  const queryVariablesMap = useMemo(() => {
    return queryVariables.reduce((acc: VariableMap, v) => {
      acc[v.id] = v;
      return acc;
    }, {});
  }, [queryVariables]);
  const [localVariablesMap, setLocalVariablesMap] =
    useState<VariableMap>(queryVariablesMap);
  const [isEditingVariable, setIsEditingVariable] = useState<boolean>(false);
  const [newVariable, setNewVariable] =
    useState<QueryVariable>(defaultVariable);
  const [useVariablesAsOutputs, setUseVariablesAsOutputs] =
    useState<boolean>(true);

  const isNewVariable = !(newVariable.id in localVariablesMap);

  const query = newVariable.data.query[0] as string;

  const handleGetTransformationResponse = useCallback(
    async (newPromptData: string) => {
      try {
        setAnnotatedPDFData((prev) => ({
          ...prev,
          processingQuery: true,
        }));

        let result;
        if (isTemporalProcessingEnabled) {
          result = await getTransformationResponse({
            data: newPromptData,
            prompt: query,
            fileId,
            variableId: newVariable.id,
          });
        } else {
          result = await getTransformationResponseAlt({
            data: newPromptData,
            prompt: query,
          });
          result = result?.processedData;
        }

        setPromptResult(result);

        if (result) {
          setNewVariable((prev) => ({
            ...prev,
            dashboardData: {
              transformInputs: {
                query: [query],
                transformedValue: result,
              },
              initialValue: newPromptData,
            },
          }));
        }
        setAnnotatedPDFData((prev) => ({
          ...prev,
          processingQuery: false,
        }));
      } catch (error) {
        setPromptResult('Invalid query');
        setAnnotatedPDFData((prev) => ({
          ...prev,
          processingQuery: false,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getTransformationResponse,
      getTransformationResponseAlt,
      query,
      fileId,
      newVariable.id,
      isTemporalProcessingEnabled,
    ],
  );

  const allowSubmit = useMemo(() => {
    return (
      newVariable.name && newVariable.data.query.length > 0 && promptResult
    );
  }, [newVariable, promptResult]);

  const resetForm = () => {
    defaultVariable.id = uuid();
    setNewVariable(linkVariableToSource(variable.id, defaultVariable));
    setPromptResult('');
  };

  const clearVariablePrompts = () => {
    const localVariablesArray = Object.values(
      localVariablesMap,
    ) as QueryVariable[];
    const updatedArray = localVariablesArray.map((localVariable) => ({
      ...localVariable,
      dashboardData: {
        ...variable.dashboardData,
        initialValue: '',
        transformInputs: {
          query: localVariable.data.query,
          transformedValue: '',
        },
      },
    }));

    // Update zustand state
    updatedArray.forEach((localVariable) => {
      updateVariable(localVariable);
    });

    const updatedVariablesMap = localVariablesArray.reduce(
      (acc: VariableMap, v) => {
        acc[v.id] = v;
        return acc;
      },
      {},
    );

    setLocalVariablesMap(updatedVariablesMap);
  };

  const handleSwapFile = () => {
    onSwapFile?.();
    resetForm();
    clearVariablePrompts();
  };

  const onSubmit = () => {
    setLocalVariablesMap((prev) => ({
      ...prev,

      [newVariable.id]: linkVariableToSource(variable.id, newVariable),
    }));

    if (!isNewVariable) {
      updateVariable(newVariable);
    }

    resetForm();
  };

  const onSaveChanges = () => {
    const originalVariableIds = queryVariables.map((v) => v.id);
    const localVariableIds = values(localVariablesMap).map((v) => v.id);
    if (!localVariableIds.includes(newVariable.id) && allowSubmit) {
      localVariableIds.push(newVariable.id);
      localVariablesMap[newVariable.id] = linkVariableToSource(
        variable.id,
        newVariable,
      );
    }
    const deletedVariables = originalVariableIds.filter(
      (id) => !localVariableIds.includes(id),
    );
    deletedVariables.forEach((id) => {
      deleteVariable(id);
    });
    addVariables(localVariablesMap);
    resetForm();
    setIsEditingVariable(false);
  };

  const onCancel = () => {
    setLocalVariablesMap(queryVariablesMap);
    resetForm();
    setIsEditingVariable(false);
  };

  const fileName = useMemo(() => {
    return (variable as DocumentVariable).data.s3Ref?.fileName ?? 'File name';
  }, [variable]);

  const fileSize = useMemo(() => {
    return fileBlob?.size ? formatBlobFileSize(fileBlob.size) : 'N/A';
  }, [fileBlob]);

  const handleVariableClick = (_variable: Variable) => {
    if (isProcessingQuery) return;
    const queryVariable = _variable as QueryVariable;

    if (
      newVariable.name &&
      newVariable.data.query.length > 0 &&
      Boolean(variablesMap[newVariable.id])
    ) {
      // update the variable in the local variables map if it exists already
      setLocalVariablesMap((prev) => ({
        ...prev,
        [newVariable.id]: linkVariableToSource(variable.id, newVariable),
      }));
    }

    setNewVariable(queryVariable);
    const _oldPrompt =
      queryVariable.dashboardData?.transformInputs?.transformedValue ?? '';
    setPromptResult(_oldPrompt as string);
  };

  useEffect(() => {
    if (initialisedRef.current) return;
    initialisedRef.current = true;
    const container = document.getElementById(
      'selected-image-node-content-container',
    );
    if (!container || !fileBlob) return;

    setAnnotatedPDFData({ fileBlob, annotations: [] });

    return () => {
      const canvasElements = document.querySelectorAll(
        '#selected-image-node-content-container > canvas',
      );

      canvasElements.forEach((canvas) => {
        canvas.remove();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAnnotatedPDFData((prev) => ({
      ...prev,
      isProcessing,
    }));
  }, [isProcessing, setAnnotatedPDFData]);

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout | null = null;

    const fetchFileUrl = async () => {
      if (fileId) {
        await handleGetLinearizedFileUrl(fileId);
      }
    };

    const _processingIsDone =
      linearizedFileResult?.status === 'ready' ||
      linearizedFileResult?.status === 'error' ||
      linearizedFileResult?.url;

    if (fileId && !_processingIsDone) {
      // Start polling
      void fetchFileUrl(); // Fetch immediately
      pollingInterval = setInterval(async () => {
        await fetchFileUrl();
      }, 4000);
    }

    return () => {
      // Cleanup polling on unmount or when fileId changes
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [fileId, handleGetLinearizedFileUrl, linearizedFileResult]);

  useEffect(() => {
    const _oldPrompt =
      newVariable.dashboardData?.transformInputs?.transformedValue;

    if (_oldPrompt && !promptResult) {
      setPromptResult(_oldPrompt as string);
    }
    if (promptResult && promptResult !== _oldPrompt) {
      setNewVariable(
        (prev) =>
          ({
            ...prev,
            dashboardData: {
              ...prev.dashboardData,
              transformInputs: {
                ...prev.dashboardData?.transformInputs,
                transformedValue: promptResult,
              },
            },
          }) as QueryVariable,
      );
    }
  }, [newVariable, promptResult]);

  return isEditingVariable ? (
    <div className="absolute top-0 left-0 w-full h-full bg-white z-[9] flex flex-col">
      <span className="text-lg font-medium text-[#103D61]">
        Create variables from the document
      </span>
      <span className="mt-4 text-sm text-[#7A859C]">
        Use our AI models to query values from the scraped document and create
        variables
      </span>

      <Input
        classes={{ wrapper: 'flex flex-col mt-8' }}
        floatingLabel
        label="Variable name"
        onChange={(variableName: string) => {
          setNewVariable((prev) => ({
            ...prev,
            name: variableName,
          }));
        }}
        placeholder="Add a name"
        value={newVariable.name}
      />

      <div className="relative mt-8">
        <Input
          floatingLabel
          label="Instructions to extract variables from the document"
          multiline
          onChange={(q: string) => {
            setPromptChanged(true);
            setNewVariable((prev) => ({
              ...prev,
              data: {
                ...prev.data,
                query: [q],
              },
            }));
          }}
          placeholder="Ex.: What is the client name?"
          rows={5}
          value={newVariable.data.query[0]}
        />

        <IconButton
          className={clsx({
            '!absolute top-2 right-0': true,
            'opacity-50': !newVariable.data.query[0],
          })}
          disabled={
            isProcessing || isProcessingQuery || !newVariable.data.query[0]
          }
          onClick={() => {
            setPromptChanged(false);
            if (promptData) {
              void handleGetTransformationResponse(promptData);
            }
          }}
        >
          {isProcessingQuery ? <Spinner size={16} /> : null}
          {!isProcessingQuery && promptResult && !promptChanged ? (
            <CheckCircleIcon className="text-transparent" />
          ) : null}
          {(!isProcessingQuery && !promptResult) || promptChanged ? (
            <SendIcon className="text-white" />
          ) : null}
        </IconButton>
      </div>

      <div className="flex flex-col space-y-1 mt-8">
        <span className="text-sm text-[#103D61]">Example variable result</span>

        <div
          className={clsx('mt-1 p-[1px] !rounded bg-white', {
            '!bg-gradient-to-r from-primary-blue to-primary-purple !p-0.5':
              promptResult,
            '!bg-[#e6e7eb]': !promptResult,
          })}
        >
          <div className="!rounded-sm p-3 min-h-[2.75rem] break-words bg-white whitespace-pre-wrap">
            {promptResult ? (
              promptResult
            ) : (
              <span className="text-neutral-400">queried value example</span>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between !mt-6 !mb-10">
        <Button
          className="!text-sm !max-w-max"
          color="secondary"
          variant="outlined"
          disabled={!allowSubmit}
          onClick={onSubmit}
        >
          {isNewVariable ? 'Create variable' : 'Save changes'}
        </Button>
        {!isNewVariable ? (
          <Button
            className="!text-sm !max-w-max"
            color="error"
            variant="outlined"
            onClick={() => {
              setLocalVariablesMap((prev) => {
                return omit(prev, newVariable.id);
              });
              resetForm();
            }}
          >
            Delete variable
          </Button>
        ) : null}
      </div>
      <span className="w-[calc(100%+4rem)] bg-[#BDCAD5] h-[1px] mx-[-2rem]" />
      <div className="flex flex-col py-8">
        <span className="text-sm font-medium text-[#103D61]">
          Created variables
        </span>
        <div className="flex flex-wrap gap-4 mt-9">
          {values(localVariablesMap).map((_variable) => (
            <VariableChip
              key={_variable.id}
              className={clsx('!bg-secondary-purple', {
                '!ring-4 !ring-[#C880D4]': newVariable.id === _variable.id,
              })}
              leftIconClassName="!hidden"
              showEditIcon
              variableId={_variable.id}
              variablesMap={localVariablesMap}
              globalVariablesMap={{}}
              onClick={() => {
                handleVariableClick(_variable);
              }}
            />
          ))}
        </div>
      </div>
      <div className="flex items-center space-x-4 mt-auto">
        <Button
          className="!flex-1"
          color="secondary"
          variant="contained"
          onClick={onSaveChanges}
        >
          Save changes
        </Button>
        <Button
          className="!flex-1"
          color="secondary"
          variant="outlined"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  ) : (
    <div className="flex flex-col mt-7 border border-[#BDCAD5] rounded-lg">
      <div className="p-6 pb-8 flex flex-col">
        <span className="text-sm font-medium text-[#103D61]">
          Create variables from the document
        </span>
        <span className="text-sm text-[#7A859C]">
          Use our AI models to query values from the scraped document and create
          variables
        </span>

        <div className="flex flex-col gap-2 border-2 border-[#e9eff3] rounded-lg p-4 mt-5 relative">
          <div className="flex flex-row gap-2 justify-between">
            <div className="flex flex-row gap-2">
              <DocumentIcon className="text-[#103D61] !w-7 !h-7" />{' '}
              <div>
                <p className="text-sm font-medium text-[#103D61]">{fileName}</p>
                <p className="text-xs text-[#7A859C]">{fileSize}</p>
              </div>
            </div>

            <div className="absolute top-0 right-0">
              <IconButton color="secondary" onClick={handleSwapFile}>
                <CloseIcon className="text-info" />
              </IconButton>
            </div>
          </div>

          <LinearProgress
            variant={isProcessing ? 'indeterminate' : 'determinate'}
            value={100}
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#2296f3',
              },
              backgroundColor: '#a6d5fa',
            }}
          />
        </div>
        {queryVariables.length ? (
          <div className="flex flex-wrap gap-4 mt-8">
            {queryVariables.map((_variable) => (
              <VariableChip
                key={_variable.id}
                className="!bg-secondary-purple"
                leftIconClassName="!hidden"
                variableId={_variable.id}
                variablesMap={variablesMap}
                globalVariablesMap={{}}
                onClick={() => {
                  setIsEditingVariable(true);
                  handleVariableClick(_variable);
                }}
              />
            ))}
          </div>
        ) : null}
        <Button
          className="!text-sm !mt-8 !max-w-max"
          color="secondary"
          variant="outlined"
          disabled={isProcessing}
          onClick={() => {
            setIsEditingVariable(true);
          }}
        >
          {queryVariables.length ? 'Edit or ' : ''}Create variables
        </Button>
      </div>
      <span className="w-full bg-[#BDCAD5] h-[1px]" />
      <div className="flex justify-between items-center p-6">
        <span className="text-sm font-medium text-[#103D61]">
          Surface variables as outputs
        </span>
        <Switch
          color="secondary"
          checked={useVariablesAsOutputs}
          onChange={() => {
            setUseVariablesAsOutputs((prev) => !prev);
          }}
        />
      </div>
    </div>
  );
}
