import { type Variable, type WorkflowTemporalNode } from 'types-shared';
// TODO: Move to a shared helper file
import { createTemplateVariable } from './request.helpers';

export type WorkflowTemporalNodeCoreData = Omit<
  WorkflowTemporalNode['data'],
  'nodeStatus' | 'selected'
>;

export const initialTemporalBlockData = (
  addVariable: (variable: Variable) => void,
): WorkflowTemporalNodeCoreData => {
  return {
    workflowType: { variableId: createTemplateVariable(addVariable).id },
    taskQueue: { variableId: createTemplateVariable(addVariable).id },
    workflowArgs: [],
  };
};

export const getTemporalNodeTitle = (node: WorkflowTemporalNode) => {
  if (node.name) return node.name;
  return 'Temporal workflow step';
};

export const getTemporalBlockTitle = (
  node: WorkflowTemporalNode,
  short?: boolean,
) => {
  const initial = 'Temporal workflow step';
  if (short) return initial;
  if (node.name) return `${initial}: ${node.name}`;
  return initial;
};

export const getTemporalNodeDescription = () => {
  return 'Execute a Temporal workflow';
};
