import {
  VariableTypeEnum,
  type ExecutionVariable,
  ExecutionVariableNameEnum,
  EmailTriggerVariableEnum,
} from 'types-shared';
import { MarkerType } from 'types-shared/reactflow';
import { v4 as uuid } from 'uuid';

export const defaultMarker = {
  type: MarkerType.Arrow,
  width: 10,
  height: 10,
  strokeWidth: 2,
  color: '#000',
};

export const editorMaxZoomLevel = 5;
export const zoomLevelForNode = 2.5;
export const editorMinZoomLevel = 0.1;
export const minZoomLevelForFullContent = 0.4;

export const CONTACT_SLACK_CHANNEL_ID = 'C06G8CTGLLD';

export const DEFAULT_ZOOM = 0.7;

const executionVariableIds = new Array(6).fill(0).map((_) => uuid());

export const executionVariableSubTypeMapping: Record<string, string> = {
  [ExecutionVariableNameEnum.ExecutionId]: 'String',
  [ExecutionVariableNameEnum.ExecutionAttempt]: 'String',
  [ExecutionVariableNameEnum.TodayDate]: 'Date',
  [ExecutionVariableNameEnum.YesterdayDate]: 'Date',
  [ExecutionVariableNameEnum.PreviousWeekdayDate]: 'Date',
  [ExecutionVariableNameEnum.StatusDescription]: 'String',
};

export const executionVariableTitleMapping: Record<string, string> = {
  [ExecutionVariableNameEnum.ExecutionId]: 'Execution ID',
  [ExecutionVariableNameEnum.ExecutionAttempt]: 'Execution Attempt #',
  [ExecutionVariableNameEnum.TodayDate]: `Date: Today`,
  [ExecutionVariableNameEnum.YesterdayDate]: `Date: Yesterday`,
  [ExecutionVariableNameEnum.PreviousWeekdayDate]: `Date: Previous Weekday`,
  [ExecutionVariableNameEnum.StatusDescription]: 'Status Description',
};

export const emailTriggerVariableNames = [
  EmailTriggerVariableEnum.Subject,
  EmailTriggerVariableEnum.Body,
  EmailTriggerVariableEnum.EmailData,
  EmailTriggerVariableEnum.Attachments,
];

export const executionVariableMap: Record<string, ExecutionVariable> = {
  [executionVariableIds[0]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.ExecutionId,
    id: executionVariableIds[0],
    data: [],
  },
  [executionVariableIds[1]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.ExecutionAttempt,
    id: executionVariableIds[1],
    data: [],
  },
  [executionVariableIds[2]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.TodayDate,
    id: executionVariableIds[2],
    data: [],
  },
  [executionVariableIds[3]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.YesterdayDate,
    id: executionVariableIds[3],
    data: [],
  },
  [executionVariableIds[4]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.PreviousWeekdayDate,
    id: executionVariableIds[4],
    data: [],
  },
  [executionVariableIds[5]]: {
    type: VariableTypeEnum.Execution,
    name: ExecutionVariableNameEnum.StatusDescription,
    id: executionVariableIds[5],
    data: [],
  },
};

export const deletableVariableTypes = [
  VariableTypeEnum.Scrape,
  VariableTypeEnum.Query,
  VariableTypeEnum.Template,
  VariableTypeEnum.Select,
  VariableTypeEnum.MultiSelect,
  VariableTypeEnum.MultiChoice,
  VariableTypeEnum.Document,
];

export const mfaActionDescription =
  'Forward 2FA emails to 2fa-sandbox@sola-solutions.com or mfa@sola-solutions.com. You may want to configure a forwarding rule on your email provider.';
