"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KEYBOARD_SHORCUTS = exports.KeyboardShortcuts = void 0;
const types_shared_1 = require("types-shared");
const workflowTypes_1 = require("types-shared/workflowTypes");
const serializer_1 = require("../serializer");
var KeyboardShortcuts;
(function (KeyboardShortcuts) {
    KeyboardShortcuts["Copy"] = "Copy";
    KeyboardShortcuts["Paste"] = "Paste";
    KeyboardShortcuts["SelectAll"] = "Select All";
    KeyboardShortcuts["Close"] = "Close";
    KeyboardShortcuts["CloseWindow"] = "Close Window";
})(KeyboardShortcuts || (exports.KeyboardShortcuts = KeyboardShortcuts = {}));
const action = new serializer_1.KeyboardActionSerializer(workflowTypes_1.KeyboardShortcutGroupEnum.General);
const SHORTCUT_MAPPING = {
    [KeyboardShortcuts.SelectAll]: [types_shared_1.CONTROL, 'a'],
    [KeyboardShortcuts.Copy]: [types_shared_1.CONTROL, 'c'],
    [KeyboardShortcuts.Paste]: [types_shared_1.CONTROL, 'p'],
    [KeyboardShortcuts.Close]: [types_shared_1.CONTROL, 'w'],
    [KeyboardShortcuts.CloseWindow]: [types_shared_1.ALT, 'F4'],
};
exports.KEYBOARD_SHORCUTS = Object.entries(SHORTCUT_MAPPING).map(([shortcutName, keySeq]) => action.serialize(shortcutName, keySeq));
