import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { Button, Flex, Modal, CustomTypography } from 'ui-kit';
import { handleException } from 'sentry-browser-shared';
import {
  useGetWorkflowConcurrencyLimit,
  useUpdateWorkflowConcurrentLimit,
} from '../hooks/useWorkflowConcurrency.gql';

interface Props {
  onCancel: () => void;
  workflowId: string;
}

export default function ConcurrencyLimitModal({ onCancel, workflowId }: Props) {
  const [limit, setLimit] = useState<number | null | undefined>(null);
  const { data, loading: loadingLimit } =
    useGetWorkflowConcurrencyLimit(workflowId);
  const { mutateAsync, loading: updatingLimit } =
    useUpdateWorkflowConcurrentLimit();

  const isDisabled = useMemo(() => {
    return limit === undefined || loadingLimit || updatingLimit;
  }, [limit, loadingLimit, updatingLimit]);

  const onSubmit = useCallback(async () => {
    if (limit === undefined) return;

    try {
      await mutateAsync({
        workflowId,
        limit,
      });
    } catch (error) {
      handleException(error, {
        name: 'Set concurrency limit',
        source: 'ConcurrencyLimitModal',
        extra: {
          workflowId,
          limit,
        },
      });
    }
    onCancel();
  }, [onCancel, mutateAsync, workflowId, limit]);

  useEffect(() => {
    setLimit(data ?? null);
  }, [data]);

  return (
    <Modal className="w-full !max-w-3xl" onClose={onCancel} open>
      <CustomTypography variant="h5">Set concurrency limit</CustomTypography>

      <div className="mt-7">
        <InputLabel className="!text-primary">
          Maximum Concurrent Executions
        </InputLabel>
        <TextField
          color="secondary"
          focused
          fullWidth
          onChange={(e) => {
            const val = e.target.value;
            if (val === '') {
              setLimit(null);
            } else {
              const num = Number(val);
              if (!Number.isNaN(num) && Number.isInteger(num) && num >= 0) {
                setLimit(num);
              } else {
                setLimit(undefined);
              }
            }
          }}
          size="medium"
          value={limit ?? ''}
          variant="outlined"
          helperText="Leave empty for nonlimited concurrent executions"
          type="number"
          inputProps={{ min: 0, step: 1 }}
          error={limit === undefined}
        />
      </div>

      <Flex className="gap-4 mt-10">
        <Button
          color="secondary"
          loading={loadingLimit || updatingLimit}
          disabled={isDisabled}
          onClick={onSubmit}
          variant="contained"
        >
          Update
        </Button>
        <Button color="secondary" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}
