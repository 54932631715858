import { gql } from '@apollo/client';
import { getUtcDate, getUtcTime } from './helper';

export const GET_EXECUTIONS = gql`
  query GetExecutions(
    $where: executions_bool_exp!
    $offset: Int!
    $limit: Int!
    $orderBy: [executions_order_by!]
    $workflowId: uuid!
  ) {
    executions(
      where: $where
      order_by: $orderBy
      offset: $offset
      limit: $limit
    ) {
      id
    }
    executions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    totalExecutionsCount: executions_aggregate(
      where: { workflowId: { _eq: $workflowId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_GLOBAL_EXECUTIONS = gql`
  query GetGlobalExecutions(
    $where: executions_bool_exp = {}
    $offset: Int!
    $limit: Int!
    $orderBy: [executions_order_by!]
  ) {
    executions(
      where: $where
      order_by: $orderBy
      offset: $offset
      limit: $limit
    ) {
      id
    }
    executions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    totalExecutionsCount: executions_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_OWNED_WORKFLOWS = gql`
  query GetOwnedWorkflows($userId: String!) {
    ownedWorkflows: workflows(where: { ownerId: { _eq: $userId } }) {
      id
    }
  }
`;

export const GET_WORKFLOW_METRICS = gql`
  query GetWorkflowMetrics {
    totalWorkflows: workflows_aggregate(
      where: { deletedAt: { _is_null: true } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_TOTAL_EXECUTION_METRICS = gql`
  query GetExecutionMetrics($startOfThisMonth: timestamptz!) {
    executionsThisMonth: executions_aggregate(
      where: { createdAt: { _gt: $startOfThisMonth } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_WORKFLOW_EXECUTION_METRICS = gql`
  query GetWorkflowExecutionMetrics($workflowId: uuid!, $statuses: [String!]) {
    overallExecutions: execution_status(where: { value: { _in: $statuses } }) {
      executions_aggregate(where: { workflowId: { _eq: $workflowId } }) {
        aggregate {
          count
        }
      }
      value
    }
    totalExecutions: executions_aggregate(
      where: { workflowId: { _eq: $workflowId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const generateWorkflowExecutionMonthlyMetricsQuery = (
  dateRanges: string[][],
) => {
  const queryFields = dateRanges
    .map(([monthName, startDate, endDate]) => {
      const utcStartDate = getUtcDate(startDate);
      const utcEndDate = getUtcDate(endDate);
      return `
      ${monthName}Executions: execution_status(
        where: { value: { _in: $statuses } }
      ) {
        executions_aggregate(
          where: {
            createdAt: { _gt: "${utcStartDate}", _lt: "${utcEndDate}" }
            workflowId: { _eq: $workflowId }
          }
        ) {
          aggregate {
            count
          }
        }
        value
      }
    `;
    })
    .join('\n');

  return gql`
    query GetWorkflowExecutionMonthlyMetrics($workflowId: uuid!, $statuses: [String!]) {
      ${queryFields}
    }
  `;
};

export const generateWorkflowExecutionHourlyMetricsQuery = (
  dateRange: string[],
  timeRanges: string[][],
) => {
  const queryFields = timeRanges
    .map(([time, startTime, endTime]) => {
      const [, startDate, endDate] = dateRange;
      const utcStartDate = getUtcDate(startDate);
      const utcEndDate = getUtcDate(endDate);
      const utcStartTime = getUtcTime(startTime);
      const utcEndTime = getUtcTime(endTime);
      return `
      ${time}Executions: execution_status(
        where: { value: { _in: $statuses } }
      ) {
        executions_aggregate(
          where: {
            updatedAt: { _gt: "${utcStartDate}", _lt: "${utcEndDate}" }
            ${
              utcStartTime > utcEndTime
                ? `_or: [{updatedAtTime: {_gt: "${utcStartTime}", _lte: "23:59:59+00:00"}}, {updatedAtTime: {_gt: "00:00:00+00:00", _lt: "${utcEndTime}"}}]`
                : `updatedAtTime: { _gt: "${utcStartTime}", _lt: "${utcEndTime}" }`
            }
            workflowId: { _eq: $workflowId }
          }
        ) {
          aggregate {
            count
          }
        }
        value
      }
    `;
    })
    .join('\n');

  return gql`
    query GetWorkflowExecutionHourlyMetrics($workflowId: uuid!, $statuses: [String!]) {
      ${queryFields}
    }
  `;
};
