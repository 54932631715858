import { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import {
  type BranchData,
  Condition,
  type ExecutionProgress,
  type GlobalVariable,
  type Variable,
  type VariableMap,
  type WorkflowConditionalNode,
  type WorkflowEdge,
  type WorkflowNode,
  type Rule,
  TemplateVariable,
  BranchModeEnum,
} from 'types-shared';

import { VariableChip } from '../../../../../components/VariableChip';
import { type ScreenshotUrl } from '../../../utils';
import HITLPrompt from './HITLModal/components/HITLPrompt';

interface ConditionalInputsProps {
  screenshotUrls: ScreenshotUrl[];
  nodeOfCurrentStep: WorkflowNode;
  currentNode: WorkflowConditionalNode;
  nodes: WorkflowNode[];
  completedSteps: ExecutionProgress;
  edges?: WorkflowEdge[];
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  variablesMap: Record<string, Variable>;
  showHITLPrompt: boolean;
  conditionalTitle?: string;
  openHITLModal: () => void;
  signalLoading: boolean;
}

export default function ConditionalInputs({
  nodes,
  currentNode,
  completedSteps,
  edges,
  variablesMap,
  globalVariablesMap,
  nodeOfCurrentStep,
  screenshotUrls,
  showHITLPrompt,
  conditionalTitle,
  openHITLModal,
  signalLoading,
}: ConditionalInputsProps) {
  const { edgeChosen, branchChosen } = useMemo(() => {
    const payload: {
      branchNodeChosen: WorkflowNode | undefined;
      edgeChosen: WorkflowEdge | undefined;
      branchChosen: BranchData | undefined;
    } = {
      branchNodeChosen: undefined,
      edgeChosen: undefined,
      branchChosen: undefined,
    };
    if (completedSteps.length && nodes.length && edges?.length) {
      const branchEdges = edges
        .filter((e) => e.source === currentNode.id)
        .map((e) => e.target);

      const branches = nodes
        .filter((n) => branchEdges.includes(n.id))
        .map((b) => b.id);

      const nodeOfCurrentStepChosen = branchEdges.includes(nodeOfCurrentStep.id)
        ? nodeOfCurrentStep
        : undefined;

      const chosenScreenshotUrl = screenshotUrls.find(
        (s) => s.sortData.nodeId && branchEdges.includes(s.sortData.nodeId),
      )?.sortData.nodeId;

      const chosenInCompletedSteps = completedSteps.find((step) =>
        branches.includes(step.nodeId),
      );

      const branchChosenId =
        chosenScreenshotUrl ??
        chosenInCompletedSteps?.nodeId ??
        nodeOfCurrentStepChosen?.id;
      if (branchChosenId) {
        payload.edgeChosen = edges.find(
          (e) => e.target === branchChosenId && e.source === currentNode.id,
        );
        payload.branchNodeChosen = nodes.find((n) => n.id === branchChosenId);
        payload.branchChosen = currentNode.data.branchesData?.find(
          (b) => b.branchId === payload.edgeChosen?.id,
        );
      }
    }

    return payload;
  }, [
    completedSteps,
    nodes,
    currentNode,
    edges,
    nodeOfCurrentStep,
    screenshotUrls,
  ]);

  const mapTemplateArrayContent = (
    el?: Condition['field'] | Condition['value'],
    instructions?: BranchData['instruction'],
  ) => {
    const elementVariable = el ? variablesMap[el.variableId] : undefined;
    const instructionVariable = instructions
      ? variablesMap[instructions.variableId]
      : undefined;
    const dataVariable = instructionVariable ?? elementVariable;
    const check = TemplateVariable.safeParse(dataVariable);

    if (check.success) {
      const data = check.data.data;
      const content = data.map((val) => {
        if (typeof val === 'object') {
          return (
            <VariableChip
              key={val.id}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              variableId={val.id}
            />
          );
        }
        return <React.Fragment key={val}>{val}</React.Fragment>;
      });

      return content;
    }
  };

  const getComparator = (val: string | undefined) =>
    val
      ? capitalize(
          val
            .replace(/(?:[A-Z])/g, ' $&')
            .trim()
            .toLowerCase(),
        )
      : '';

  const generateElement = (
    element: Condition,
    index: number,
    arrLength: number,
    branchToUse: BranchData,
    operator?: string,
  ) => {
    const useOperator = arrLength > 1;
    const isFirst = index === 0;
    return (
      <>
        {!isFirst && useOperator ? (
          <p className="text-info-dark text-sm font-normal mb-2">
            {getComparator(operator ?? branchToUse.rule?.data.operator)}
          </p>
        ) : null}
        <div className="rounded-lg border border-indigo-light px-4 py-3">
          {/* Element */}
          <p className="text-info-dark text-sm font-normal mb-2">
            {mapTemplateArrayContent(element.field)}
          </p>

          {/* Comparator */}
          <p className="text-info-dark text-sm font-normal mb-2">
            {getComparator(element.comparator)}
          </p>

          <p className="text-info-dark text-sm font-normal mb-2">
            {mapTemplateArrayContent(element.value)}
          </p>
        </div>
      </>
    );
  };

  const mapElements = (
    elements: Rule['data']['elements'],
    branchToUse: BranchData,
    operator?: string,
  ): React.ReactNode[] => {
    return elements.map((el, index, arr) => {
      const result = Condition.safeParse(el);

      if (result.success) {
        // Is a condition.
        return (
          <>
            {generateElement(
              result.data,
              index,
              arr.length,
              branchToUse,
              operator,
            )}
          </>
        );
      }
      const elArr = el as Rule['data'];
      // Is a nested condition

      return (
        <>
          <hr className="!my-8" />
          {mapElements(elArr.elements, branchToUse, elArr.operator)}
          <hr className="!my-8" />
        </>
      );
    });
  };

  return (
    <>
      {showHITLPrompt ? (
        <div className="px-6">
          <HITLPrompt
            defaultTitle={conditionalTitle}
            openHITLModal={openHITLModal}
            actionIndex={0}
            signalLoading={signalLoading}
          />
        </div>
      ) : null}
      {branchChosen ? (
        <div className="flex flex-col gap-2 py-4 px-6 overflow-auto rounded-lg border border-indigo-light m-6">
          <p className="text-info-dark text-sm font-medium">
            Branch Taken: {edgeChosen?.label}
          </p>

          {currentNode.errorOverlay ? null : (
            <>
              {branchChosen.selectedMode === BranchModeEnum.Rule ? (
                <>
                  <p className="text-info-dark text-sm font-medium">
                    Condition
                  </p>

                  <>
                    {branchChosen.rule?.data.elements
                      ? mapElements(
                          branchChosen.rule.data.elements,
                          branchChosen,
                        )
                      : null}
                  </>
                </>
              ) : (
                <p className="text-slate-500 text-sm font-normal">
                  <span className="font-medium">Take this branch when: </span>
                  {mapTemplateArrayContent(undefined, branchChosen.instruction)}
                </p>
              )}
            </>
          )}
        </div>
      ) : null}
    </>
  );
}
