import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Clear,
  ContentContainer,
  CustomTypography,
  DataLoader,
  IconButton,
  Logo,
  ScrollableContainer,
  PlaceholderIcon,
  FileIconAlt,
  notify,
  AlertVariant,
  CustomDownloadIcon,
} from 'ui-kit';
import { isUUID } from '../Editor/utils/helper';
import { useFetchWorkflowMetadata } from '../Workflows/hooks';
import {
  useWorkflowFiles,
  useUploadWorkflowFile,
  useDownloadWorkflowFiles,
} from './hooks';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { type FileMetadata } from 'api-types-shared';
import { type GridColDef } from '@mui/x-data-grid/models';
import {
  useMemo,
  useRef,
  type ChangeEvent,
  type MouseEvent,
  useEffect,
} from 'react';
import { WORKFLOW_FILE_MAX_SIZE } from '../../utils/constants';
import { FileManager } from './FileManager';
import { getTabTitle } from '../../utils/tabTitle';
import { isAdmin } from '../../utils/env';
import { clsx } from 'clsx';

const columns: GridColDef<FileMetadata>[] = [
  {
    field: 'name',
    headerName: 'File name',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <div className="flex items-center space-x-2 text-sm">
          <FileIconAlt className="!w-6 !h-6" />
          <p>{params.row.name}</p>
        </div>
      );
    },
  },
  {
    field: 'fileType',
    headerName: 'File type',
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      const fileType = params.row.name.split('.').reverse()[0]?.toUpperCase();
      return <span className="text-sm">{fileType}</span>;
    },
  },
  {
    field: 'download',
    headerName: '',
    flex: 1,
    sortable: false,
    align: 'right',
    renderCell: (params) => {
      return (
        <FileManager fileId={params.row.fileId} filename={params.row.name} />
      );
    },
  },
];

export default function WorkflowFiles() {
  const navigate = useNavigate();
  const { workflowId } = useParams();
  const inputRef = useRef<HTMLInputElement | null>(null);
  if (!workflowId) {
    throw new Error('WorkflowId not found!');
  }

  if (!isUUID(workflowId)) {
    navigate('/');
  }

  const { data: workflowMetadata, isLoading: isLoadingWorkflowMetadata } =
    useFetchWorkflowMetadata(workflowId);
  const {
    data: workflowFiles = [],
    isLoading: isLoadingWorkflowFiles,
    refetch: refetchWorkflowFiles,
  } = useWorkflowFiles(workflowId);
  const { mutateAsync: uploadWorkflowFile } = useUploadWorkflowFile(workflowId);
  const {
    mutateAsync: downloadWorkflowFiles,
    status: downloadWorkflowFilesStatus,
  } = useDownloadWorkflowFiles(workflowId);

  const isDownloadingZippedOutput = downloadWorkflowFilesStatus === 'pending';

  const loading = isLoadingWorkflowMetadata || isLoadingWorkflowFiles;

  const workflowFilesSorted = useMemo(() => {
    return workflowFiles.sort((a, b) => {
      const aDate = a.uploadedAt ? new Date(a.uploadedAt) : new Date(0);
      const bDate = b.uploadedAt ? new Date(b.uploadedAt) : new Date(0);
      return bDate.getTime() - aDate.getTime();
    });
  }, [workflowFiles]);

  const onFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      if (selectedFile.size <= WORKFLOW_FILE_MAX_SIZE) {
        notify({
          message: 'Uploading file...',
          variant: AlertVariant.INFO,
        });
        await uploadWorkflowFile(selectedFile);
        notify({
          message: 'File uploaded successfully.',
          variant: AlertVariant.SUCCESS,
        });
        void refetchWorkflowFiles();
      } else {
        notify({
          message: 'File size should be less than 50 MB',
          variant: AlertVariant.ERROR,
        });
      }
    }
  };

  const handleDownloadZippedOutput = async () => {
    await downloadWorkflowFiles(workflowFiles);
  };

  useEffect(() => {
    document.title = workflowMetadata?.workflowName
      ? getTabTitle(`Files | ${workflowMetadata.workflowName}`, isAdmin)
      : getTabTitle('Files', isAdmin);
  }, [workflowMetadata?.workflowName]);

  return (
    <ScrollableContainer>
      {loading ? (
        <DataLoader loadingText="Fetching workflow files" />
      ) : (
        <>
          <div className="h-20 w-full border-b border-[#BDCAD5] px-8 py-6 flex justify-between items-center">
            <div className="flex items-center space-x-6">
              <Logo className="h-8 w-8" />
              <div className="flex items-center space-x-2 text-base">
                <Link
                  className="text-sm text-[#808080]"
                  to={`/editor/${workflowId}`}
                >
                  {workflowMetadata?.workflowName}
                </Link>
                <span>/</span>
                <span className="text-base text-navy-blue">Workflow files</span>
              </div>
            </div>
            <IconButton
              color="secondary"
              onClick={() => {
                navigate(`/editor/${workflowId}`);
              }}
            >
              <Clear className="!w-5 !h-5 text-info" />
            </IconButton>
          </div>

          <ContentContainer className="!pt-7 flex flex-col" withToolbar>
            <div className="w-full flex items-center justify-between mb-8">
              <div>
                <CustomTypography
                  className="!font-medium !text-2xl !text-navy-blue"
                  variant="h4"
                >
                  Workflow files
                </CustomTypography>
                <p className="text-color-grey text-sm mt-2">
                  Upload files to your workflows for Sola bots to interact with
                  them.
                </p>
              </div>
              <div className="flex items-center space-x-4">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => inputRef.current?.click()}
                >
                  Add New File
                </Button>
                <Button
                  className="!border-none"
                  color="secondary"
                  loading={isDownloadingZippedOutput}
                  disabled={workflowFiles.length === 0}
                  onClick={handleDownloadZippedOutput}
                  variant="outlined"
                >
                  {!isDownloadingZippedOutput ? (
                    <CustomDownloadIcon
                      fontSize="small"
                      className={clsx('mr-1', {
                        'text-gray-350': workflowFiles.length === 0,
                        'text-info': workflowFiles.length > 0,
                      })}
                    />
                  ) : null}
                  Export all documents
                </Button>
              </div>
            </div>
            {workflowFiles.length === 0 ? (
              <div className="flex-1 rounded-lg border border-[#BDCAD5] flex flex-col items-center justify-center">
                <PlaceholderIcon className="!w-80 !h-80 !-my-20" />
                <h4 className="text-lg font-medium text-navy-blue">
                  There are no workflow files right now
                </h4>
                <p className="mt-2 text-sm text-color-grey text-center">
                  Upload files needed in the executions or sample files
                  <br />
                  to help the bot understand the workflow.
                </p>
              </div>
            ) : null}
            {workflowFiles.length > 0 ? (
              <DataGrid
                columns={columns}
                getRowId={(row) => row.fileId}
                rows={workflowFilesSorted}
              />
            ) : null}
          </ContentContainer>
        </>
      )}
      <input
        hidden
        onChange={onFileSelect}
        onClick={(e: MouseEvent<HTMLInputElement>) => {
          e.currentTarget.value = '';
        }}
        ref={inputRef}
        type="file"
      />
    </ScrollableContainer>
  );
}
