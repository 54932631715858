import { useNavigate } from 'react-router-dom';
import {
  useDeleteWorkflow,
  useFetchWorkflowsListBatched,
  useUpdateWorkflowStatus,
  useImportWorkflowButton,
  useWorkflowCols,
  useOwnedWorkflowsCount,
} from '../hooks';
import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  ContentContainer,
  CustomTypography,
  modalEventChannel,
  ScrollableContainer,
  Tooltip,
  Add,
  InfoOutlined,
  WarningAmberOutlined,
} from 'ui-kit';
import { NavigationPanel } from '../../../components/NavigationPanel';
import { isAdmin } from '../../../utils/env';
import ListSkeleton from '../../../components/ListSkeleton';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { NoDataFound } from '../../../components/NoDataFound';
import CloneModal from '../components/CloneModal';
import { WorkflowStatusEnum } from 'api-types-shared';
import isEmpty from 'lodash/isEmpty';
import { FeatureFlag } from '../../../utils/constants';
import { useAuth, useFeatureFlag } from '../../../utils/helper';
import {
  workflowListFiltersSchema,
  type CompactWorkflowMetadata,
  type WorkflowListMenuProps,
} from '../types';
import WorkflowListMenu from '../components/WorkflowListMenu';
import usePersistedURLState from '../../../hooks/usePersistedURLState';
import WorkflowListMetrics from '../components/WorkflowListMetrics';
import RenameWorkflowModal from '../components/RenameWorkflowModal';
import { getExtensionData } from '../../../utils/extension';
import useWorkflowLimit from '../../../hooks/useWorkflowLimit';
import isNil from 'lodash/isNil';
import { MOCK_USER_ID } from 'types-shared';
import { getTabTitle } from '../../../utils/tabTitle';
import WorkflowListFilter from '../components/WorkflowListFilter';
import { defaultWorkflowListFilters } from '../utils/constants';
import ConcurrencyLimitModal from '../components/ConcurrencyLimitModal';

export default function WorkflowsList() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const userID = isAdmin ? MOCK_USER_ID : user?.sub;
  const [filters, setFilters] = usePersistedURLState(
    workflowListFiltersSchema,
    defaultWorkflowListFilters,
    'workflows-list-filters',
  );
  const [menuProps, setMenuProps] = useState<WorkflowListMenuProps | null>(
    null,
  );
  const workflowLimit = useWorkflowLimit();
  const [selectedWorkflowData, setSelectedWorkflowData] =
    useState<CompactWorkflowMetadata | null>(null);
  const [showClonePopup, setShowClonePopup] = useState<boolean>(false);
  const [showRenamePopup, setShowRenamePopup] = useState<boolean>(false);
  const [showConcurrencyPopup, setShowConcurrencyPopup] =
    useState<boolean>(false);
  const [extensionHasData, setExtensionHasData] = useState<boolean>(false);
  const { isLoading, data } = useFetchWorkflowsListBatched();
  const { mutateAsync: deleteWorkflow } = useDeleteWorkflow();
  const { mutate: updateWorkflowMetadata, status: workflowUpdateStatus } =
    useUpdateWorkflowStatus();
  const { data: ownedWorkflowsCount } = useOwnedWorkflowsCount(userID);
  const apiKeysManagerPageEnabled = useFeatureFlag(FeatureFlag.ApiKeysManager);
  const demoFeaturesEnabled = useFeatureFlag(FeatureFlag.DemoFeatures, false);
  const enableWorkflowCloning = useFeatureFlag(
    FeatureFlag.EnableWorkflowCloning,
  );
  const editWorkflowEnabled = useFeatureFlag(FeatureFlag.EditWorkflow);
  const metricsEnabled =
    useFeatureFlag(FeatureFlag.ExecutionAndWorkflowListMetrics) ?? false;
  const workflowImportPollingEnabled = useFeatureFlag(
    FeatureFlag.WorkflowImportPolling,
  );

  const workflows = useMemo(() => {
    if (isEmpty(filters)) {
      return data;
    }
    const { name, email, status, hideInternal } = filters;
    return data?.filter((workflow) => {
      const workflowStatus = workflow.status;
      return (
        (!name ||
          workflow.workflowName.toLowerCase().includes(name.toLowerCase())) &&
        (!email ||
          workflow.email?.toLowerCase().includes(email.toLowerCase())) &&
        (!status ||
          status === 'All' ||
          (workflowStatus as string) === status ||
          (status === 'Needs review' &&
            (workflowStatus === WorkflowStatusEnum.Invalid ||
              workflowStatus === WorkflowStatusEnum.ProcessedImport))) &&
        (!hideInternal || !workflow.email?.includes('@sola-solutions.com'))
      );
    });
  }, [data, filters]);

  const importsOverLimit = useMemo(
    () =>
      !isNil(workflowLimit) &&
      ownedWorkflowsCount &&
      ownedWorkflowsCount >= workflowLimit,
    [ownedWorkflowsCount, workflowLimit],
  );

  const { loadingImport, handleOnClickImport } = useImportWorkflowButton();

  const importWorkflowProps = useMemo(() => {
    const tooltipHidden =
      apiKeysManagerPageEnabled &&
      !importsOverLimit &&
      (extensionHasData || !workflowImportPollingEnabled);
    let tooltipTitle = '';

    if (!apiKeysManagerPageEnabled) {
      tooltipTitle =
        'As soon as your setup is complete, this feature will be available to you!';
    } else if (importsOverLimit) {
      tooltipTitle = 'Upgrade to a full plan to continue building workflows.';
    }

    if (!extensionHasData) {
      tooltipTitle =
        'Start a new recording to import it into your Sola dashboard.';
    }

    const disableImport =
      !apiKeysManagerPageEnabled ||
      Boolean(importsOverLimit) ||
      isLoading ||
      loadingImport ||
      (workflowImportPollingEnabled && !extensionHasData);

    return {
      disableImport,
      tooltipTitle,
      tooltipHidden,
    };
  }, [
    apiKeysManagerPageEnabled,
    extensionHasData,
    importsOverLimit,
    isLoading,
    loadingImport,
    workflowImportPollingEnabled,
  ]);

  const showEmailCol = useMemo(
    () => data?.some((workflow) => workflow.email),
    [data],
  );

  const hasWorkflowInProcessingState = useMemo(
    () =>
      workflows?.some(
        (workflow) => workflow.status === WorkflowStatusEnum.ProcessingImport,
      ),
    [workflows],
  );

  const onCloseMenu = () => {
    setMenuProps(null);
  };

  const onOpenDuplicateModal = () => {
    onCloseMenu();
    setShowClonePopup(true);
  };

  const onEditWorkflow = () => {
    onCloseMenu();
    if (menuProps?.workflowId) {
      openWorkflowEditor(menuProps.workflowId);
    }
  };

  const openWorkflowEditor = (workflowId: string) => {
    navigate(`/editor/${workflowId}`);
  };

  const onDeleteWorkflow = () => {
    onCloseMenu();
    modalEventChannel.emit('open', {
      title: 'Delete Workflow',
      descriptions: ['Are you sure, you want to delete this workflow?'],
      showCloseIcon: false,
      alignActions: 'right',
      actions: [
        {
          text: 'Cancel',
          variant: 'text',
          onClick: () => {
            modalEventChannel.emit('close');
          },
        },
        {
          text: 'Proceed',
          color: 'error',
          onClick: async () => {
            modalEventChannel.emit('close');
            if (menuProps?.workflowId) {
              await deleteWorkflow(menuProps.workflowId);
            }
            modalEventChannel.emit('close');
          },
        },
      ],
    });
  };

  const onRenameWorkflow = () => {
    onCloseMenu();
    setShowRenamePopup(true);
  };

  const onUpdateConcurrency = () => {
    onCloseMenu();
    setShowConcurrencyPopup(true);
  };

  const columns = useWorkflowCols({
    showEmailCol: Boolean(showEmailCol),
    demoFeaturesEnabled: Boolean(demoFeaturesEnabled),
    workflowUpdateStatus,
    setMenuProps,
    setSelectedWorkflowData,
    handleUpdateWorkflow: updateWorkflowMetadata,
  });

  useEffect(() => {
    if (!workflowImportPollingEnabled) {
      return;
    }
    const timerId = setInterval(() => {
      void getExtensionData().then((extensionData) => {
        const {
          capturedUrls = [],
          scans = [],
          actions = [],
        } = extensionData ?? {};
        const hasData = Boolean(
          capturedUrls.length || scans.length || actions.length,
        );
        setExtensionHasData(hasData);
        if (hasData) {
          clearInterval(timerId);
        }
      });
      // poll extension data every 5s until we receive the data
    }, 5 * 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [workflowImportPollingEnabled]);

  useEffect(() => {
    document.title = getTabTitle('Workflows', isAdmin);
  }, []);

  return (
    <>
      <ScrollableContainer>
        <div className="w-full h-full flex">
          <NavigationPanel />
          <ContentContainer>
            <div className="flex justify-between w-full items-center">
              <CustomTypography variant="h4">Workflows</CustomTypography>
              <Tooltip
                arrow
                hidden={importWorkflowProps.tooltipHidden}
                placement="left"
                title={importWorkflowProps.tooltipTitle}
              >
                <Button
                  color="secondary"
                  disabled={importWorkflowProps.disableImport}
                  loading={loadingImport}
                  onClick={handleOnClickImport}
                  variant="contained"
                >
                  <Add />
                  Import Workflow
                </Button>
              </Tooltip>
            </div>

            {metricsEnabled || isAdmin ? <WorkflowListMetrics /> : null}

            {hasWorkflowInProcessingState && !isAdmin ? (
              <div className="mt-10 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded -mb-6">
                <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
                <span className="font-medium text-primary-blue-dark">
                  Your workflows are actively processing. We’ll notify you once
                  they’re ready!
                </span>
              </div>
            ) : null}

            {demoFeaturesEnabled ? (
              <div className="mt-10 w-full bg-primary-blue-extralight flex space-x-2 px-4 py-3 rounded -mb-6">
                <InfoOutlined className="!w-5 !h-5 !text-[#0288D1] !mt-0.5" />
                <span className="font-medium text-primary-blue-dark">
                  Members of your organization have imported workflow(s).
                  Approve to begin processing!
                </span>
              </div>
            ) : null}

            {importsOverLimit ? (
              <div className="bg-warning-light my-6 flex flex-row gap-2 rounded-lg px-4 py-3.5 mt-8 -mb-6">
                <WarningAmberOutlined className="text-warning" />
                <p className="text-warning-dark text-base font-medium">
                  You've reached the workflow import limit for this sandbox.
                  Upgrade to a full plan to continue building workflows.
                </p>
              </div>
            ) : null}
            <WorkflowListFilter filters={filters} setFilters={setFilters} />
            <div className="mt-11">
              {isLoading ? (
                <ListSkeleton columns={columns} />
              ) : (
                <>
                  {workflows?.length ? (
                    <DataGrid
                      columns={columns}
                      getRowId={(row) => row.workflowId}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'createdAt', sort: 'desc' }],
                        },
                      }}
                      rows={workflows}
                    />
                  ) : (
                    <NoDataFound
                      heading="You don’t have workflows yet"
                      subHeading="You don’t have workflows yet. Use the Sola chrome extension to record a new workflow"
                    />
                  )}
                </>
              )}
            </div>
          </ContentContainer>
        </div>
        <WorkflowListMenu
          menuProps={menuProps}
          editWorkflowEnabled={Boolean(editWorkflowEnabled)}
          enableWorkflowCloning={Boolean(enableWorkflowCloning)}
          onEditWorkflow={onEditWorkflow}
          onOpenDuplicateModal={onOpenDuplicateModal}
          onDeleteWorkflow={onDeleteWorkflow}
          onCloseMenu={onCloseMenu}
          onRenameWorkflow={onRenameWorkflow}
          onUpdateConcurrency={onUpdateConcurrency}
        />
      </ScrollableContainer>
      {showClonePopup && selectedWorkflowData ? (
        <CloneModal
          onCancel={() => {
            setShowClonePopup(false);
          }}
          workflow={selectedWorkflowData}
        />
      ) : null}
      {showRenamePopup && selectedWorkflowData ? (
        <RenameWorkflowModal
          onCancel={() => {
            setShowRenamePopup(false);
          }}
          workflow={selectedWorkflowData}
        />
      ) : null}
      {showConcurrencyPopup && selectedWorkflowData ? (
        <ConcurrencyLimitModal
          onCancel={() => {
            setShowConcurrencyPopup(false);
          }}
          workflowId={selectedWorkflowData.workflowId}
        />
      ) : null}
    </>
  );
}
