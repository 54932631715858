import { type SlackPlatformMetadata } from 'types-shared/notificationTypes';
import {
  type TemplateData,
  type WebhookHeader,
  type WorkflowWebhook,
} from 'types-shared/workflowTypes';
import { v4 as uuid } from 'uuid';

export interface FormValues {
  id: string;
  eventName: string;
  event: string;
  action: string;
  url: TemplateData;
  headers: ({ id: string } & WebhookHeader)[];
}

export enum Event {
  ExecutionFinished = 'Execution finished',
  ExecutionStatusChange = 'Execution status change',
}

export enum Action {
  SendWebhook = 'Send webhook',
  SendNotification = 'Send notification',
}

export enum NotificationTabIdEnum {
  Slack = 'slack',
  Email = 'email',
  Teams = 'teams',
}

export enum NotifyAboutEnum {
  Urgent = 'urgent',
  Custom = 'custom',
}

export interface NotificationData {
  slackActive: boolean;
  slackChannel: string;
  slackNotificationEmails: string[];
  notifyAbout: NotifyAboutEnum;
  workflowExecutionCompleted: boolean;
  failedExecutions: boolean;
  successfulExecutions: boolean;
  improvementDetected: boolean;
}

export interface NotificationConfig {
  id: string;
  workflowId: string;
  ownerId?: string;
  platform: string;
  platformMetadata: SlackPlatformMetadata;
  events: { event: string; configurationId: string }[];
}

export interface NotificationConfigPayload
  extends Omit<NotificationConfig, 'id' | 'events'> {
  events: { event: string }[];
}

// Enum mappings between frontend data and backend
const NotifyAboutEnumToEventMapping = {
  [NotifyAboutEnum.Urgent]: [
    'execution_failed',
    'workflow_improvement_detected',
  ],
  [NotifyAboutEnum.Custom]: [
    'execution_failed',
    'execution_success',
    'workflow_improvement_detected',
  ],
};

export const events = [Event.ExecutionFinished, Event.ExecutionStatusChange];
export const actions = [Action.SendWebhook, Action.SendNotification];

export const defaultForm: FormValues = {
  id: uuid(),
  eventName: '',
  event: Event.ExecutionFinished,
  action: Action.SendWebhook,
  url: [],
  headers: [],
};

export const convertWebhookToFormValues = (
  webhook: WorkflowWebhook,
): FormValues => ({
  id: webhook.id,
  eventName: webhook.name,
  event: Event.ExecutionFinished,
  action: Action.SendWebhook,
  url: webhook.webhookUrl,
  headers: webhook.headers.map(({ key, value }) => ({
    id: uuid(),
    key,
    value,
  })),
});

export const convertFormValuesToWebhook = (
  formValues: FormValues,
): WorkflowWebhook => ({
  id: formValues.id,
  name: formValues.eventName,
  webhookUrl: formValues.url,
  headers: formValues.headers.map((header) => ({
    key: header.key,
    value: header.value,
  })),
});

const getEvents = (frontendData: NotificationData): { event: string }[] => {
  return frontendData.notifyAbout === NotifyAboutEnum.Urgent
    ? NotifyAboutEnumToEventMapping[NotifyAboutEnum.Urgent].map((event) => ({
        event,
      }))
    : [
        ...(frontendData.failedExecutions
          ? [{ event: 'execution_failed' }]
          : []),
        ...(frontendData.successfulExecutions
          ? [{ event: 'execution_success' }]
          : []),
        ...(frontendData.improvementDetected
          ? [{ event: 'workflow_improvement_detected' }]
          : []),
      ];
};

export const convertNotificationDataToBackendPayload = ({
  workflowId,
  notificationData,
  ownerId,
  isAdmin,
}: {
  workflowId: string;
  notificationData: NotificationData;
  ownerId: string;
  isAdmin: boolean;
}): NotificationConfigPayload => {
  return {
    workflowId,
    platform: NotificationTabIdEnum.Slack.toLowerCase(),
    platformMetadata: {
      platform: 'slack',
      userEmails: notificationData.slackNotificationEmails,
      conversationId: notificationData.slackChannel,
    },
    events: getEvents(notificationData),
    ...(isAdmin && { ownerId }),
  };
};

export const convertNotificationDataToUpdatePayload = ({
  workflowId,
  notificationData,
  ownerId,
  notificationId,
  configurationId,
  isAdmin,
}: {
  workflowId: string;
  notificationData: NotificationData;
  ownerId: string;
  notificationId: string;
  configurationId: string;
  isAdmin: boolean;
}): NotificationConfig => {
  return {
    id: notificationId,
    workflowId,
    platform: NotificationTabIdEnum.Slack.toLowerCase(),
    platformMetadata: {
      platform: 'slack',
      userEmails: notificationData.slackNotificationEmails,
      conversationId: notificationData.slackChannel,
    },
    events: getEvents(notificationData).map((event) => ({
      ...event,
      configurationId,
    })),
    ...(isAdmin && { ownerId }),
  };
};

// Converts backend payload to frontend notification data
export function convertBackendPayloadToNotificationData(backendData: {
  id: string;
  platform: string;
  platformMetadata: SlackPlatformMetadata;
  workflowId: string;
  events: {
    event: string;
    configurationId: string;
  }[];
}): NotificationData {
  backendData;
  const allEvents = backendData.events.map((e: { event: string }) => e.event);

  return {
    slackActive: backendData.platform === 'slack',
    slackChannel: backendData.platformMetadata.conversationId || '',
    slackNotificationEmails: backendData.platformMetadata.userEmails,
    notifyAbout:
      allEvents.length > 1 ? NotifyAboutEnum.Custom : NotifyAboutEnum.Urgent,
    workflowExecutionCompleted:
      allEvents.includes('execution_failed') ||
      allEvents.includes('execution_success'),
    failedExecutions: allEvents.includes('execution_failed'),
    successfulExecutions: allEvents.includes('execution_success'),
    improvementDetected: allEvents.includes('workflow_improvement_detected'),
  };
}
