export const getTabTitle = (pageName: string, isAdmin = false): string => {
  const suffix = isAdmin ? 'Admin Dashboard' : 'Sola Dashboard';
  return `${pageName} | ${suffix}`;
};

export const getWorkflowTabTitle = (
  workflowName: string | undefined,
  isAdmin = false,
): string => {
  if (!workflowName) {
    return getTabTitle('Editor', isAdmin);
  }
  return getTabTitle(workflowName, isAdmin);
};

export const getExecutionTabTitle = (
  workflowName: string | undefined,
  isAdmin = false,
): string => {
  if (!workflowName) {
    return getTabTitle('Execution', isAdmin);
  }
  return getTabTitle(`Execution | ${workflowName}`, isAdmin);
};
