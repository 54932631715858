"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHitlStep = void 0;
const replay_1 = require("@puppeteer/replay");
const createHitlStep = (id) => {
    return {
        id,
        type: replay_1.StepType.CustomStep,
        name: 'hitl',
        parameters: {},
    };
};
exports.createHitlStep = createHitlStep;
