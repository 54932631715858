"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createKeyStep = exports.parseKeyStep = void 0;
const replay_1 = require("@puppeteer/replay");
const key_1 = require("../../utils/key");
const parseKeyStep = (id, step, _idx) => {
    const key = (0, key_1.translateToSolaSupportedPuppeteerKey)(step.key);
    if ((0, key_1.isSpecialKey)(key) || (0, key_1.isTypableKey)(key)) {
        return {
            id,
            type: step.type,
            key,
            down: step.type === replay_1.StepType.KeyDown,
        };
    }
    throw new Error(`Key ${key} is not supported`);
};
exports.parseKeyStep = parseKeyStep;
const createKeyStep = ({ id, key, down, }) => {
    return {
        id,
        type: down ? replay_1.StepType.KeyDown : replay_1.StepType.KeyUp,
        key,
        down,
    };
};
exports.createKeyStep = createKeyStep;
