"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FILE_EXPLORER_SHORCUTS = exports.FileExplorerShortcuts = void 0;
const types_shared_1 = require("types-shared");
const workflowTypes_1 = require("types-shared/workflowTypes");
const serializer_1 = require("../serializer");
// See https://support.microsoft.com/en-us/windows/keyboard-shortcuts-in-windows-dcc61a57-8ff0-cffe-9796-cb9706c75eec#fileexplorer
var FileExplorerShortcuts;
(function (FileExplorerShortcuts) {
    FileExplorerShortcuts["OpenFileExplorer"] = "Open File Explorer";
    FileExplorerShortcuts["CreateFolder"] = "Create Folder";
    FileExplorerShortcuts["PrevFolder"] = "Previous Folder";
    FileExplorerShortcuts["NextFolder"] = "Next Folder";
    FileExplorerShortcuts["ParentFolder"] = "Parent Folder";
    FileExplorerShortcuts["NewTab"] = "New Tab";
    FileExplorerShortcuts["PrevTab"] = "Prev Tab";
    FileExplorerShortcuts["NextTab"] = "Next Tab";
    FileExplorerShortcuts["SelectAddressBar"] = "Select Address Bar";
    FileExplorerShortcuts["FocusAddressBar"] = "Focus Address Bar";
    FileExplorerShortcuts["SelectSearchBox"] = "Select Search Box";
    FileExplorerShortcuts["RenameSelected"] = "Rename Selected";
    FileExplorerShortcuts["DeleteSelected"] = "Delete Selected";
})(FileExplorerShortcuts || (exports.FileExplorerShortcuts = FileExplorerShortcuts = {}));
const action = new serializer_1.KeyboardActionSerializer(workflowTypes_1.KeyboardShortcutGroupEnum.FileExplorer);
const SHORTCUT_MAPPING = {
    [FileExplorerShortcuts.OpenFileExplorer]: [types_shared_1.META, 'e'],
    [FileExplorerShortcuts.CreateFolder]: [types_shared_1.CONTROL, types_shared_1.SHIFT, 'n'],
    [FileExplorerShortcuts.PrevFolder]: [types_shared_1.ALT, 'ArrowLeft'],
    [FileExplorerShortcuts.NextFolder]: [types_shared_1.ALT, 'ArrowRight'],
    [FileExplorerShortcuts.ParentFolder]: [types_shared_1.ALT, 'ArrowUp'],
    [FileExplorerShortcuts.NewTab]: [types_shared_1.CONTROL, 't'],
    [FileExplorerShortcuts.NextTab]: [types_shared_1.CONTROL, 'Tab'],
    [FileExplorerShortcuts.PrevTab]: [types_shared_1.CONTROL, types_shared_1.SHIFT, 'Tab'],
    [FileExplorerShortcuts.SelectAddressBar]: [types_shared_1.ALT, 'd'],
    [FileExplorerShortcuts.FocusAddressBar]: [types_shared_1.CONTROL, 'l'],
    [FileExplorerShortcuts.SelectSearchBox]: [types_shared_1.CONTROL, 'f'],
    [FileExplorerShortcuts.RenameSelected]: ['F2'],
    [FileExplorerShortcuts.DeleteSelected]: [types_shared_1.SHIFT, 'Delete'],
};
exports.FILE_EXPLORER_SHORCUTS = Object.entries(SHORTCUT_MAPPING).map(([shortcutName, keySeq]) => action.serialize(shortcutName, keySeq));
