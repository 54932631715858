import { type ReactNode, useEffect, useMemo, useState } from 'react';
import {
  BulkCheckableNodeTypes,
  type NodeSelectionModeEnums,
  NodeStatusEnum,
  NodeTypesEnum,
  type WorkflowNode,
  type WorkflowNodeProps,
} from 'types-shared';
import { EditorStore, type EditorStoreProps } from '../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import Checkbox from '@mui/material/Checkbox';
import {
  Button,
  CheckCircleIcon,
  modalEventChannel,
  Tooltip,
  Add,
  DeleteOutlineIcon,
  EditOutlined,
  Error as AssetError,
  RadioButtonUncheckedOutlined,
  EyeIcon,
  HideEyeIcon,
} from 'ui-kit';
import { useSearchParams } from 'react-router-dom';
import { CustomHandle } from '../EdgeElement/Handle';
import { clsx } from 'clsx';
import { useEditingNodeId } from '../../hooks/useEditingNodeId';
import {
  getAllNodesAfter,
  insertNodeAfter,
  removeNode,
} from '../../utils/helper';
import { nodeSelectEventChannel } from '../ActionsHeader';
import { isAdmin } from '../../../../utils/env';

const actionIconMap: Record<string, ReactNode | undefined> = {
  [NodeStatusEnum.Checked]: <CheckCircleIcon className="!fill-none" />,
  [NodeStatusEnum.Error]: <AssetError className="!text-error" />,
};

interface Props {
  workflowData: WorkflowNodeProps;
  children: ReactNode;
  showAddButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
  onClick?: () => void;
  label?: string;
  allowBulkCheck?: boolean;
  leftConnectable?: boolean;
  rightConnectable?: boolean;
  darkBg?: boolean;
}

function NodeElement({
  workflowData,
  children,
  showAddButton,
  showEditButton,
  showDeleteButton,
  onClick,
  label = 'Step',
  allowBulkCheck = false,
  leftConnectable = isAdmin,
  rightConnectable = isAdmin,
  darkBg = false,
}: Props) {
  const [, setSearchParams] = useSearchParams();
  const { id, data, type } = workflowData;
  const [aboutToDelete, setAboutToDelete] = useState(false);
  const [nodeSelectionModeEnabled, setNodeSelectionModeEnabled] =
    useState<NodeSelectionModeEnums | null>(null);
  const { nodes, edges, setEdges, setNodes, setSelectedNode } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      edges: state.edges,
      setEdges: state.setEdges,
      setNodes: state.setNodes,
      setSelectedNode: state.setSelectedNode,
    })),
  );
  const { setEditingNodeId } = useEditingNodeId();

  const updateAboutToDelete = (status: boolean) => () => {
    setAboutToDelete(status);
  };

  const status = data.nodeStatus;
  const showIcon = useMemo(
    () =>
      [NodeStatusEnum.Error, NodeStatusEnum.Checked].includes(status) &&
      type !== 'source',
    [status, type],
  );
  const actionIcon = actionIconMap[status];
  const { editingNodeId } = useEditingNodeId();

  const { isLastNode, isConditional, isHidden } = useMemo(() => {
    const payload = {
      isLastNode: true,
      isConditional: false,
      isHidden: false,
    };
    const thisNode = nodes.find((n) => n.id === id);
    const thisNodeIsConditional = thisNode?.type === NodeTypesEnum.Conditional;
    payload.isHidden = Boolean(thisNode?.hideFromUser);

    if (!thisNodeIsConditional) {
      return payload;
    }

    const noEdgeHasThisAsSrc = !edges.find((e) => e.source === thisNode.id);

    payload.isConditional = true;
    payload.isLastNode = noEdgeHasThisAsSrc;

    return payload;
  }, [id, nodes, edges]);

  const insertNode = (sourceId: string) => {
    const node = nodes.find((n) => n.id === sourceId);
    if (!node) {
      throw new Error('step not found');
    }

    const createABranch = isConditional && isLastNode;

    const { nodeId } = insertNodeAfter(
      node,
      nodes,
      edges,
      {
        setNodes,
        setEdges,
      },
      createABranch,
    );
    setEditingNodeId(nodeId);
  };

  const closeModal = () => {
    modalEventChannel.emit('close');
  };

  const disableNode = useMemo(() => {
    const stopNodes = nodes.filter(
      ({ type: nodeType }) => nodeType === NodeTypesEnum.Stop,
    );
    return stopNodes.some((n) => {
      const followingNodes = getAllNodesAfter(n, nodes, edges);
      return followingNodes.indexOf(id) > 0;
    });
  }, [id, nodes, edges]);

  const handleDelete = () => {
    const { nodes: filteredNodes, edges: filteredEdges } = removeNode(
      nodes,
      edges,
      id,
    );

    setNodes(filteredNodes);
    setEdges(filteredEdges);
    setSearchParams({});
    setSelectedNode(null);
    setEditingNodeId(undefined);
  };

  const selectedNode: WorkflowNode | undefined = useMemo(() => {
    if (!allowBulkCheck) return undefined;
    const workflowNode = nodes.find((node) => node.id === workflowData.id);
    if (
      workflowNode?.type &&
      BulkCheckableNodeTypes.includes(workflowNode.type)
    ) {
      return workflowNode;
    }
  }, [allowBulkCheck, workflowData.id, nodes]);

  const toggleNodeSelection = () => {
    const filteredNodes = nodes.map((node) => {
      if (
        node.id !== workflowData.id ||
        !BulkCheckableNodeTypes.includes(node.type)
      ) {
        return node;
      }

      return {
        ...node,
        data: {
          ...node.data,
          selected: !(node.data as { selected: boolean }).selected,
        },
      };
    });
    setNodes(filteredNodes as WorkflowNode[]);
  };

  const openModal = () => {
    modalEventChannel.emit('open', {
      title: `Are you sure do you want to delete this ${label}?`,
      descriptions:
        type === 'image'
          ? [
              'Deleting an image step might break your workflow. You can always restore an older version if needed.',
            ]
          : [],
      actions: [
        {
          text: 'Yes, Delete step',
          onClick: () => {
            handleDelete();
            closeModal();
          },
          color: 'error',
        },
        {
          text: 'CANCEL',
          onClick: closeModal,
          color: 'secondary',
          variant: 'outlined',
        },
      ],
    });
  };

  const toggleNodeVisibility = () => {
    const filteredNodes = nodes.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          hideFromUser: !isHidden,
          data: {
            ...node.data,
            nodeStatus: !isHidden
              ? NodeStatusEnum.Checked
              : node.data.nodeStatus,
          },
        };
      }
      return node;
    }) as WorkflowNode[];
    setNodes(filteredNodes);
  };

  useEffect(() => {
    const unsubscribe = nodeSelectEventChannel.on(
      'onToggleSelection',
      (mode: NodeSelectionModeEnums | null) => {
        setNodeSelectionModeEnabled(mode);
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div id={id}>
      <div className="group relative">
        {isAdmin ? (
          <span className="absolute -top-6 left-1/2 -translate-x-1/2 font-semibold">
            {id.slice(0, 5)}
          </span>
        ) : null}
        {!nodeSelectionModeEnabled && showDeleteButton ? (
          <button
            className="delete-node-btn hidden absolute top-0 right-0 !w-6 !h-6 z-[20] group-hover:flex w-6 h-6 rounded-full flex justify-center items-center !py-0 !px-0 text-info-dark border-2 border-primary-blue hover:border-red-600 bg-white hover:bg-red-600 hover:text-white !cursor-pointer"
            onClick={() => {
              openModal();
            }}
            onMouseEnter={updateAboutToDelete(true)}
            onMouseLeave={updateAboutToDelete(false)}
            type="button"
          >
            <DeleteOutlineIcon className="!w-4 !h-4 fill-none" />
          </button>
        ) : null}
        <div
          className={clsx('p-2 relative', {
            '!bg-gradient-to-br from-info-extralight to-purple-light !shadow-primary-purple drop-shadow-sm hover:from-info-semi-light hover:to-purple-light hover:drop-shadow-xl hover:shadow-inherit !rounded-3xl':
              status === NodeStatusEnum.Autolinked,
            'after:content-[""] after:bg-gray-500 after:absolute after:top-0 after:left-0 after:w-full after:h-full after:z-10 after:opacity-50 after:rounded-3xl':
              isHidden,
            'after:pointer-events-none': isAdmin && isHidden, // Make this clickable even if its hidden but only for admins
          })}
        >
          <div
            className={clsx(
              'relative flex flex-col space-y-3 overflow-hidden rounded-2xl p-3 w-60 h-50 ring-8 ring-transparent border border-indigo-light hover:border-info hover:drop-shadow-xl',
              {
                'bg-info-dark': darkBg,
                'bg-white': !darkBg,
                'border-2 !ring-info-extralight hover:!ring-info-semi-light':
                  type !== 'source' &&
                  type !== 'new' &&
                  (status === NodeStatusEnum.NotViewed ||
                    status === NodeStatusEnum.Viewed ||
                    (!data.selected && nodeSelectionModeEnabled)),
                '!border-error !ring-error-extralight hover:!ring-error-light':
                  status === NodeStatusEnum.Error,
                '!border-checked-green !ring-primary-light-green hover:!ring-checked-green-light':
                  data.selected,
                '!border-primary-purple': status === NodeStatusEnum.Autolinked,
                '!border-info hover:!border-info shadow-xl':
                  editingNodeId === id,
                '!border-red-600': aboutToDelete,
              },
            )}
            onClick={onClick}
            role="presentation"
          >
            {disableNode ? (
              <Tooltip
                arrow
                placement="top"
                title="Step is disabled because the execution will not reach this step"
                tooltipClassName="!mb-6"
              >
                <div className="w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-[#C4D4DA] z-[4] opacity-60" />
              </Tooltip>
            ) : null}
            <div className="w-full h-full !mt-0 flex flex-col space-y-3">
              {children}
            </div>
            {showIcon ? (
              <div
                className={clsx(
                  'absolute bottom-3 right-3 flex justify-between items-center',
                  {
                    'text-error left-3': status === NodeStatusEnum.Error,
                    'bg-white': !darkBg,
                    'bg-info-dark': darkBg,
                  },
                )}
              >
                {status === NodeStatusEnum.Error && <span>Unavailable</span>}
                {actionIcon ? actionIcon : null}
              </div>
            ) : null}
          </div>
          <CustomHandle isConnectable={leftConnectable} type="target" />
          <CustomHandle isConnectable={rightConnectable} type="source" />
        </div>
        <div className="mt-4 w-full flex justify-center nodrag nopan">
          {!nodeSelectionModeEnabled ? (
            <div className="justify-center gap-3 hidden absolute group-hover:flex">
              {isAdmin ? (
                <Button
                  className="!min-w-min"
                  color="secondary"
                  onClick={toggleNodeVisibility}
                  variant="text"
                >
                  {isHidden ? (
                    <EyeIcon className="!fill-none" />
                  ) : (
                    <HideEyeIcon className="!fill-none" />
                  )}
                </Button>
              ) : null}
              {showEditButton ? (
                <Button
                  className="!min-w-min h-10 w-10 flex justify-center items-center !py-1 !px-3 !rounded !border-2 hover:!bg-sola-primary hover:!text-white"
                  color="secondary"
                  onClick={() => {
                    setEditingNodeId(id);
                  }}
                  variant="outlined"
                >
                  <EditOutlined />
                </Button>
              ) : null}
              {showAddButton && (!isConditional || isLastNode) ? (
                <Button
                  className="!min-w-min h-10 w-10 flex justify-center items-center !py-1 !px-3 !rounded !border-2 hover:!bg-sola-primary hover:!text-white add-node-btn"
                  color="secondary"
                  onClick={() => {
                    insertNode(id);
                  }}
                  variant="outlined"
                >
                  <Add />
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>

      {allowBulkCheck && nodeSelectionModeEnabled ? (
        <div className="mt-4 w-full flex justify-center nodrag nopan">
          <div
            className="bg-white aspect-square rounded-xl absolute !cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              toggleNodeSelection();
            }}
            role="presentation"
          >
            <Checkbox
              checked={
                (selectedNode?.data as { selected: boolean } | null)
                  ?.selected ?? false
              }
              checkedIcon={
                <CheckCircleIcon className="!fill-none" strokeColor="#246bfa" />
              }
              className="!p-4"
              icon={<RadioButtonUncheckedOutlined />}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default NodeElement;
