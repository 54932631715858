import * as React from 'react';
import { useRef, useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import type dayjs from 'dayjs';
import type {} from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3274fb',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#e6e7eb',
          },
          '&:hover fieldset': {
            borderColor: '#e6e7eb',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#e6e7eb',
          },
        },
      },
    },
  },
});
export default function BasicDateTimePicker({
  label,
  onChange,
  onAccept,
  value,
  noTheme,
  labelBackground = '#ffffff',
  disablePast = true,
}: {
  label: string;
  onChange?: (newValue: dayjs.Dayjs) => void;
  onAccept?: (newValue: dayjs.Dayjs) => void;
  value?: dayjs.Dayjs;
  noTheme?: boolean;
  labelBackground?: string;
  disablePast?: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.onclick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        setIsOpen(true);
      };
    }
  }, []);

  return noTheme ? (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        inputRef={inputRef}
        open={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        disablePast={disablePast}
        label={label}
        onChange={(newValue) => {
          if (newValue) {
            onChange?.(newValue);
          }
        }}
        onAccept={(newValue) => {
          if (newValue) {
            onAccept?.(newValue);
          }
        }}
        sx={{
          '& .MuiInputLabel-root': {
            transition: 'display 0s 200ms',
          },
          '& .MuiInputLabel-shrink': {
            display: 'block',
            background: labelBackground,
          },
          '& .MuiOutlinedInput-notchedOutline legend': {
            display: 'none',
          },
        }}
        value={value ?? null}
      />
    </LocalizationProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          inputRef={inputRef}
          open={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          onAccept={(newValue) => {
            if (newValue) {
              onAccept?.(newValue);
            }
          }}
          disablePast={disablePast}
          label={label}
          onChange={(newValue) => {
            if (newValue) {
              onChange?.(newValue);
            }
          }}
          value={value ?? null}
          sx={{
            '& .MuiInputLabel-root': {
              transition: 'display 0s 200ms',
            },
            '& .MuiInputLabel-shrink': {
              display: 'block',
              background: '#ffffff',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#e6e7eb',
            },
            '& .MuiOutlinedInput-notchedOutline legend': {
              display: 'none',
            },
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
