import {
  useMutation,
  type UseMutationResult,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import { type FileMetadata } from 'api-types-shared';
import { useAPI } from '../../hooks/useApi';
import { downloadLinkData } from '../../utils/helper';
import { AlertVariant, notify } from 'ui-kit';
import { downloadZip } from 'client-zip';
import isNil from 'lodash/isNil';

export function useWorkflowFiles(
  workflowId: string,
  includeDeleted = false,
): UseQueryResult<FileMetadata[]> {
  const { fileSDK } = useAPI();

  return useQuery({
    queryKey: ['workflow-files', workflowId],
    queryFn: () => {
      return fileSDK.listFiles({
        query: { workflowId, includeDeleted },
      });
    },
  });
}

export function useUploadWorkflowFile(
  workflowId: string,
): UseMutationResult<unknown, unknown, File> {
  const { fileSDK } = useAPI();
  return useMutation({
    mutationFn: (file: File) => fileSDK.addFiles([file], workflowId),
  });
}

export function useGetFileUrl(
  fileId: string,
  filename: string,
): UseQueryResult<Blob> {
  const { fileSDK } = useAPI();
  return useQuery({
    queryKey: ['file-url', fileId],
    queryFn: async () => {
      const blob = await fileSDK.getFileBlob(fileId);
      const fileUrl = URL.createObjectURL(blob);
      downloadLinkData(fileUrl, filename);
      return fileUrl;
    },
    enabled: false,
  });
}

export function useDeleteWorkflowFile(): UseMutationResult<
  unknown,
  unknown,
  string
> {
  const { fileSDK } = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fileId: string) => fileSDK.deleteFile(fileId),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['workflow-files'] });
      notify({
        message: 'File deleted successfully.',
        variant: AlertVariant.SUCCESS,
      });
    },
  });
}

export function useDownloadWorkflowFiles(
  workflowId: string,
): UseMutationResult<unknown, unknown, FileMetadata[]> {
  const { fileSDK } = useAPI();
  return useMutation({
    mutationFn: async (files: FileMetadata[]) => {
      const fileBlobs = await Promise.all(
        files.map(({ fileId }) => fileSDK.getFileBlob(fileId)),
      );
      const fileObj = await Promise.all(
        fileBlobs.map((blob, i) => {
          const name = files[i].name;
          return {
            name,
            input: blob,
            lastModified: new Date(),
          };
        }),
      );

      const blob = await downloadZip(
        fileObj.filter((file) => !isNil(file)),
      ).blob();
      const url = URL.createObjectURL(blob);
      downloadLinkData(url, `workflow_${workflowId}_files.zip`);
      return url;
    },
  });
}
