"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serializeWorkflowAction = void 0;
const serializeWorkflowAction = (step, nodeId, action) => {
    return Object.assign(Object.assign({}, step), { legacyCompatibilityOptions: {
            step: {
                nodeId,
                stepId: action.id,
            },
            stepData: {
                nodeId,
                stepIds: [action],
            },
        } });
};
exports.serializeWorkflowAction = serializeWorkflowAction;
// TODO: Add deserialization (validation) method which will be invoked DesktopStepJSONDeserializer.deserialize (?)
