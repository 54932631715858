import { useFeatureFlagPayload } from 'posthog-js/react';
import { FeatureFlag } from '../utils/constants';
import { useAuth } from '../utils/helper';
import { isAdmin } from '../utils/env';

export default function useWorkflowLimit() {
  const { user } = useAuth();
  if (!isAdmin && !user?.email) {
    throw new Error('User email not found');
  }
  const frontendSandboxLimitImportV2Payload = useFeatureFlagPayload(
    FeatureFlag.FrontendSandboxLimitImportV2,
  ) as { users: { email: string; limit: number }[] } | undefined;
  if (isAdmin) {
    return 1000000;
  }
  const limit = frontendSandboxLimitImportV2Payload?.users.find(
    (_user) => _user.email === user?.email,
  )?.limit;
  return limit;
}
