import { type SvgIconProps } from '@mui/material/SvgIcon';
import { clsx } from 'clsx';
import { type ComponentType } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  text: string;
  href: string;
  className?: string;
  disabled: boolean;
  target?: string;
  Icon: ComponentType<SvgIconProps>;
  isExpanded: boolean;
}

export default function NavigationItem({
  text,
  href,
  className,
  disabled,
  target,
  Icon,
  isExpanded,
}: Props) {
  const location = useLocation();
  const active = location.pathname === href;

  return (
    <Link
      className={clsx(
        'block w-full',
        disabled && 'pointer-events-none opacity-50',
      )}
      to={href}
      target={target}
    >
      <li
        className={clsx(
          'w-full px-3 py-4 transition text-color-grey rounded hover:bg-sola-primary hover:bg-opacity-10',
          active && 'bg-sola-primary bg-opacity-10',
          className,
        )}
      >
        <div
          className={clsx(
            'w-full h-full flex items-center font-medium space-x-3',
            active && 'text-black',
          )}
        >
          <Icon
            className={clsx('!h-5 !w-5', {
              '!mx-auto': !isExpanded,
              'text-sola-primary': active,
            })}
          />
          {isExpanded ? <span>{text}</span> : null}
        </div>
      </li>
    </Link>
  );
}
