import MenuItem from '@mui/material/MenuItem';
import type { ChangeEvent } from 'react';
import React, { useMemo, useState, useRef, useEffect } from 'react';
import {
  EmailTriggerVariable,
  VariableTypeEnum,
  type GlobalVariable,
  type Variable,
  type VariableMap,
} from 'types-shared';
import { VariableChip } from '../../../../../../components/VariableChip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, NotFound, Tooltip } from 'ui-kit';
import SearchIcon from '@mui/icons-material/Search';
import { clsx } from 'clsx';
import { useFeatureFlag } from '../../../../../../utils/helper';
import { FeatureFlag } from '../../../../../../utils/constants';
import { Divider } from '@mui/material';
import {
  executionVariableTitleMapping,
  deletableVariableTypes,
} from '../../../../utils/constants';
import { handleException } from 'sentry-browser-shared';
import {
  EditorStore,
  type EditorStoreProps,
} from '../../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { whichVariablesUsedInWorkflow } from '../../../../utils/delete-variable';
import { useSourceVariable } from '../../../../hooks/useSourceVariable';
import {
  isDerivedFromDocumentVariable,
  isEmailVariable,
} from '../../../../utils/helper';
import { VariableInputMenuTabsEnum } from '../Input2.0/types';

interface Props {
  onClose: () => void;
  onSelect: (val: Variable) => void;
  onAddNew?: () => void;
  variables: Variable[];
  globalVariables: GlobalVariable[];
  allowAddVariable: boolean;
  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  disabledAddVariableTooltip?: string;
  allowedTabs?: VariableInputMenuTabsEnum[];
}

type VariableFiltersType = {
  [key in VariableInputMenuTabsEnum]: VariableTypeEnum;
};

const VariableFilters: VariableFiltersType = {
  [VariableInputMenuTabsEnum.Input]: VariableTypeEnum.Query,
  [VariableInputMenuTabsEnum.Scrapes]: VariableTypeEnum.Scrape,
  [VariableInputMenuTabsEnum.Shared]: VariableTypeEnum.Global,
  [VariableInputMenuTabsEnum.Execution]: VariableTypeEnum.Execution,
  [VariableInputMenuTabsEnum.Documents]: VariableTypeEnum.Document,
  [VariableInputMenuTabsEnum.Email]: VariableTypeEnum.Query,
};

type NoVariablesTextType = {
  [key in VariableInputMenuTabsEnum]: string;
};

const noVariablesText: NoVariablesTextType = {
  [VariableInputMenuTabsEnum.Input]:
    'No input variables have been created for this workflow yet',
  [VariableInputMenuTabsEnum.Scrapes]:
    'No scrapes have been created for this workflow yet',
  [VariableInputMenuTabsEnum.Shared]:
    'No global variables have been created for this team yet',
  [VariableInputMenuTabsEnum.Execution]: '', // Will always exist
  [VariableInputMenuTabsEnum.Documents]:
    'No document variables have been created for this workflow yet',
  [VariableInputMenuTabsEnum.Email]:
    'No email variables have been created for this workflow yet',
};

function VariableMenuContent({
  onClose,
  onSelect,
  onAddNew,
  variables = [],
  globalVariables = [],
  allowAddVariable = true,
  variablesMap,
  globalVariablesMap,
  disabledAddVariableTooltip,
  allowedTabs = Object.values(VariableInputMenuTabsEnum),
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { nodes, deleteVariable } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      nodes: state.nodes,
      deleteVariable: state.deleteVariable,
    })),
  );
  const [search, setSearch] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<VariableInputMenuTabsEnum>(
    VariableInputMenuTabsEnum.Input,
  );
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const { isEmailTrigger } = useSourceVariable(variablesMap);

  const usedVariables = useMemo(
    () => whichVariablesUsedInWorkflow(nodes, variablesMap, handleException),
    [nodes, variablesMap],
  );

  const systemVariablesEnabled = useFeatureFlag(
    FeatureFlag.SystemVariables,
    true,
  );
  const globalVariablesEnabled = useFeatureFlag(FeatureFlag.GlobalVariables);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const enabledVariables = useMemo(() => {
    const otherVariables = variables.filter(
      (v) => v.type !== VariableTypeEnum.Execution,
    );
    const systemVariables = variables.filter(
      (v) => v.type === VariableTypeEnum.Execution,
    );

    const allEnabledVariables = [
      ...(systemVariablesEnabled ? systemVariables : []),
      ...(globalVariablesEnabled ? globalVariables : []),
      ...otherVariables,
    ] as Variable[];

    return allEnabledVariables.filter((v) =>
      allowedTabs.some((t) => VariableFilters[t] === v.type),
    );
  }, [
    globalVariablesEnabled,
    systemVariablesEnabled,
    globalVariables,
    variables,
    allowedTabs,
  ]);

  const variablesToShow = useMemo(() => {
    const isEmailVariableMenu = selectedTab === VariableInputMenuTabsEnum.Email;
    const isQueryVariableMenu = selectedTab === VariableInputMenuTabsEnum.Input;
    const isScrapeVariableMenu =
      selectedTab === VariableInputMenuTabsEnum.Scrapes;

    const activeVariables: Variable[] = [
      ...variables,
      ...globalVariables,
    ].filter((v) => {
      if (isEmailVariableMenu) {
        return isEmailVariable(v, variablesMap);
      }

      if (isScrapeVariableMenu) {
        return (
          v.type === VariableFilters[selectedTab] ||
          isDerivedFromDocumentVariable(v, variablesMap)
        );
      }

      if (isQueryVariableMenu) {
        return (
          v.type === VariableFilters[selectedTab] &&
          !isEmailVariable(v, variablesMap) &&
          !isDerivedFromDocumentVariable(v, variablesMap)
        );
      }
      return v.type === VariableFilters[selectedTab];
    });

    if (search === '') {
      return activeVariables;
    }

    return enabledVariables.filter((variable) => {
      if (variable.type === VariableTypeEnum.Execution) {
        return executionVariableTitleMapping[variable.name]
          .toLowerCase()
          .includes(search.toLowerCase());
      }
      if (variable.type === VariableTypeEnum.Document && !variable.name) {
        return 'document-1'.includes(search.toLowerCase());
      }

      return (
        !EmailTriggerVariable.safeParse(variable).success &&
        variable.name?.toLowerCase().includes(search.toLowerCase())
      );
    });
  }, [
    selectedTab,
    globalVariables,
    variables,
    search,
    enabledVariables,
    variablesMap,
  ]);

  const tabItems = useMemo(
    () =>
      [
        {
          label: VariableInputMenuTabsEnum.Input,
          normalMessage: 'These variables are included in the API request',
        },
        {
          label: VariableInputMenuTabsEnum.Scrapes,
          normalMessage: 'These variables were scraped during the recording',
        },
        {
          label: VariableInputMenuTabsEnum.Shared,
          normalMessage: 'These variables are shared among your team members',
          disabled: !globalVariablesEnabled,
          disabledMessage:
            'Use shared variables across your team’s workflows. Contact sales to learn more!',
        },
        {
          label: VariableInputMenuTabsEnum.Execution,
          normalMessage: 'These variables vary depending on the execution',
          disabled: !systemVariablesEnabled,
          disabledMessage: 'Contact sales to access this feature.',
        },
        {
          label: VariableInputMenuTabsEnum.Documents,
          normalMessage:
            'These were the documents downloaded during the recording',
        },
        ...(isEmailTrigger
          ? [
              {
                label: VariableInputMenuTabsEnum.Email,
                normalMessage:
                  'These are variables derived from the email trigger.',
              },
            ]
          : []),
      ].filter((t) => allowedTabs.includes(t.label)),
    [
      systemVariablesEnabled,
      globalVariablesEnabled,
      isEmailTrigger,
      allowedTabs,
    ],
  );
  useEffect(() => {
    if (initialLoadDone) return;
    setInitialLoadDone(true);
    setSelectedTab(tabItems[0].label);
  }, [tabItems, initialLoadDone]);

  const onTabChange = (
    _event: React.SyntheticEvent,
    newValue: VariableInputMenuTabsEnum,
  ) => {
    const isDisabled = tabItems.find((t) => t.label === newValue)?.disabled;
    if (isDisabled) return;
    setSelectedTab(newValue);
    if (search.length) {
      setSearch('');
    }
  };

  return (
    <React.Fragment key={selectedTab}>
      <Box
        className="zoom-adjusted-container"
        sx={{
          flexShrink: 0,
        }}
      >
        <Tabs
          onChange={onTabChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#3274fb',
            },
            '& .Mui-selected': {
              color: '#3274fb !important',
            },
          }}
          value={selectedTab}
          variant={tabItems.length === 1 ? 'fullWidth' : 'standard'}
        >
          {tabItems.map(
            ({ label, disabled, disabledMessage, normalMessage }) => (
              <Tab
                key={label}
                sx={{
                  flexGrow: 1,
                }}
                value={
                  VariableInputMenuTabsEnum[
                    label as keyof typeof VariableInputMenuTabsEnum
                  ]
                }
                disabled={disabled}
                label={
                  <Tooltip
                    title={disabled ? disabledMessage : normalMessage}
                    arrow
                  >
                    <span className={disabled ? 'text-gray-400' : ''}>
                      {label}
                    </span>
                  </Tooltip>
                }
              />
            ),
          )}
        </Tabs>
      </Box>

      <Box
        sx={{
          flexShrink: 0,
        }}
      >
        {[...variables, ...globalVariables].length > 0 ? (
          <MenuItem
            className="!bg-white !px-3 !py-4 "
            disableRipple
            onClick={() => inputRef.current?.focus()}
          >
            <div
              className={clsx({
                'outline-none border rounded w-full': true,
                ' pl-1': !search.length,
                'focus-within:border-blue-500 focus-within:ring-1 focus-within:ring-blue-500':
                  !search.length,
              })}
              onClick={() => inputRef.current?.focus()}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              role="presentation"
            >
              {search.length ? null : (
                <SearchIcon className="text-info-dark mr-1" />
              )}

              <input
                ref={inputRef}
                className={clsx({
                  'p-1 text-sm': true,
                  'border-0 focus:outline-none': !search.length,
                  'w-full ': Boolean(search.length),
                })}
                onChange={onSearchChange}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                placeholder="Search variable"
                value={search}
              />
            </div>
          </MenuItem>
        ) : (
          <MenuItem
            className="!px-3 !py-2.5 pointer-events-none !text-xs"
            divider
          >
            {noVariablesText[selectedTab]}
          </MenuItem>
        )}
      </Box>

      <Box
        sx={{
          flex: '1 1 auto',
        }}
      >
        {variablesToShow.length ? (
          <div
            className="overflow-y-auto"
            style={{ maxHeight: '500px' }}
            key={selectedTab}
          >
            {variablesToShow.map((variable: Variable) => {
              const isUsed = usedVariables.includes(variable.id);
              const isDeletable = deletableVariableTypes.includes(
                variable.type,
              );

              return (
                <MenuItem
                  className="!px-3 !py-2.5"
                  key={variable.id}
                  onClick={() => {
                    onSelect(variable);
                    onClose();
                  }}
                >
                  <VariableChip
                    variableId={variable.id}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    {...(isUsed || !isDeletable
                      ? {}
                      : {
                          onDelete: () => {
                            deleteVariable(variable.id);
                          },
                        })}
                  />
                </MenuItem>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center p-5">
            {search.length ? (
              <div>
                <NotFound
                  fontSize="large"
                  style={{
                    height: '55px',
                    width: '43px',
                  }}
                />
              </div>
            ) : null}
            <p
              className={clsx({
                'text-gray-500 text-normal text-center text-xs': true,
                'mt-3': search.length,
                'mt-10': !search.length,
              })}
            >
              {search.length
                ? `There are no matching results for "${search}".`
                : noVariablesText[selectedTab]}
            </p>
          </div>
        )}
      </Box>

      {selectedTab === VariableInputMenuTabsEnum.Input ? (
        <Box
          sx={{
            flexShrink: 0,
          }}
        >
          <Divider />
          <MenuItem
            className="!border-t !border-t-gray-300 !text-blue-600 !px-3 !py-2.5"
            onClick={() => onAddNew?.()}
          >
            <Tooltip
              title={disabledAddVariableTooltip}
              arrow
              hidden={allowAddVariable || !disabledAddVariableTooltip}
              containerClassName="!w-full block"
            >
              <Button
                className="w-full h-9 zoom-countered-element !text-xs"
                color="secondary"
                onClick={() => onAddNew?.()}
                variant="contained"
                disabled={!allowAddVariable}
              >
                Add input variables
              </Button>
            </Tooltip>
          </MenuItem>
        </Box>
      ) : null}
    </React.Fragment>
  );
}

export default VariableMenuContent;
