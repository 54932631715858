"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTypableKey = exports.isSpecialKey = exports.shift = void 0;
exports.translateToSolaSupportedPuppeteerKey = translateToSolaSupportedPuppeteerKey;
const types_shared_1 = require("types-shared");
function translateToSolaSupportedPuppeteerKey(puppeteerKey) {
    const keyMap = {
        MetaLeft: 'Meta',
        MetaRight: 'Meta',
        ShiftLeft: 'Shift',
        ShiftRight: 'Shift',
        ControlLeft: 'Control',
        ControlRight: 'Control',
        AltLeft: 'Alt',
        AltRight: 'Alt',
        Space: ' ',
    };
    if (puppeteerKey in keyMap) {
        return keyMap[puppeteerKey];
    }
    return puppeteerKey;
}
const shift = (key) => {
    const shiftMap = {
        // Numbers
        '0': ')',
        '1': '!',
        '2': '@',
        '3': '#',
        '4': '$',
        '5': '%',
        '6': '^',
        '7': '&',
        '8': '*',
        '9': '(',
        // Letters
        a: 'A',
        b: 'B',
        c: 'C',
        d: 'D',
        e: 'E',
        f: 'F',
        g: 'G',
        h: 'H',
        i: 'I',
        j: 'J',
        k: 'K',
        l: 'L',
        m: 'M',
        n: 'N',
        o: 'O',
        p: 'P',
        q: 'Q',
        r: 'R',
        s: 'S',
        t: 'T',
        u: 'U',
        v: 'V',
        w: 'W',
        x: 'X',
        y: 'Y',
        z: 'Z',
        // Punctuation and symbols
        '-': '_',
        '=': '+',
        '[': '{',
        ']': '}',
        '\\': '|',
        ';': ':',
        "'": '"',
        ',': '<',
        '.': '>',
        '/': '?',
        '`': '~',
        // Space
        ' ': ' ',
    };
    if (key in shiftMap) {
        return shiftMap[key];
    }
    throw new Error(`Unknown key: ${key}`);
};
exports.shift = shift;
const isSpecialKey = (key) => {
    return types_shared_1.SPECIAL_KEYS.includes(key);
};
exports.isSpecialKey = isSpecialKey;
const isTypableKey = (key) => {
    return types_shared_1.TYPABLE_KEYS.includes(key);
};
exports.isTypableKey = isTypableKey;
