"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EXCEL_HOMETAB_SHORCUTS = exports.ExcelHomeTabShortcuts = void 0;
const types_shared_1 = require("types-shared");
const workflowTypes_1 = require("types-shared/workflowTypes");
const serializer_1 = require("../../serializer");
var ExcelHomeTabShortcuts;
(function (ExcelHomeTabShortcuts) {
    ExcelHomeTabShortcuts["ClearContent"] = "Clear Content";
})(ExcelHomeTabShortcuts || (exports.ExcelHomeTabShortcuts = ExcelHomeTabShortcuts = {}));
const action = new serializer_1.KeyboardActionSerializer(workflowTypes_1.KeyboardShortcutGroupEnum.ExcelHomeTab, {
    prefixSeq: [types_shared_1.ALT, 'h'],
});
const SHORTCUT_MAPPING = {
    [ExcelHomeTabShortcuts.ClearContent]: ['e', 'c'],
};
exports.EXCEL_HOMETAB_SHORCUTS = Object.entries(SHORTCUT_MAPPING).map(([shortcutName, keySeq]) => action.serialize(shortcutName, keySeq));
