import {
  CloseIcon,
  Button,
  Input,
  Select,
  GrayedOutInput,
  Tooltip,
} from 'ui-kit';
import {
  type DatasourceMetadata,
  type DatasourceTable,
  NodeStatusEnum,
  type SourceTypeEnum,
  type TemplateData,
  type Variable,
  type VariableMap,
  type WorkflowEmailNode,
  type GlobalVariable,
  EmailActionEnum,
  EmailTypeEnum,
  EmailProviderEnum,
} from 'types-shared';
import type { SelectChangeEvent } from '@mui/material/Select';
import { createTemplateVariable } from '../request.helpers';
import { NodeCheck } from '../../NodeCheck';
import { isAdmin } from '../../../../../utils/env';
import CreateContactAction from './CreateContactAction';
import SendEmailAction from './SendEmailAction';
import { EditorStore, type EditorStoreProps } from '../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';
import { useAuth } from '../../../../../utils/helper';
import { type GetWorkflowMetadataResponse } from 'api-types-shared';
import {
  useGetParagonUserToken,
  useParagonAuth,
} from '../../../../Integrations/hooks';
import { useEffect, useMemo, useState } from 'react';
import { isNull } from 'lodash';

export type WorkflowEmailNodeCoreData = Omit<
  WorkflowEmailNode['data'],
  'nodeStatus' | 'selected'
>;

interface Props {
  node: WorkflowEmailNode;
  onCancel: () => void;
  onUpdateData: (data: WorkflowEmailNodeCoreData) => void;
  variablesMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
  addVariable: (variable: Variable) => void;
  updateVariable: (variable: Variable) => void;
  transformApiReqStatus: 'error' | 'idle' | 'pending' | 'success' | 'loading';
  sourceType?: SourceTypeEnum;
  onTransformApiReq: (
    prompt: TemplateData,
    textToTransform: string,
  ) => Promise<string | undefined>;
  updateNodeStatus: (status: NodeStatusEnum) => void;
  updateNodeName: (name: string) => void;
  workflowMetadata?: GetWorkflowMetadataResponse | null;
}

const emailActionOptions = Object.values(EmailActionEnum).filter(
  (o) => o !== EmailActionEnum.CreateContact,
);
const emailProviders = Object.values(EmailProviderEnum);
const emailProviderLabels = {
  [EmailProviderEnum.Gmail]: (
    <div className="flex flex-row gap-2">
      <div className="w-6 flex items-center justify-center">
        <img alt="logo" className="w-5 bg-white" src="/gmail.png" />
      </div>
      <span>Gmail</span>
    </div>
  ),
  [EmailProviderEnum.Outlook]: (
    <div className="flex flex-row gap-2">
      <div className="w-6 flex items-center justify-center">
        <img alt="logo" className="w-5 bg-white" src="/outlook.svg" />
      </div>
      <span>Outlook</span>
    </div>
  ),
};

export function EmailBlock({
  node,
  onCancel,
  onUpdateData,
  variablesMap,
  globalVariablesMap,
  updateNodeStatus,
  updateNodeName,
  workflowMetadata,
  ...props
}: Props) {
  const [defaultProviderSet, setDefaultProviderSet] = useState(false);
  const { addVariable } = EditorStore(
    useShallow((state: EditorStoreProps) => ({
      addVariable: state.addVariable,
    })),
  );
  const { updateVariable } = props;
  const emailNodeData = node.data;
  const { emailAction, createContactDetails, sendEmailDetails } = emailNodeData;
  const { user: authUser } = useAuth();

  const { data, isLoading: providersLoading } = useGetParagonUserToken(
    workflowMetadata?.userId,
  );
  const { user } = useParagonAuth(data?.token);

  const currentUserOwnsWorkflow = useMemo(() => {
    if (
      !isNull(workflowMetadata) &&
      workflowMetadata?.email &&
      authUser?.email
    ) {
      return workflowMetadata.email === authUser.email;
    }

    return false;
  }, [workflowMetadata, authUser]);

  const enabledEmailProviders = useMemo(() => {
    const gmailEnabled = Boolean(user?.integrations.gmail?.enabled);
    const outlookEnabled = Boolean(user?.integrations.outlook?.enabled);

    return {
      [EmailProviderEnum.Gmail]: gmailEnabled,
      [EmailProviderEnum.Outlook]: outlookEnabled,
    };
  }, [user]);

  const handleChangeAction = (actionType: EmailActionEnum) => {
    const updatedData: WorkflowEmailNode['data'] = {
      ...emailNodeData,
      emailAction: actionType,
    };
    if (
      actionType === EmailActionEnum.SendEmail &&
      !node.data.sendEmailDetails
    ) {
      const recipientsId = createTemplateVariable(addVariable).id;
      const senderVariable = createTemplateVariable(addVariable);
      const senderId = senderVariable.id;
      const subjectId = createTemplateVariable(addVariable).id;
      const emailBodyId = createTemplateVariable(addVariable).id;
      const bccRecipientsId = createTemplateVariable(addVariable).id;
      const ccRecipientsId = createTemplateVariable(addVariable).id;
      const attachmentsId = createTemplateVariable(addVariable).id;

      const newSendEmailDetails = {
        recipients: { variableId: recipientsId },
        ccRecipients: { variableId: ccRecipientsId },
        bccRecipients: { variableId: bccRecipientsId },
        sender: { variableId: senderId },
        subject: { variableId: subjectId },
        emailBody: { variableId: emailBodyId },
        attachments: { variableId: attachmentsId },
        emailType: EmailTypeEnum.NewEmail,
      } as WorkflowEmailNode['data']['sendEmailDetails'];

      if (!isNull(workflowMetadata) && workflowMetadata?.email) {
        updateVariable({
          ...senderVariable,
          data: [workflowMetadata.email],
        });
      }

      updatedData.sendEmailDetails = newSendEmailDetails;
    } else if (
      actionType === EmailActionEnum.CreateContact &&
      !node.data.createContactDetails
    ) {
      const givenNameId = createTemplateVariable(addVariable).id;
      const lastNameId = createTemplateVariable(addVariable).id;
      const emailId = createTemplateVariable(addVariable).id;

      const newContactDetails = {
        givenName: { variableId: givenNameId },
        lastName: { variableId: lastNameId },
        email: { variableId: emailId },
      } as WorkflowEmailNode['data']['createContactDetails'];

      updatedData.createContactDetails = newContactDetails;
    }

    onUpdateData(updatedData);
  };

  const handleChangeEmailProvider = (newProvider: EmailProviderEnum) => {
    const updatedData: WorkflowEmailNode['data'] = {
      ...emailNodeData,
      emailProvider: newProvider,
    };

    onUpdateData(updatedData);
  };

  // Set the default email provider if a client has any integrations enabled
  useEffect(() => {
    // Check if any providers that are enabled exist
    const hasValidProvider = Object.values(enabledEmailProviders).some(
      (emailProviderEnabledStatus) => emailProviderEnabledStatus,
    );

    // Only set this if it has not been set already and a valid provider exists
    if (
      hasValidProvider &&
      !defaultProviderSet &&
      !emailNodeData.emailProvider
    ) {
      // Get the first provider that is enabled and extract its EmailProviderEnum value
      const defaultProvider = Object.entries(enabledEmailProviders).find(
        (providerEnabledStatus) => providerEnabledStatus[1],
      )?.[0];

      setDefaultProviderSet(true);
      if (defaultProvider) {
        onUpdateData({
          ...emailNodeData,
          emailProvider: defaultProvider as EmailProviderEnum,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledEmailProviders]);

  return (
    <div className="zoom-adjusted-container node-block absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col justify-between space-y-5">
      <div className="overflow-auto p-8">
        <div className="flex justify-between items-center">
          <span className="text-sm text-primary-blue font-medium">
            {node.name ?? 'Email step'}
          </span>
          <Button
            className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
            color="secondary"
            onClick={onCancel}
            variant="outlined"
          >
            <CloseIcon className="text-info" />
          </Button>
        </div>
        {isAdmin ? (
          <p className="text-zinc-500 text-xs leading-tight mb-0 -mt-2">
            Node Id: {node.id}
          </p>
        ) : null}
        <div className="my-6">
          <h2 className="text-cyan-900 text-lg font-medium leading-relaxed tracking-tight truncate">
            Email Integration
          </h2>
          <p className="text-zinc-500 text-sm leading-tight">
            Configure an email step to perform actions with your connected email
            accounts. Currently, Gmail and Outlook are supported. For other
            providers, please reach out for assistance.
          </p>
        </div>
        <div className="request-block flex-1 flex flex-col gap-4">
          <Input
            floatingLabel
            label="Step name"
            onChange={(val: string) => {
              updateNodeName(val);
            }}
            placeholder="Add a name"
            value={node.name ?? ''}
          />

          {!isAdmin && !currentUserOwnsWorkflow && !providersLoading ? (
            <Tooltip
              title="Only the workflow owner can select the email provider"
              placement="right"
            >
              <GrayedOutInput
                hideSpace
                isSelect
                className="mb-5 w-full"
                label="Email provider"
                value={
                  node.data.emailProvider
                    ? emailProviderLabels[node.data.emailProvider]
                    : 'Select the email provider app'
                }
              />
            </Tooltip>
          ) : (
            <Select
              classes={{ select: 'w-100' }}
              getLabel={(opt: string) =>
                emailProviderLabels[opt as EmailProviderEnum]
              }
              getValue={(opt: string) => opt}
              label="Email provider"
              placeholder="Select the email provider app"
              getIsDisabled={(opt) =>
                !enabledEmailProviders[opt as EmailProviderEnum]
              }
              onChange={(event: SelectChangeEvent) => {
                const newActionType = event.target.value as EmailProviderEnum;

                handleChangeEmailProvider(newActionType);
              }}
              options={emailProviders}
              value={emailNodeData.emailProvider}
            />
          )}

          {/* Disabling this for now until paragon issues are sorted out and users can select a provider */}
          {/* {node.data.emailProvider ? ( */}
          <Select
            classes={{ select: 'w-100' }}
            getLabel={
              (opt: string) =>
                // eslint-disable-next-line prefer-named-capture-group
                opt.replace(/([a-z])([A-Z])/g, '$1 $2')
              // Convert the value from the format "MoveMailToFolder" to "Move Mail To Folder" for readability
            }
            getValue={(opt: string) => opt}
            label="Action"
            placeholder="Select an email action"
            getIsDisabled={(opt) =>
              (opt as EmailActionEnum) !== EmailActionEnum.SendEmail
            }
            onChange={(event: SelectChangeEvent) => {
              const newActionType = event.target.value as EmailActionEnum;
              handleChangeAction(newActionType);
            }}
            options={emailActionOptions}
            value={emailNodeData.emailAction}
          />
          {/* ) : null} */}

          {emailAction === EmailActionEnum.SendEmail && sendEmailDetails ? (
            <SendEmailAction
              updateVariable={updateVariable}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              sendEmailAction={sendEmailDetails}
              updateSendEmailAction={(newDetails) => {
                onUpdateData({
                  ...emailNodeData,
                  sendEmailDetails: newDetails,
                });
              }}
              datasourceMetadata={props.datasourceMetadata}
              tableData={props.tableData}
            />
          ) : null}
          {emailAction === EmailActionEnum.CreateContact &&
          createContactDetails ? (
            <CreateContactAction
              updateVariable={updateVariable}
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
              createContactAction={createContactDetails}
              updateCreateContactAction={(newDetails) => {
                onUpdateData({
                  ...emailNodeData,
                  createContactDetails: newDetails,
                });
              }}
              datasourceMetadata={props.datasourceMetadata}
              tableData={props.tableData}
            />
          ) : null}
        </div>
      </div>

      <div className="flex flex-col space-y-7 px-8 pb-8">
        {!node.hideFromUser ? (
          <NodeCheck
            isChecked={node.data.nodeStatus === NodeStatusEnum.Checked}
            updateNodeStatus={updateNodeStatus}
          />
        ) : null}
        <Button
          color="secondary"
          fullWidth
          onClick={onCancel}
          variant="outlined"
        >
          Back to flow view
        </Button>
      </div>
    </div>
  );
}
