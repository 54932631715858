"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createInputStep = exports.parseInputStep = void 0;
const replay_1 = require("@puppeteer/replay");
const parseInputStep = (id, step, _idx) => {
    return Object.assign({ id }, step);
};
exports.parseInputStep = parseInputStep;
const createInputStep = ({ id, value, }) => {
    return {
        id,
        type: replay_1.StepType.Change,
        value,
        selectors: [[]],
    };
};
exports.createInputStep = createInputStep;
