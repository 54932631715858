"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardShortcutAction = exports.KeyboardShortcutGroup = exports.KeyboardShortcutGroupEnum = exports.WorkflowActionV2Base = exports.MouseAction = exports.MouseActionEnum = exports.WorkflowAction = exports.ActionValueCriteria = exports.ActionValueCriteriaEnum = exports.WorkflowActionsOptions = exports.ActionPlatform = exports.ActionPlatformEnum = void 0;
var zod_1 = require("zod");
var extensionTypes_1 = require("../extensionTypes");
var logic_schema_1 = require("../logicTypes/logic.schema");
var helper_types_1 = require("../helper-types");
var ActionPlatformEnum;
(function (ActionPlatformEnum) {
    ActionPlatformEnum["Web"] = "web";
    ActionPlatformEnum["Windows"] = "windows";
})(ActionPlatformEnum || (exports.ActionPlatformEnum = ActionPlatformEnum = {}));
exports.ActionPlatform = zod_1.z.nativeEnum(ActionPlatformEnum);
// MARK: Start Leagcy Action Schema
exports.WorkflowActionsOptions = zod_1.z.object({
    hidden: zod_1.z.boolean().optional(),
    adminOnly: zod_1.z.boolean().optional(),
    hitl: zod_1.z.boolean().optional(),
    sitl: zod_1.z.boolean().optional(),
    download: zod_1.z.array(extensionTypes_1.DownloadData).optional(),
    mfa: zod_1.z.boolean().optional(),
    captcha: zod_1.z.boolean().optional(),
    reCaptcha: zod_1.z.boolean().optional(),
    submitted: zod_1.z.boolean().optional(),
    adminManual: zod_1.z.boolean().optional(),
    adminSkipped: zod_1.z.boolean().optional(),
    terminal: zod_1.z.boolean().optional(),
    nonBlocking: zod_1.z.boolean().default(false).optional(),
});
var ActionValueCriteriaEnum;
(function (ActionValueCriteriaEnum) {
    ActionValueCriteriaEnum["Condition"] = "Condition";
    ActionValueCriteriaEnum["Variable"] = "Variable";
})(ActionValueCriteriaEnum || (exports.ActionValueCriteriaEnum = ActionValueCriteriaEnum = {}));
exports.ActionValueCriteria = zod_1.z.nativeEnum(ActionValueCriteriaEnum);
// WARN: Do not overload this schema when creating a new workflow action!
// Create a v2 action instead!
// See https://github.com/Sola-Solutions/monorepo/pull/3107
exports.WorkflowAction = zod_1.z
    .object({
    id: zod_1.z.string(),
    actionType: extensionTypes_1.Actions,
    targetId: zod_1.z.string().optional(),
    variableId: zod_1.z.string().optional(),
    title: zod_1.z.string().optional(),
    description: zod_1.z.string().optional(),
    options: exports.WorkflowActionsOptions.optional(),
    rules: zod_1.z.array(logic_schema_1.Rule).optional(),
    criteria: exports.ActionValueCriteria.optional(),
    keyType: extensionTypes_1.KeyTypes.optional(),
})
    .passthrough();
var MouseActionEnum;
(function (MouseActionEnum) {
    MouseActionEnum["Move"] = "Move";
    MouseActionEnum["Click"] = "Click";
    MouseActionEnum["DoubleClick"] = "DoubleClick";
    MouseActionEnum["RightClick"] = "RightClick";
    MouseActionEnum["LeftClickDrag"] = "LeftClickDrag";
})(MouseActionEnum || (exports.MouseActionEnum = MouseActionEnum = {}));
exports.MouseAction = zod_1.z.nativeEnum(MouseActionEnum);
// MARK: End Leagcy Action Schema
exports.WorkflowActionV2Base = zod_1.z
    .object({
    actionType: extensionTypes_1.Actions,
    actionVersion: zod_1.z.literal('2.0'),
})
    // XXX: Merge WorkflowAction for backwards compat
    // TODO(michael): Remove merge
    .merge(exports.WorkflowAction.omit({
    actionType: true,
}));
// See https://en.wikipedia.org/wiki/Table_of_keyboard_shortcuts
// for how we categorize keyboard shorcuts to sets;
// Appliction specific shortcuts will have its own set enum
var KeyboardShortcutGroupEnum;
(function (KeyboardShortcutGroupEnum) {
    KeyboardShortcutGroupEnum["General"] = "General";
    KeyboardShortcutGroupEnum["Excel"] = "Excel General";
    KeyboardShortcutGroupEnum["ExcelHomeTab"] = "Excel Home Tab";
    KeyboardShortcutGroupEnum["FileExplorer"] = "File Explorer";
})(KeyboardShortcutGroupEnum || (exports.KeyboardShortcutGroupEnum = KeyboardShortcutGroupEnum = {}));
exports.KeyboardShortcutGroup = zod_1.z.nativeEnum(KeyboardShortcutGroupEnum);
exports.KeyboardShortcutAction = exports.WorkflowActionV2Base.extend({
    actionType: zod_1.z.literal(extensionTypes_1.ActionsEnum.KeyboardShortcut),
    shortcutGroup: exports.KeyboardShortcutGroup,
    shortcutType: zod_1.z.string(),
    parameters: zod_1.z.object({
        keySequence: helper_types_1.KeySequence,
        platform: exports.ActionPlatform,
    }),
});
