"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasProperty = hasProperty;
exports.isObject = isObject;
exports.isString = isString;
exports.isNumber = isNumber;
exports.isArray = isArray;
exports.isBoolean = isBoolean;
exports.isIntegerArray = isIntegerArray;
function hasProperty(data, prop) {
    // TODO: use Object.hasOwn once types are available https://github.com/microsoft/TypeScript/issues/44253
    if (!Object.prototype.hasOwnProperty.call(data, prop)) {
        return false;
    }
    const keyedData = data;
    return keyedData[prop] !== undefined;
}
function isObject(data) {
    return typeof data === 'object' && data !== null;
}
function isString(data) {
    return typeof data === 'string';
}
function isNumber(data) {
    return typeof data === 'number';
}
function isArray(data) {
    return Array.isArray(data);
}
function isBoolean(data) {
    return typeof data === 'boolean';
}
function isIntegerArray(data) {
    return isArray(data) && data.every((item) => Number.isInteger(item));
}
