"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesktopKeyboardShortcutStepFactory = void 0;
const replay_1 = require("@puppeteer/replay");
const steps_1 = require("../../../types/steps");
const key_1 = require("../../../utils/key");
class DesktopKeyboardShortcutStepFactory {
    createStepFromKeySequence(id, type, keySequence) {
        return this._makeCustomStep(id, {
            type,
            keySequence,
        });
    }
    createStepFromPuppeteerSteps(id, type, steps) {
        const keySequence = steps.map((step) => {
            switch (step.type) {
                case replay_1.StepType.Change:
                    if ((0, key_1.isSpecialKey)(step.value) || (0, key_1.isTypableKey)(step.value)) {
                        return step.value;
                    }
                    throw new Error(`Invalid step value ${step.value} in shortcut sequence`);
                case replay_1.StepType.KeyDown:
                case replay_1.StepType.KeyUp:
                    // eslint-disable-next-line no-case-declarations -- Do not reuse tranlsatedKey outside of map
                    const tranlsatedKey = (0, key_1.translateToSolaSupportedPuppeteerKey)(step.key);
                    if ((0, key_1.isTypableKey)(tranlsatedKey) || (0, key_1.isSpecialKey)(tranlsatedKey)) {
                        return tranlsatedKey;
                    }
                    throw new Error(`Invalid key ${step.key} in shortcut sequence`);
                default:
                    throw new Error(`Unsupported step type in shortcut sequence: ${step.type}`);
            }
        });
        return this._makeCustomStep(id, {
            type,
            keySequence,
        });
    }
    _makeCustomStep(id, parameters) {
        return {
            id,
            type: replay_1.StepType.CustomStep,
            name: steps_1.DESKTOP_CUSTOM_STEP_TYPES.keyboardShortcut,
            parameters,
        };
    }
}
exports.DesktopKeyboardShortcutStepFactory = DesktopKeyboardShortcutStepFactory;
