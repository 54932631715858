import { useCallback, useState, useEffect } from 'react';
import { Modal, Button, notify, AlertVariant } from 'ui-kit';
import { useRetryExecution } from '../../../../../hooks';

export default function RerunModal({
  setOpen,
  open,
  executionId,
  setStopPolling,
}: {
  setOpen: (newOpen: boolean) => void;
  open: boolean;
  executionId: string;
  setStopPolling: (newPolling: boolean) => void;
}) {
  const [loading, setLoading] = useState(false);
  const { mutateAsync: retryExecution } = useRetryExecution({
    retryInPlace: true,
  });

  const handleSetOpen = useCallback(setOpen, [setOpen]);
  const handleSetStopPolling = useCallback(setStopPolling, [setStopPolling]);

  //   Transition workflow to retry status, then attempt to run again
  const handleRetryExecution = useCallback(async () => {
    try {
      setLoading(true);
      await retryExecution({ executionId }).then(() => {
        setLoading(false);
        handleSetOpen(false);

        notify({
          message: 'Re-running execution',
          variant: AlertVariant.SUCCESS,
        });
      });
      window.location.reload();
    } catch (e) {
      setLoading(false);
      handleSetOpen(false);
      notify({
        message:
          e instanceof Error
            ? e.message
            : 'Failed to re-run the execution. Please try again.',
        variant: AlertVariant.ERROR,
      });
    }
  }, [handleSetOpen, retryExecution, executionId]);

  useEffect(() => {
    if (open) {
      handleSetStopPolling(true);
    }
  }, [open, handleSetStopPolling]);

  return (
    <Modal
      className="flex item-center justify-items-center max-w-[45rem] min-w-[45rem]"
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      enableEventBubbling
    >
      <div className="text-sm flex">
        <div className="flex-1 flex flex-col">
          <h2 className="font-medium text-xl mb-7 text-black">
            Execution timed out waiting on human-in-the-loop action
          </h2>

          <p className="text-gray-600 mb-5">
            No worries! The bot is ready for your input. Simply re-run the
            workflow, and it should be ready in no time. You'll be redirected to
            the executions list while the workflow restarts from the beginning.
            Once it's ready, you can step in and handle the action manually if
            needed.
          </p>

          <div className="flex flex-row gap-7 mt-5">
            <Button
              loading={loading}
              color="secondary"
              onClick={handleRetryExecution}
              variant="contained"
            >
              RE-RUN WORKFLOW
            </Button>
            <Button
              disabled={loading}
              className="h-9"
              color="secondary"
              onClick={() => {
                setOpen(false);
              }}
              variant="outlined"
            >
              CANCEL
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
