"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.retryExecutionSchema = exports.RetryVersionEnum = void 0;
var types_shared_1 = require("types-shared");
var RetryVersionEnum;
(function (RetryVersionEnum) {
    RetryVersionEnum["Latest"] = "Latest";
    RetryVersionEnum["Existing"] = "Existing";
})(RetryVersionEnum || (exports.RetryVersionEnum = RetryVersionEnum = {}));
var RetryVersion = types_shared_1.z.nativeEnum(RetryVersionEnum);
exports.retryExecutionSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            executionId: types_shared_1.uuidSchema,
        }),
        body: types_shared_1.z.object({
            version: types_shared_1.z
                .union([RetryVersion, types_shared_1.z.string()])
                .optional()
                .default(RetryVersionEnum.Latest),
            retryInPlace: types_shared_1.z.boolean().default(false),
            adminRun: types_shared_1.z.boolean().optional(),
            asUser: types_shared_1.z.string().optional(),
        }),
    }),
    response: types_shared_1.z.object({
        executionId: types_shared_1.z.string(),
        workflowId: types_shared_1.z.string(),
    }),
};
