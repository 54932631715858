import type {
  SendSlackMessageRequestPayload,
  SendSlackMessageWithFileRequestPayload,
  ZodFetcher,
} from 'api-types-shared';
import {
  getExtensionSchema,
  getParagonUserTokenSchema,
  sendSlackMessageSchema,
  sendSlackMessageWithFileSchema,
} from 'api-types-shared';
import type { KyInstance, Options } from 'ky';
import { handleException } from 'sentry-browser-shared';
import { apiEndpoints, type NodeEnv } from 'ui-kit';

import { createZodKyFetcher } from '../fetcher';

export class MiscSDK {
  readonly endpoint: string;
  private _kyFetcher: ZodFetcher<KyInstance>;

  constructor(env: NodeEnv, kyOpts?: Options) {
    this.endpoint = apiEndpoints[env].miscApiV1;
    this._kyFetcher = createZodKyFetcher(kyOpts);
  }

  sendSlackMessage = async (req: SendSlackMessageRequestPayload) => {
    return this._kyFetcher(sendSlackMessageSchema.response, this.endpoint, {
      method: 'post',
      body: JSON.stringify(req),
    }).catch((err: unknown) => {
      handleException(err, {
        name: 'Slack message send failed',
        source: 'miscSDK.sendSlackMessage',
        extra: { req },
      });
      throw err;
    });
  };

  sendSlackMessageWithFile = async (
    req: SendSlackMessageWithFileRequestPayload,
  ) => {
    return this._kyFetcher(
      sendSlackMessageWithFileSchema.response,
      `${this.endpoint}/with-file`,
      {
        method: 'post',
        body: JSON.stringify(req),
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Slack message with file send failed',
        source: 'miscSDK.sendSlackMessageWithFile',
        extra: { req },
      });
      throw err;
    });
  };

  getParagonUserToken = async (userId?: string) => {
    return this._kyFetcher(
      getParagonUserTokenSchema.response,
      `${this.endpoint}/paragon-user-token${userId ? `?userId=${userId}` : ''}`,
      {
        method: 'get',
      },
    ).catch((err: unknown) => {
      handleException(err, {
        name: 'Paragon user token fetch failed',
        source: 'miscSDK.getParagonUserToken',
        extra: {},
      });
      throw err;
    });
  };

  getExtension = async () => {
    return this._kyFetcher(
      getExtensionSchema.response,
      `${this.endpoint}/extension`,
      {
        method: 'get',
      },
    );
  };
}
