import { type SvgIconProps } from '@mui/material/SvgIcon';
import { type GetExecutionResponse } from 'api-types-shared';
import isArray from 'lodash/isArray';
import { handleException } from 'sentry-browser-shared';
import { ActionsEnum } from 'types-shared';
import { type ExecutionVariables } from 'types-shared';
import { SourceTypeEnum } from 'types-shared/sourceTypes';
import {
  parseTemplateString,
  parseVariable,
  ParseVariableMode,
  QueryVariable,
  RequestIntegrationTypeEnum,
  type RequestPayloadType,
  type SourceVariable,
  TemplateData,
  type Variable,
  type VariableBase,
  type VariableMap,
  VariableTypeEnum,
  type WorkflowRequestNode,
  type WorkflowAction,
  type NodeTypesEnum,
  ScrapeVariable,
} from 'types-shared/workflowTypes';
import {
  type OutputItem,
  OutputItemActions,
} from './components/OutputsTable/Description';
import { v4 as uuid } from 'uuid';
import { type ReactNode } from 'react';
import {
  isEmailVariable,
  isDerivedFromDocumentVariable,
} from '../Editor/utils/helper';
import isNil from 'lodash/isNil';
import { isNull } from 'lodash';

export const extractTransformedValue = (variable: VariableBase): string => {
  const val =
    variable.executionData?.transformedValue ??
    variable.executionData?.initialValue ??
    {};

  if (typeof val === 'string') {
    return val;
  }

  if ('data' in val) {
    if (isArray(val.data)) {
      return val.data.join(', ');
    }
    return val.data as string;
  }

  const stringified = JSON.stringify(val);

  if (stringified === '{}') {
    return '';
  }

  return stringified;
};

export const extractToEmail = (variables: ExecutionVariables): string => {
  const outputVariables = extractOutputsData(variables);
  const toVariable = outputVariables.find(({ title }) => title === 'To');

  if (!toVariable) return '';

  return toVariable.description;
};

export const extractOutputsData = (
  variables: ExecutionVariables,
): { id: string; title: string; description: string }[] => {
  return Object.entries(variables)
    .map(([key, value]) => {
      const isStringValue = typeof value === 'string';
      const executionVariable = value as VariableBase;
      const title = isStringValue ? key : executionVariable.name;

      const description = isStringValue
        ? value
        : extractTransformedValue(executionVariable);

      return { id: executionVariable.id, title: title ?? '', description };
    })
    .filter(({ id, title, description }) => id && title && description);
};

export const extractSourceOutputsData = (
  variablesMap: ExecutionVariables,
): {
  id: string;
  title: string;
  description: string;
  scrapeText?: string;
}[] => {
  const variables = Object.entries(variablesMap);
  return variables
    .map(([key, value]) => {
      const isStringValue = typeof value === 'string';
      const executionVariable = value as VariableBase;
      const title = isStringValue ? key : executionVariable.name;

      const description = isStringValue
        ? value
        : extractTransformedValue(executionVariable);

      const output = {
        id: executionVariable.id,
        title: title ?? '',
        description,
        scrapeText: '',
      };

      const queryVariables = variables.filter(([, variable]) => {
        if ((variable as Variable).type !== VariableTypeEnum.Query) {
          return;
        }
        const { data } = variable as QueryVariable;
        // TODO: add better handling for attachment variables
        return data.sourceIds[0] === executionVariable.id;
      });

      if (queryVariables.length > 0) {
        const scrapedStrings = queryVariables
          .map((item) => {
            const variable = item[1] as QueryVariable;
            const scrapeText = extractTransformedValue(variable);
            return scrapeText
              ? `${variable.name ? `${variable.name}: ` : ''}${scrapeText}`
              : '';
          })
          .filter((v) => v);
        return {
          ...output,
          scrapeText:
            scrapedStrings.length > 0 ? scrapedStrings.join('<br />') : '',
        };
      }

      return output;
    })
    .filter(
      ({ id, title, description, scrapeText }) =>
        id && title && (description || scrapeText),
    );
};

export function extractFilename(path: string): string {
  const parts = path.split('/');
  return parts[parts.length - 1];
}

export const getRequestNodeBody = (
  body: RequestPayloadType[] | { variableId: string },
  executionVariables: VariableMap,
): string => {
  let str;
  if (
    typeof body === 'object' &&
    !Array.isArray(body) &&
    TemplateData.safeParse(executionVariables[body.variableId].data).success
  ) {
    str = parseTemplateString({
      data: executionVariables[body.variableId].data as TemplateData,
      variableMap: executionVariables,
      mode: ParseVariableMode.Execution,
      handleException,
    }) as string;
  } else {
    str = (body as RequestPayloadType[])
      .map(({ key, value }: RequestPayloadType) => {
        const keyStr = parseVariable({
          variable: executionVariables[key.variableId],
          variableMap: executionVariables,
          mode: ParseVariableMode.Execution,
          handleException,
        }) as string;
        const valueStr = parseVariable({
          variable: executionVariables[value.variableId],
          variableMap: executionVariables,
          mode: ParseVariableMode.Execution,
          handleException,
        }) as string;
        return `Key: ${keyStr}<br />Value: ${valueStr}`;
      })
      .join(`<br /><br />`);
  }

  return `<span class="text-cyan-900 text-sm font-medium">Content</span><br />${str}`;
};

export const getRequestNodeTitle = (node: WorkflowRequestNode) => {
  return node.data.integrationType === RequestIntegrationTypeEnum.Salesforce
    ? 'Salesforce Integration'
    : 'HTTP Request';
};

export const getTriggerBlockShortTitle = (variable: SourceVariable) => {
  const isEmail = variable.data.sourceType === SourceTypeEnum.EmailTrigger;

  return isEmail ? 'Email trigger' : undefined;
};

export const getActionTitle = ({
  options,
  actionType,
  title = '',
}: WorkflowAction): string => {
  const { download, captcha, mfa, reCaptcha, terminal } = options ?? {};
  if (download ?? actionType === ActionsEnum.Download) {
    return 'Download';
  }

  if (actionType === ActionsEnum.MultiChoice) {
    return 'Multiple Choice';
  }

  if (actionType === ActionsEnum.KeyPress) {
    return 'Press';
  }

  if (actionType === ActionsEnum.KeyUnpress) {
    return 'Release';
  }

  if (actionType === ActionsEnum.RightClick) {
    return 'Right Click';
  }

  if (actionType === ActionsEnum.PickFromList) {
    return 'Pick from list';
  }

  if (actionType === ActionsEnum.Arbitrary) {
    return title;
  }

  if (actionType === ActionsEnum.SwitchTab) {
    return 'Switch Tab';
  }

  if (actionType === ActionsEnum.NewTab) {
    return 'New Tab';
  }

  if (actionType === ActionsEnum.MultiSelect) {
    return 'Multi Select';
  }

  if (actionType === ActionsEnum.MagicLoop) {
    return `${terminal ? 'End' : 'Begin'} Magic Loop`;
  }

  if (actionType === ActionsEnum.Refresh) {
    return 'Refresh';
  }

  if (mfa) {
    return 'Two-factor authentication';
  }

  if (reCaptcha) {
    return 'ReCaptcha';
  }

  if (captcha) {
    return 'Captcha';
  }

  return actionType;
};

export const getMetadataFromImageUrl = (
  url: string,
): {
  timestamp: string;
  retryCount: string;
  nodeId?: string;
  stepId?: string;
} => {
  const [rawTimestamp, retryCount, nodeId, stepId] = url.split('_');
  const timestamp = decodeURIComponent(rawTimestamp);

  // The nodeId doesn't exist (or make sense to include) if we submit a manual screenshot signal, so we handle that case here
  return {
    timestamp,
    retryCount,
    nodeId: nodeId ? nodeId.replace('.png', '') : undefined,
    stepId: stepId ? stepId.replace('.png', '') : undefined,
  };
};

export interface ScreenshotUrl {
  type: string;
  nodeType: NodeTypesEnum;
  src: string | JSX.Element | ((props: SvgIconProps) => JSX.Element);
  sortData: {
    timestamp?: string;
    nodeId?: string;
    stepId?: string;
    name?: string;
    isRetryNode?: boolean;
  };
}

export interface EditorToolbarMenuItem {
  label: string;
  value: SourceTypeEnum;
  isCurrent: boolean;
  icon: ReactNode;
  disabled?: boolean;
  tooltipText?: string;
}
export interface ScreenshotForDelete {
  id: string;
  isImage: boolean;
  item?: ScreenshotUrl;
}

const getFileName = (filePath: string) => {
  const parts = filePath.split('/');
  return parts[parts.length - 1];
};

const emailTitle = ['From', 'To'];

export const getOutputItemsFromExecutionArtifacts = (
  {
    variableData = {},
    artifacts,
  }: {
    variableData: ExecutionVariables;
    artifacts: GetExecutionResponse['artifacts'];
  },
  onDownloadLinkData: (url: string) => void,
): OutputItem[] => {
  const variableArr = Object.values(variableData).filter(
    (v): v is Variable => typeof v !== 'string',
  );
  const variableMap: Record<string, Variable> = variableArr.reduce<
    Record<string, Variable>
  >((acc, v) => {
    acc[v.id] = v;
    return acc;
  }, {});
  const variablesToReturn = Object.entries(variableData)
    .filter(([, variable]) => {
      const isScrape = ScrapeVariable.safeParse(variable).success;
      const derivedFromEmail =
        typeof variable !== 'string' && isEmailVariable(variable, variableMap);

      const derivedFromDocument =
        typeof variable !== 'string' &&
        isDerivedFromDocumentVariable(variable, variableMap);
      return derivedFromEmail || isScrape || derivedFromDocument;
    })
    .map(([key, value]) => {
      const isStringValue = typeof value === 'string';
      const executionVariable = value as VariableBase;
      const title = isStringValue ? key : executionVariable.name;
      const executionData = (executionVariable.executionData
        ?.transformedValue ??
        executionVariable.executionData?.initialValue) as Record<
        string,
        string
      >;
      const isEmailAddressVariable = emailTitle.includes(title ?? '');
      let description = isStringValue ? value : executionData;
      if (isEmailAddressVariable && !isNil(executionData)) {
        const emailStr = Object.keys(executionData)
          .map(
            (email) =>
              `${email}${executionData[email] ? ` (${executionData[email]})` : ''}`,
          )
          .join('');
        description = emailStr;
      }

      const action: OutputItemActions = OutputItemActions.COPY;

      if (!title || !description) {
        return null;
      }

      const derivedFromEmail =
        !isStringValue && isEmailVariable(value, variableMap);
      const derivedFromDocument =
        !isStringValue && isDerivedFromDocumentVariable(value, variableMap);

      let type = 'Scrape';
      if (derivedFromEmail) {
        type = 'Email variable';
      } else if (derivedFromDocument) {
        type = 'Document output';
      }

      const outputItem: OutputItem = {
        id: uuid(),
        action,
        description,
        onDownloadLinkData,
        title,
        type,
      };

      return outputItem;
    })
    .filter((item): item is OutputItem => item !== null);

  const downloads = artifacts.map((doc) => {
    const { s3Key, uri, mediaType } = doc;

    if (s3Key.includes('DEMO')) {
      const withoutPrefix = s3Key.replace('DEMO_', '');
      const firstUnderscoreIndex = withoutPrefix.indexOf('_');
      const name = decodeURIComponent(
        withoutPrefix.slice(0, firstUnderscoreIndex),
      );
      const value = decodeURIComponent(
        withoutPrefix.slice(firstUnderscoreIndex + 1),
      );
      return {
        id: uuid(),
        action:
          mediaType === 'application/octet-stream'
            ? OutputItemActions.DOWNLOAD
            : OutputItemActions.COPY,
        description: value || getFileName(s3Key) || s3Key,
        onDownloadLinkData,
        title: name || s3Key,
        uri,
        type: 'Document',
      } as OutputItem;
    }

    return {
      id: uuid(),
      action:
        mediaType === 'application/octet-stream'
          ? OutputItemActions.DOWNLOAD
          : OutputItemActions.COPY,
      description: getFileName(s3Key) || s3Key,
      onDownloadLinkData,
      title: s3Key,
      uri,
      type: 'Document',
    } as OutputItem;
  });

  return [...variablesToReturn, ...downloads];
};

export const getInputItemsFromExecutionArtifacts = (
  {
    executionVariables = {},
    artifacts,
    variableMetadata,
  }: {
    executionVariables?: ExecutionVariables;
    artifacts: GetExecutionResponse['artifacts'];
    variableMetadata?: Record<string, string>;
  },
  onDownloadLinkData: (url: string) => void,
): OutputItem[] => {
  const variableArr = Object.values(executionVariables).filter(
    (v): v is Variable => typeof v !== 'string',
  );
  const variableMap: Record<string, Variable> = variableArr.reduce<
    Record<string, Variable>
  >((acc, v) => {
    acc[v.id] = v;
    return acc;
  }, {});
  const queryVariables = variableArr.map((variable) => {
    const check = QueryVariable.safeParse(variable);

    const derivedFromEmail = isEmailVariable(variable, variableMap);
    const derivedFromDocument = isDerivedFromDocumentVariable(
      variable,
      variableMap,
    );

    if (check.success && !derivedFromEmail && !derivedFromDocument) {
      return check.data;
    }
    return null;
  });

  const outputItemsToReturn = queryVariables.map((variable) => {
    if (variable) {
      const title = variable.name;
      let altDesc = '';

      if (title && variableMetadata) {
        altDesc = variableMetadata[title];
      }

      // Need this to evaluate all
      const description =
        variable.executionData?.transformedValue ||
        variable.executionData?.initialValue ||
        variable.dashboardData?.initialValue ||
        altDesc;

      const action: OutputItemActions = OutputItemActions.COPY;

      if (!title) {
        return null;
      }

      const outputItem: OutputItem = {
        id: uuid(),
        action,
        description,
        onDownloadLinkData,
        title,
        type: variable.data.valueType,
      };

      return outputItem;
    }
    return null;
  });

  const downloads = artifacts.map((doc) => {
    const { s3Key, uri, mediaType } = doc;

    return {
      id: uuid(),
      action:
        mediaType === 'application/octet-stream'
          ? OutputItemActions.DOWNLOAD
          : OutputItemActions.COPY,
      description: getFileName(s3Key) || s3Key,
      onDownloadLinkData,
      title: s3Key,
      uri,
      type: 'Document',
    } as OutputItem;
  });

  return [
    ...outputItemsToReturn.filter((item): item is OutputItem => item !== null),
    ...downloads,
  ];
};

export const parseEmailStepQueryVariable = ({
  executionVariables = {},
  variableMetadata,
  variableId,
}: {
  executionVariables?: ExecutionVariables;
  variableId: string;
  variableMetadata?: Record<string, string>;
}) => {
  const variableArr = Object.values(executionVariables).filter(
    (v): v is Variable => typeof v !== 'string',
  );
  const variableMap: Record<string, Variable> = variableArr.reduce<
    Record<string, Variable>
  >((acc, v) => {
    acc[v.id] = v;
    return acc;
  }, {});

  const queryVariables = variableArr.map((variable) => {
    const check = QueryVariable.safeParse(variable);

    const derivedFromEmail = isEmailVariable(variable, variableMap);
    const derivedFromDocument = isDerivedFromDocumentVariable(
      variable,
      variableMap,
    );

    if (check.success && !derivedFromEmail && !derivedFromDocument) {
      return check.data;
    }
    return null;
  });

  const variable = queryVariables.find(
    (v) => !isNull(v) && v.id === variableId,
  );

  if (variable) {
    const title = variable.name;
    let altDesc = '';

    if (title && variableMetadata) {
      altDesc = variableMetadata[title];
    }

    // Need this to evaluate all
    const description =
      variable.executionData?.transformedValue ||
      variable.executionData?.initialValue ||
      variable.dashboardData?.initialValue ||
      altDesc;

    return description as string;
  }

  return '';
};

export const getEmailStepDownload = (
  {
    artifacts,
    variableId,
  }: {
    artifacts: GetExecutionResponse['artifacts'];
    variableId: string;
  },
  onDownloadLinkData: (url: string) => void,
): OutputItem | null => {
  const matchingDoc = artifacts.find(
    (art) => art.variableId && art.variableId === variableId,
  );

  if (matchingDoc) {
    const { s3Key, uri, mediaType } = matchingDoc;

    return {
      id: uuid(),
      action:
        mediaType === 'application/octet-stream'
          ? OutputItemActions.DOWNLOAD
          : OutputItemActions.COPY,
      description: getFileName(s3Key) || s3Key,
      onDownloadLinkData,
      title: s3Key,
      uri,
      type: 'Document',
    } as OutputItem;
  }

  return null;
};

const parseEmailName = (data: string): string => {
  // Match the format {email:name}, removing any extra quotes or escaping
  // eslint-disable-next-line prefer-named-capture-group
  const regex = /{([^:]+):([^}]+)}/;

  // Remove extra surrounding quotes iteratively
  let sanitizedData = data.trim();
  while (sanitizedData.startsWith('"') && sanitizedData.endsWith('"')) {
    sanitizedData = sanitizedData.slice(1, -1);
  }

  // Apply the regex to extract email and name
  const match = regex.exec(sanitizedData);

  if (match) {
    const [, email, name] = match;
    return `${email.trim()} (${name.trim()})`;
  }

  return '';
};

const isNestedString = (data: string): boolean => {
  // Regex to check if the string is surrounded by multiple quotes

  const nestedRegex = /^"+.*"+$/;
  return nestedRegex.test(data.trim());
};

export const processEmailSender = (data: string) => {
  const dataIsNested = isNestedString(data);

  if (dataIsNested) {
    return parseEmailName(data);
  }

  const asObject = JSON.parse(data) as Record<string, string>;
  const emails = Object.keys(asObject);
  const names = Object.values(asObject);

  const result = emails
    .map(
      (email, index) =>
        `${email}${names[index] && names[index] !== email ? ` (${names[index]})` : ''}`,
    )
    .join(' ');
  return result;
};

export const isJson = (value: string) => {
  try {
    const result: unknown = JSON.parse(value);
    return typeof result === 'object' && result !== null;
  } catch (error) {
    return false;
  }
};

export const getPathFromUri = (uri: string, executionId: string) => {
  const parts = uri.split(executionId);
  return decodeURIComponent(
    `${executionId}${parts[1]}`.split('?X-Amz-Algorithm')[0],
  );
};
