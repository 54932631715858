import { SignalTypeEnum } from 'api-types-shared';
import React from 'react';
import {
  type Variable,
  QueryVariable,
  ScrapeVariable,
  DocumentVariable,
} from 'types-shared';
import { Button, Input } from 'ui-kit';
import { type GetRefProps } from '../../../Editor/hooks';
import { Select } from '../Select';

const defaultSignalForm = {
  variable: undefined,
  value: '',
};

interface UpdateVariableProps {
  setSignalForm: React.Dispatch<
    React.SetStateAction<{
      variable: Variable | undefined;
      value: string;
    }>
  >;
  signalForm: {
    variable: Variable | undefined;
    value: string;
  };
  globalVariablesMap: Record<string, Variable>;
  onSignalClick: (signalType: SignalTypeEnum, payload?: object) => () => void;
  isSignalLoading: boolean;
  refData?: GetRefProps;
}
export default function UpdateVariable({
  setSignalForm,
  signalForm,
  globalVariablesMap,
  onSignalClick,
  isSignalLoading,
  refData,
}: UpdateVariableProps) {
  return (
    <div className="flex justify-end items-stretch space-x-2">
      <div className="flex items-center pr-4">
        <span className="font-medium">Update variable</span>
      </div>
      <Select
        allowAddVariable={false}
        className="!min-w-48 !mt-2"
        data={signalForm.variable ? [signalForm.variable] : []}
        filterVariables={false}
        label="Variable"
        onChange={(data) => {
          setSignalForm((form) => ({
            ...form,
            variable: data[0] as Variable,
          }));
        }}
        options={[]}
        globalVariablesMap={globalVariablesMap}
        variablesMap={Object.fromEntries(
          Object.entries(refData?.variableData ?? {}).filter(
            ([, variable]) =>
              QueryVariable.safeParse(variable).success ||
              ScrapeVariable.safeParse(variable).success ||
              DocumentVariable.safeParse(variable).success,
          ),
        )}
      />
      <Input
        label="Value"
        floatingLabel
        value={signalForm.value}
        onChange={(value: string) => {
          setSignalForm((form) => ({
            ...form,
            value,
          }));
        }}
      />
      <Button
        className="!mt-2"
        color="secondary"
        disabled={!signalForm.variable || !signalForm.value || isSignalLoading}
        onClick={() => {
          onSignalClick(SignalTypeEnum.SetVariable, {
            variableId: signalForm.variable?.id,
            value: signalForm.value,
          })();
          setSignalForm(defaultSignalForm);
        }}
        variant="contained"
      >
        Submit
      </Button>
    </div>
  );
}
