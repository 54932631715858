"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardShortcutParameters = exports.KeyboardShortcutType = void 0;
const types_shared_1 = require("types-shared");
const keyboard_shortcuts_1 = require("../../legacy/actions/keyboard-shortcuts");
const excel_1 = require("../../legacy/actions/keyboard-shortcuts/groups/excel");
const file_explorer_1 = require("../../legacy/actions/keyboard-shortcuts/groups/file-explorer");
exports.KeyboardShortcutType = types_shared_1.z.union([
    types_shared_1.z.nativeEnum(keyboard_shortcuts_1.KeyboardShortcuts),
    types_shared_1.z.nativeEnum(excel_1.ExcelShortcuts),
    types_shared_1.z.nativeEnum(excel_1.ExcelHomeTabShortcuts),
    types_shared_1.z.nativeEnum(file_explorer_1.FileExplorerShortcuts),
]);
exports.KeyboardShortcutParameters = types_shared_1.z.object({
    type: exports.KeyboardShortcutType,
    keySequence: types_shared_1.KeySequence,
});
