import {
  type GlobalVariable,
  type VariableMap,
  type TemplateData,
  type Variable,
} from 'types-shared';

export interface VariableIdContainer {
  variableId: string;
}
export interface ExtendedVariable {
  value?: string;
}

export interface VariableInputProps {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  containerClassName?: string;

  value?: TemplateData;
  onChange?: (val: TemplateData) => void;

  variablesMap: Record<string, Variable>;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  allowAddVariable?: boolean;
  showPlusButton?: boolean;
  onClickAddNew?: (indexForVariableInsert?: number) => void;
  onClickVariableChip?: (variableId: string) => void;
  multiline?: boolean; // true by default
  willRerender?: boolean;
  hideVariableMenuButton?: boolean;
  disabledAddVariableTooltip?: string;
  allowedMenuTabs?: VariableInputMenuTabsEnum[];
}

export interface VariableInputRef {
  addVariable: (variable: Variable) => void;
}

export enum VariableInputMenuTabsEnum {
  Input = 'Input',
  Scrapes = 'Scrapes',
  Shared = 'Shared',
  Execution = 'Execution',
  Documents = 'Documents',
  Email = 'Email',
}
