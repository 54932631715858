import { clsx } from 'clsx';
import { type ComponentType, type ReactNode, useMemo } from 'react';
import { PlayCircleOutlineIcon, Checkbox } from 'ui-kit';
import { isAdmin } from '../../../utils/env';
import { type ScreenshotUrl, type ScreenshotForDelete } from '../utils';

enum ExecutionScreenshotTypeEnum {
  Blob = 'blob',
  Video = 'video',
  Icon = 'icon',
}

interface ExecutionScreenshotThumbnailProps {
  onClick: () => void;
  _selectedIndex: number;
  index: number;
  screenshotUrlsLength: number;
  screenshot: ScreenshotUrl;
  onAddScreenshotForDelete: (newItem: ScreenshotForDelete) => void;
  screenshotsForDelete: ScreenshotForDelete[];
}

export default function ExecutionScreenshotThumbnail({
  onClick,
  _selectedIndex,
  index,
  screenshotUrlsLength,
  screenshot,
  onAddScreenshotForDelete,
  screenshotsForDelete,
}: ExecutionScreenshotThumbnailProps) {
  const {
    type: _type,
    src: Src,
    sortData: { isRetryNode },
  } = screenshot;

  const sortDataName = useMemo(
    () => screenshot.sortData.name ?? screenshot.sortData.nodeId,
    [screenshot],
  );

  const isChecked = useMemo(
    () => Boolean(screenshotsForDelete.find((a) => a.id === sortDataName)),
    [screenshotsForDelete, sortDataName],
  );

  const onSelect = () => {
    if (!sortDataName) return;
    const payload: ScreenshotForDelete = {
      id: sortDataName,
      isImage: _type === 'blob',
      item: screenshot,
    };
    onAddScreenshotForDelete(payload);
  };

  return (
    <div
      className={clsx(
        'cursor-pointer last:mr-auto rounded-lg flex items-center relative',
        {
          'border-2 border-primary-purple-200 hover:border-primary-blue-dark hover:border-2 ease-in-out transition-all':
            _selectedIndex !== index,
          '!border-4 !border-blue-500 border-opacity-60':
            _selectedIndex === index,
          'mt-5': isAdmin,
        },
      )}
      // TODO: doing this because of time sensitivity, use timestamp in future

      onClick={onClick}
      role="presentation"
    >
      {isAdmin ? (
        <div className="absolute top-[-31px] left-1/2 -translate-x-1/2">
          <Checkbox
            checked={isChecked}
            color="secondary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              onSelect();
            }}
          />
        </div>
      ) : null}

      {(() => {
        switch (_type as ExecutionScreenshotTypeEnum) {
          case ExecutionScreenshotTypeEnum.Blob:
            return (
              <img
                alt={`Execution detail ${(screenshotUrlsLength - index).toString()}`}
                className="object-contain rounded-md w-12 min-w-12 h-8"
                loading="lazy"
                src={Src as string}
              />
            );
          case ExecutionScreenshotTypeEnum.Video:
            return (
              <div className="bg-white w-12 h-8 rounded-md flex items-center justify-center">
                <PlayCircleOutlineIcon className="!w-7" />
              </div>
            );
          case ExecutionScreenshotTypeEnum.Icon:
            // eslint-disable-next-line no-case-declarations
            const Icon = Src as ComponentType<{
              className?: string;
            }>;
            return (
              <div className="!w-12 !min-w-12 !h-8 rounded-md !py-1 flex items-center justify-center">
                <Icon className={isRetryNode ? '!w-4' : '!w-7'} />
              </div>
            );
          default:
            return (
              <div className="!w-12 !min-w-12 !h-8 rounded-md !py-1 [&>img]:w-6 bg-white flex items-center justify-center">
                {Src as ReactNode}
              </div>
            );
        }
      })()}
    </div>
  );
}
