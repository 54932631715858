import { useMemo } from 'react';
import { isAdmin } from '../../../utils/env';
import { ExecutionTriggerEnum } from 'types-shared';
import dayjs from '../../../config/dayjs';
import { type GetExecutionResponse } from 'api-types-shared';
import { Flex, CustomTypography } from 'ui-kit';
import {
  getStatusColor,
  titleCase,
  useFeatureFlag,
} from '../../../utils/helper';
import {
  executionStatusTitleMapping,
  FeatureFlag,
  triggerTitleMapping,
} from '../../../utils/constants';
import { useGetTemporalUiAddress, useUpdateExecution } from '../hooks';
import ExecutionChip from '../../Workflows/components/Chips/ExecutionChip';
import { capitalizeFirstLetter } from '../../Workflows/utils/helper';
import { ExecutionStatusDropdown } from '../../Workflows/components/ExecutionStatusDropdown';
import { Skeleton } from '@mui/material';
import { ExecutionAdminRun } from '../../Workflows/components/ExecutionAdminRun';

function HeaderValueSkeleton() {
  return <Skeleton variant="rounded" height={40} width={90} />;
}

// TODO: Should we be adding a case for ExecutionTriggerEnum.Webhook here?
const getTitle = ({ metadata }: GetExecutionResponse) => {
  const { trigger, createdAt } = metadata;
  const executionDate = dayjs(new Date(createdAt)).format('MM/DD/YY -  h:mm A');
  switch (trigger) {
    case ExecutionTriggerEnum.API:
      return `API Execution: ${executionDate}`;
    case ExecutionTriggerEnum.Email:
      return `Email: ${executionDate}`;
    case ExecutionTriggerEnum.Scheduled:
      return `Scheduled Execution: ${executionDate}`;
    default:
      return `Manual Execution: ${executionDate}`;
  }
};

export default function ExecutionDetailHeader({
  executionDetail,
  executionId,
  executionDetailLoading,
}: {
  executionDetail?: GetExecutionResponse;
  executionId: string;
  executionDetailLoading: boolean;
}) {
  const { temporalBaseUrl, loading } = useGetTemporalUiAddress(
    executionDetail?.metadata.userId ?? '',
  );
  const { status: executionUpdateStatus, mutate: updateExecution } =
    useUpdateExecution();
  const executionsLastUpdatedAtEnabled = useFeatureFlag(
    FeatureFlag.ExecutionsLastUpdatedAt,
  );

  const { statusTitle, executionDate, triggerType, executionLastUpdatedAt } =
    useMemo(() => {
      const summaryData = {
        statusTitle: '',
        executionDate: '',
        executionLastUpdatedAt: '',
        triggerType: '' as ExecutionTriggerEnum,
      };

      if (!executionDetail?.metadata) return summaryData;
      const {
        trigger,
        status: _status,
        createdAt,
        lastUpdatedAt,
      } = executionDetail.metadata;

      const formattedStatus = _status.toLowerCase().replace('_', ' ');
      summaryData.statusTitle = isAdmin
        ? formattedStatus
        : executionStatusTitleMapping[_status] ?? formattedStatus;
      summaryData.executionDate = dayjs(new Date(createdAt)).format(
        'MM/DD/YY -  h:mm A',
      );
      if (lastUpdatedAt) {
        summaryData.executionLastUpdatedAt = dayjs(
          new Date(lastUpdatedAt),
        ).format('MM/DD/YY -  h:mm A');
      }

      if (summaryData.statusTitle === 'retry') {
        summaryData.statusTitle = 'Waiting to retry';
      }
      summaryData.triggerType = trigger;
      return summaryData;
    }, [executionDetail]);

  return (
    <>
      <div className="flex items-center space-x-6 !mb-4">
        {executionDetailLoading || !executionDetail ? (
          <Skeleton variant="text" width={600} height={70} />
        ) : (
          <CustomTypography className="!font-bold text-info-dark" variant="h4">
            {getTitle(executionDetail)}
          </CustomTypography>
        )}
        {isAdmin ? (
          <>
            {(() => {
              if (loading) {
                return <span>Loading...</span>;
              } else if (!temporalBaseUrl) {
                return (
                  <span className="text-red-500">
                    Error: Temporal URL not available.
                  </span>
                );
              }

              return (
                <a
                  className="px-2 py-1 border border-gray-800 rounded"
                  target="_blank"
                  rel="noreferrer"
                  href={`${temporalBaseUrl}/${executionId}`}
                >
                  View on temporal
                </a>
              );
            })()}
          </>
        ) : null}
      </div>

      <Flex
        className="gap-14 rounded-lg border border-indigo-light px-8 pb-10 pt-9 mb-8"
        flexDirection="row"
      >
        <div>
          <p className="text-color-grey text-base mb-1.5">
            Execution Queued At
          </p>
          {executionDetailLoading ? (
            <HeaderValueSkeleton />
          ) : (
            <p className="text-info-dark text-base">{executionDate}</p>
          )}
        </div>
        {executionsLastUpdatedAtEnabled ? (
          <div>
            <p className="text-color-grey text-base mb-1.5">Last Updated At</p>
            {executionDetailLoading ? (
              <HeaderValueSkeleton />
            ) : (
              <p className="text-info-dark text-base">
                {executionLastUpdatedAt}
              </p>
            )}
          </div>
        ) : null}
        <div>
          <p className="text-color-grey text-base mb-1.5">Trigger</p>
          {executionDetailLoading ? (
            <HeaderValueSkeleton />
          ) : (
            <p className="text-info-dark text-base capitalize">
              {triggerTitleMapping[triggerType] ?? titleCase(triggerType)}
            </p>
          )}
        </div>
        <div>
          <p className="text-color-grey text-base mb-1.5">Attempts</p>
          {executionDetailLoading || !executionDetail?.metadata.attempt ? (
            <HeaderValueSkeleton />
          ) : (
            <p className="text-info-dark text-base capitalize">
              {executionDetail.metadata.attempt}
            </p>
          )}
        </div>
        <div>
          <p className="text-color-grey text-base mb-1.5">Status</p>

          {executionDetailLoading || !executionDetail?.metadata.status ? (
            <HeaderValueSkeleton />
          ) : (
            <ExecutionChip
              className="!h-6"
              color={getStatusColor(executionDetail.metadata.status)}
              label={
                <span className="normal-case">
                  {capitalizeFirstLetter(statusTitle)}
                </span>
              }
              status={executionDetail.metadata.status}
              sx={{
                '& .MuiChip-label': { textTransform: 'capitalize' },
              }}
              variant="filled"
            />
          )}
        </div>
        {isAdmin ? (
          <>
            <div>
              <p className="text-color-grey text-base mb-1.5">Update status</p>

              {executionDetailLoading || !executionDetail?.metadata.status ? (
                <HeaderValueSkeleton />
              ) : (
                <ExecutionStatusDropdown
                  isSmall
                  executionUpdateStatus={executionUpdateStatus}
                  id={executionId}
                  status={executionDetail.metadata.status}
                  updateExecutionStatus={updateExecution}
                />
              )}
            </div>
            <div>
              <p className="text-color-grey text-base mb-1.5">Admin run</p>

              {executionDetailLoading ? (
                <HeaderValueSkeleton />
              ) : (
                <ExecutionAdminRun
                  checked={Boolean(executionDetail?.metadata.adminRun)}
                  id={executionId}
                  updateAdminRun={updateExecution}
                  adminRunStatus={executionUpdateStatus}
                />
              )}
            </div>
          </>
        ) : null}
      </Flex>
    </>
  );
}
