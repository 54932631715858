"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseUserFlow = void 0;
const json_1 = require("../serde/json");
const checks_1 = require("../types/checks");
const parseUserFlow = (data) => {
    if (!(0, checks_1.isObject)(data)) {
        throw new Error('Recording is not an object');
    }
    if (!(0, checks_1.hasProperty)(data, 'title')) {
        throw new Error('Recording is missing `title`');
    }
    if (!(0, checks_1.isString)(data.title)) {
        throw new Error('Recording `title` is not a string');
    }
    if ((0, checks_1.hasProperty)(data, 'timeout') && !(0, checks_1.isNumber)(data.timeout)) {
        throw new Error('Recording `timeout` is not a number');
    }
    if (!(0, checks_1.hasProperty)(data, 'steps')) {
        throw new Error('Recording is missing `steps`');
    }
    // if (
    //   hasProperty(data, 'timeout') &&
    //   isNumber(data.timeout) &&
    //   !validTimeout(data.timeout)
    // ) {
    //   throw new Error(timeoutErrorMessage);
    // }
    return {
        title: data.title,
        steps: parseDesktopSteps(data.steps),
    };
};
exports.parseUserFlow = parseUserFlow;
const parseDesktopSteps = (steps) => {
    if (!(0, checks_1.isArray)(steps)) {
        throw new Error('Recording `steps` is not an array');
    }
    const de = new json_1.DesktopStepJSONDeserializer();
    const result = [];
    for (const [idx, step] of steps.entries()) {
        result.push(de.deserialize(step, idx));
    }
    return result;
};
