import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export default function RetryIcon({
  stroke = '#103D61',
  ...props
}: SvgIconProps & { stroke?: string }) {
  return (
    <svg
      {...props}
      width="120"
      height="137"
      viewBox="0 0 120 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.3329 42.3534H115V5M112.868 104.785C106.034 115.505 96.0075 123.71 84.259 128.197C72.5106 132.684 59.6587 133.215 47.5919 129.713C35.5251 126.212 24.8802 118.863 17.2162 108.743C9.5521 98.6233 5.27564 86.2662 5.01288 73.4873C4.75012 60.7085 8.51295 48.1806 15.7543 37.7426C22.9956 27.3046 33.3315 19.508 45.2438 15.4947C57.1561 11.4814 70.0188 11.4621 81.9414 15.4437C93.864 19.4252 104.22 27.195 111.488 37.6137"
        stroke={stroke}
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
