import {
  type NotificationConfig,
  type NotificationConfigPayload,
} from './helper';
import { type SlackPlatformMetadata } from 'types-shared';
import { type TypedDocumentNode, useMutation, useQuery } from '@apollo/client';
import {
  graphql,
  GetWorkflowNotificationsDocument,
  UpsertNotificationConfigurationDocument,
  // eslint disable next line camelcase
  type Notifications_Enum_Platform_Enum as NotificationsEnumPlatformEnum,
  // eslint disable next line camelcase
  type Notifications_Workflow_Configuration_Events_Insert_Input as NotificationsWorkflowConfigurationEventsInsertInput,
} from 'hasura-gql';
import { handleException } from 'sentry-browser-shared';

export const useCreateWorkflowNotification = () => {
  const [createNotification, { data, loading, error }] = useMutation(
    UpsertNotificationConfigurationDocument,
  );

  const createNotificationConfig = async (
    payload: NotificationConfigPayload,
  ) => {
    const variables = {
      workflowId: payload.workflowId,
      platform: payload.platform,
      platformMetadata: payload.platformMetadata,
      events: payload.events,
    } as const;

    return createNotification({
      variables: {
        ...variables,
        platform: variables.platform as NotificationsEnumPlatformEnum,
        events:
          variables.events as NotificationsWorkflowConfigurationEventsInsertInput[],
        ...(payload.ownerId && { ownerId: payload.ownerId }),
      },
    });
  };

  return {
    createNotificationConfig,
    data,
    loading,
    error,
  };
};

export const useUpdateNotification = () => {
  const [updateNotification, { data, loading, error }] = useMutation(
    UpsertNotificationConfigurationDocument,
  );

  const updateNotificationConfig = async (
    notificationPayload: NotificationConfig,
  ) => {
    return updateNotification({
      variables: {
        workflowId: notificationPayload.workflowId,
        platform: notificationPayload.platform as NotificationsEnumPlatformEnum,
        platformMetadata: notificationPayload.platformMetadata,
        events: notificationPayload.events.map((e) => ({
          event: e.event,
        })) as NotificationsWorkflowConfigurationEventsInsertInput[],
      },
    });
  };

  return {
    updateNotificationConfig,
    data,
    loading,
    error,
  };
};

export const useDeleteNotification = () => {
  const [deleteNotification, { data, loading, error }] = useMutation(
    UpsertNotificationConfigurationDocument,
  );

  const deleteNotificationConfig = async ({
    workflowId,
    platform,
    platformMetadata,
    events,
  }: {
    workflowId: string;
    platform: NotificationsEnumPlatformEnum;
    platformMetadata: SlackPlatformMetadata;
    events:
      | NotificationsWorkflowConfigurationEventsInsertInput
      | NotificationsWorkflowConfigurationEventsInsertInput[];
  }) => {
    const variables = {
      workflowId,
      platform,
      platformMetadata,
      events,
      deletedAt: new Date().toISOString(),
    };

    return deleteNotification({
      variables,
    });
  };

  return {
    deleteNotificationConfig,
    data,
    loading,
    error,
  };
};

export const useGetWorkflowNotifications = (workflowId: string) => {
  const { data, loading, error, refetch } = useQuery(
    GetWorkflowNotificationsDocument,
    {
      variables: { workflowId },
      skip: !workflowId,
    },
  );

  return {
    refetchNotifications: refetch,
    notifications: data?.notifications_workflow_configurations || [],
    loading,
    error,
  };
};

const GET_TEAM_SLACK_CHANNEL_IDS = graphql(`
  query GetTeamSlackChannelIds($workflowId: uuid!) {
    workflow: workflows_by_pk(id: $workflowId) {
      owner {
        memberships {
          team {
            id
            slackChannelId
          }
        }
      }
    }
  }
`) as TypedDocumentNode<
  {
    workflow: {
      owner: {
        memberships: {
          team: {
            id: string;
            slackChannelId: string;
          };
        }[];
      };
    };
  },
  {
    workflowId: string;
  }
>;

export const useGetTeamSlackChannelId = (workflowId: string) => {
  const { data, loading, error } = useQuery(GET_TEAM_SLACK_CHANNEL_IDS, {
    variables: { workflowId },
    skip: !workflowId,
  });

  const channelId = data?.workflow.owner.memberships[0]?.team?.slackChannelId;

  if (data && data.workflow.owner.memberships.length > 1) {
    handleException(new Error('Multiple Slack channel IDs found. Expected 1'), {
      name: 'Multiple Slack Channels',
      source: 'useGetTeamSlackChannelIds',
      extra: {
        workflowId,
        data,
      },
    });
  }

  return {
    channelId: channelId || '',
    loading,
    error,
  };
};

const GET_CONVERSATION = graphql(`
  query GetConversation($conversationId: String!) {
    getConversation(conversationId: $conversationId) {
      name
    }
  }
`) as TypedDocumentNode<
  {
    getConversation: {
      name: string;
    };
  },
  {
    conversationId: string;
  }
>;

export const useGetSlackChannelName = (channelId: string) => {
  const { data, loading, error } = useQuery(GET_CONVERSATION, {
    variables: { conversationId: channelId },
    skip: !channelId,
  });

  return {
    channelName: data?.getConversation.name || '',
    loading,
    error,
  };
};
