"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getXpathSelectorValue = getXpathSelectorValue;
const replay_1 = require("@puppeteer/replay");
function getXpathSelectorValue(selector) {
    let selectorString;
    // MARK: Deviation from puppeteer-replay -- we do not support multi-part selectors
    if (Array.isArray(selector)) {
        if (selector.length !== 1) {
            throw new Error(`Expected 1 part in selector, got: ${JSON.stringify(selector)}`);
        }
        selectorString = selector[0];
    }
    else {
        selectorString = selector;
    }
    if ((0, replay_1.getSelectorType)(selectorString) === replay_1.SelectorType.XPath) {
        return selectorString.substring(replay_1.SelectorType.XPath.length + 1);
    }
    throw new Error(`Selector is not an XPath: ${JSON.stringify(selector)}`);
}
