import { clsx } from 'clsx';
import type { ReactElement } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type {
  EdgeTypes,
  NodeTypes,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  ReactFlowInstance,
  Viewport,
} from 'types-shared/reactflow';
import {
  Background,
  ConnectionLineType,
  ReactFlow,
  ReactFlowProvider,
  SelectionMode,
} from 'types-shared/reactflow';
import { AlertVariant, notify, Spinner } from 'ui-kit';
import {
  DEFAULT_ZOOM,
  editorMaxZoomLevel,
  editorMinZoomLevel,
} from '../utils/constants';
import { contactModalEventChannel } from '../../../utils/contactModal';
import {
  BulkCheckableNodeTypes,
  type DatasourceMetadata,
  type DatasourceTable,
  NodeSelectionModeEnums,
  NodeStatusEnum,
  NodeTypesEnum,
  SourceTypeEnum,
  SourceVariable,
  type TargetMap,
  type TemplateData,
  type Variable,
  type VariableMap,
  VariableTypeEnum,
  type WorkflowData,
  type WorkflowEdge,
  type WorkflowImageNode,
  type WorkflowNode,
  type GlobalVariable,
} from 'types-shared';
import type {
  GetWorkflowMetadataResponse,
  LlmTransformResponse,
  SendSlackMessageWithFileResponse,
  SendSlackMessageWithFileRequestPayload,
  VersionConfigurations,
} from 'api-types-shared';
import { WorkflowStatusEnum } from 'api-types-shared';
import { ActionsHeader } from './ActionsHeader';
import { FlowViewControls } from './FlowViewControls';
import { useEditingNodeId } from '../hooks/useEditingNodeId';
import isEmpty from 'lodash/isEmpty';
import { useVersionHistory } from '../hooks/useVersionHistory';
import VersionHistoryPanel from './VersionHistoryPanel';
import values from 'lodash/values';
import size from 'lodash/size';
import {
  checkSelectedNodes,
  createWorkflowData,
  getQueryParam,
  mergeSelectedNodes,
  parseWorkflowDataFromClipboardData,
  transformPastedWorkflow,
} from '../utils/helper';
import { Connector } from './EdgeElement/Connector';
import { isAdmin } from '../../../utils/env';
import { copyToClipboard } from '../../../utils/helper';
import { EditNodePanel } from './EditNodePanel';
import { adjustEdgesForHiddenNodes } from '../utils/hidden-nodes.helper';
import useReactFlowZoomFix from '../hooks/useReactFlowZoomFix';

export function EditorCore({
  Toolbar,
  ActionView,
  allowNodesMerging = false,
  showSelectAllButton = false,
  nodeTypes,
  edgeTypes,
  nodes,
  setNodes,
  addNodes,
  addEdges,
  updateNode,
  variables,
  globalVariables,
  setVariables,
  addVariable,
  updateVariable,
  edges,
  setEdges,
  onConnect,
  onEdgesChange,
  onImport,
  onNodesChange,
  selectedNode,
  datasourceMetadata,
  setDatasourceMetadata,
  tableData,
  setDatasourceTable,
  sourceType,
  setSourceType,
  targets,
  setTargets,
  setThumbnails,
  workflowMetadata,
  workflowData,
  isFetchingWorkflowData,
  nodeViewData,
  isFetchingNodeData,
  allThumbnails,
  isFetchingThumbnails,
  datasourceMetadataResponse,
  datasourceTableData,
  transformApiReq,
  transformApiReqStatus,
  continueRecordingBlockEnabled,
  sendEmailStepEnabled,
  endingStatusBlockFeatureEnabled,
  enabledFeatureFlags,
  workflowVersionData,
  versionLoading,
  onLoadVersion,
  onRestore,
  downloadCloudVersion,
  uploadLocalVersionToCloud,
  selectedVersion,
  cloneWorkflowImages,
  addTargets,
  addVariables,
  currentViewport,
  setCurrentViewport,
  onUploadFile,
  fullRequestNodeVersion,
  versions = [],
  hasSuggestions,
  setSelectedNode,
  isReadonlyView = false,
  selectedMode,
  setSelectedMode,
}: {
  isReadonlyView?: boolean;
  editorWorkflowId?: string;
  Toolbar: ReactElement;
  ActionView: ReactElement | null;
  allowNodesMerging: boolean;
  showSelectAllButton: boolean;

  nodeTypes: NodeTypes;
  edgeTypes: EdgeTypes;

  nodes: WorkflowNode[];
  setNodes: (nodes: WorkflowNode[]) => void;
  addNodes: (nodes: WorkflowNode[]) => void;
  updateNode: (node: WorkflowNode) => void;

  variables: VariableMap;
  globalVariables: Record<string, GlobalVariable>;
  setVariables: (variables: VariableMap) => void;
  addVariable: (variable: Variable) => void;
  addVariables?: (variables: VariableMap) => void;
  updateVariable: (variable: Variable) => void;

  edges: WorkflowEdge[];
  addEdges: (edges: WorkflowEdge[]) => void;
  setEdges: (edges: WorkflowEdge[]) => void;

  onConnect: OnConnect;
  onEdgesChange: OnEdgesChange;
  onImport?: (replaceNodeId?: string) => Promise<boolean>;
  onNodesChange: OnNodesChange;

  selectedNode?: string | null;
  setSelectedNode: (nodeId: string | null) => void;
  selectedVersion: string;

  datasourceMetadata: DatasourceMetadata | null;
  setDatasourceMetadata: (datasourceMetadata: DatasourceMetadata) => void;

  tableData: DatasourceTable | null;
  setDatasourceTable: (tableData: DatasourceTable) => void;

  sourceType?: SourceTypeEnum;
  setSourceType?: (sourceType: SourceTypeEnum) => void;

  targets: TargetMap;
  setTargets: (targets: TargetMap) => void;
  addTargets?: (targets: TargetMap) => void;

  setThumbnails: (thumbnails: Record<string, string | null>) => void;

  workflowMetadata?: GetWorkflowMetadataResponse | null;
  workflowData?: WorkflowData | null;
  isFetchingWorkflowData: boolean;
  nodeViewData?: {
    targetData: TargetMap;
    variableData: VariableMap;
  };
  isFetchingNodeData: boolean;
  allThumbnails?: Record<string, string | null>;
  isFetchingThumbnails: boolean;
  datasourceMetadataResponse?: DatasourceMetadata[];
  isFetchingDatasourceMetadata: boolean;
  datasourceTableData?: DatasourceTable | null;
  isFetchingDatasourceTableData: boolean;

  sendMessage: (
    variables: SendSlackMessageWithFileRequestPayload,
  ) => Promise<SendSlackMessageWithFileResponse>;
  sendMessageStatus: 'error' | 'success' | 'pending' | 'idle';
  transformApiReq?: (variables: {
    data: string;
    prompt: TemplateData;
  }) => Promise<LlmTransformResponse | null>;
  transformApiReqStatus: 'error' | 'success' | 'pending' | 'idle';
  endingStatusBlockFeatureEnabled?: boolean;
  enabledFeatureFlags: string[];
  continueRecordingBlockEnabled?: boolean;
  sendEmailStepEnabled?: boolean;
  workflowVersionData: WorkflowData | null | undefined;
  versionLoading?: boolean;
  onLoadVersion: (version: string) => void;
  onRestore: () => void;
  downloadCloudVersion: () => void;
  uploadLocalVersionToCloud: () => void;
  cloneWorkflowImages?: (data: {
    fromWorkflow: WorkflowData;
    toWorkflowId: string;
  }) => Promise<unknown>;
  currentViewport?: Viewport;
  setCurrentViewport: (viewport: Viewport) => void;
  onUploadFile: (file: File) => Promise<{ fileId: string }>;
  fullRequestNodeVersion?: boolean;
  versions?: VersionConfigurations[];
  hasSuggestions?: boolean;
  selectedMode: NodeSelectionModeEnums | null;
  setSelectedMode: (newMode: NodeSelectionModeEnums | null) => void;
}) {
  const { workflowId } = useParams();
  if (!workflowId) {
    throw new Error('Workflow ID not provided');
  }

  const navigate = useNavigate();
  useReactFlowZoomFix();

  const isEditorReadonly = isReadonlyView || hasSuggestions;

  const [navMode, setNavMode] = useState<'pan' | 'trackpad'>('pan');
  const { editingNodeId, setEditingNodeId } = useEditingNodeId();
  const { showVersionHistory, toggleVersionHistory } = useVersionHistory();

  const { nodes: adjustedNodes, edges: adjustedEdges } = useMemo(
    () => adjustEdgesForHiddenNodes(nodes, edges),
    [nodes, edges],
  );

  const sourceVariableId = useMemo(() => {
    if (isEmpty(variables)) {
      return undefined;
    }
    return values(variables).find((v) => {
      return (
        v.type === VariableTypeEnum.Source &&
        SourceVariable.safeParse(v).success &&
        (v as SourceVariable).data.sourceType === SourceTypeEnum.API
      );
    })?.id;
  }, [variables]);

  const handleDefaultViewport = (instance: ReactFlowInstance) => {
    instance.fitView({
      nodes: nodes.filter((node) => node.type === NodeTypesEnum.Source),
      maxZoom: DEFAULT_ZOOM,
      minZoom: DEFAULT_ZOOM,
    });
  };

  const onApplyChanges = (mode: NodeSelectionModeEnums) => {
    if (mode === NodeSelectionModeEnums.BulkCheck) {
      const newNodes = checkSelectedNodes(nodes as WorkflowImageNode[]);
      setNodes(newNodes);
    } else {
      const { newNodes, newEdges } = mergeSelectedNodes(
        nodes as WorkflowImageNode[],
        edges,
      );
      setEdges(newEdges);
      setNodes(newNodes);
    }
  };

  const onEnableSelectionMode = () => {
    const filteredNodes = nodes.map((node) => {
      if (!BulkCheckableNodeTypes.includes(node.type)) return node;

      return {
        ...node,
        data: {
          ...node.data,
          selected: node.data.nodeStatus === NodeStatusEnum.Checked, // select the checked nodes by default
        },
      };
    }) as WorkflowNode[];
    setNodes(filteredNodes);
  };

  const onCancelNodeSelection = () => {
    const filteredNodes = nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        selected: false,
      },
    })) as WorkflowNode[];
    setNodes(filteredNodes);
  };

  const handleCopy = useCallback(
    (event: ClipboardEvent) => {
      const reactFlowViewport = document.querySelector('.react-flow__renderer');
      if (
        event.target &&
        reactFlowViewport?.contains(event.target as HTMLElement) &&
        isAdmin
      ) {
        const selectedNodes = nodes.filter((node) => node.selected);
        const selectedNodeIds = selectedNodes.map((node) => node.id);
        const selectedEdges = edges.filter(
          (edge) =>
            edge.selected &&
            selectedNodeIds.includes(edge.source) &&
            selectedNodeIds.includes(edge.target),
        );
        const payload = JSON.stringify(
          createWorkflowData({
            workflowId,
            targets,
            variables,
            globalVariables,
            nodes,
            selectedNodes,
            selectedEdges,
          }),
        );
        copyToClipboard(payload, 'Nodes and Edges copied to clipboard');
      }
    },
    [nodes, edges, targets, variables, globalVariables, workflowId],
  );

  const handlePaste = useCallback(
    async (event: ClipboardEvent) => {
      let clipboardText = event.clipboardData?.getData('text');
      if (
        isAdmin &&
        !selectedNode &&
        !showVersionHistory &&
        !editingNodeId &&
        clipboardText
      ) {
        if (sourceVariableId) {
          // NOTE: this is done in order to make the copied workflow work in the current context
          // without having to change the source variable id
          const regexPattern = /"sourceIds":\s*\[\s*".*?"\s*\]/;
          clipboardText = clipboardText.replace(
            regexPattern,
            `"sourceIds":["${sourceVariableId}"]`,
          );
        }
        try {
          const pastedWorkflowData =
            parseWorkflowDataFromClipboardData(clipboardText);
          const fromWorkflowId = pastedWorkflowData?.workflowId;
          if (fromWorkflowId) {
            const isPastedInSameWorkflow = fromWorkflowId === workflowId;
            const {
              workflowData: _workflowData,
              targetMap,
              variableMap,
            } = transformPastedWorkflow(
              pastedWorkflowData,
              isPastedInSameWorkflow,
              true,
              variables,
            );
            if (!isPastedInSameWorkflow) {
              notify({
                message: 'Pasting copied workflow data',
                variant: AlertVariant.INFO,
                timeoutInMs: 5000,
              });
              await cloneWorkflowImages?.({
                fromWorkflow: _workflowData,
                toWorkflowId: workflowId,
              });
            }
            const { nodes: _nodes, edges: _edges } = _workflowData;
            if (_nodes.length) {
              addNodes(_nodes);
            }
            if (_edges.length) {
              addEdges(_edges);
            }
            if (!isEmpty(targetMap)) {
              addTargets?.(targetMap);
            }
            if (!isEmpty(variableMap)) {
              addVariables?.(variableMap);
            }
            notify({
              message: 'Pasted copied workflow data',
              variant: AlertVariant.SUCCESS,
            });
          }
        } catch (e) {
          // eslint-disable-next-line
          console.log('Failed to parse copied data', clipboardText, e);
        }
      }
    },
    [
      addEdges,
      addNodes,
      addTargets,
      addVariables,
      cloneWorkflowImages,
      editingNodeId,
      selectedNode,
      showVersionHistory,
      sourceVariableId,
      workflowId,
      variables,
    ],
  );

  useEffect(() => {
    if (allThumbnails) {
      setThumbnails(allThumbnails);
    }
  }, [allThumbnails, setThumbnails]);

  useEffect(() => {
    if (workflowData && isEmpty(nodes)) {
      setNodes(workflowData.nodes);
      setEdges(workflowData.edges);
    }
  }, [workflowData, setNodes, setEdges, nodes]);

  useEffect(() => {
    if (nodeViewData && isEmpty(variables) && isEmpty(targets)) {
      setTargets(nodeViewData.targetData);
      setVariables(nodeViewData.variableData);
    }
  }, [nodeViewData, setTargets, setVariables, variables, targets]);

  // do this only if there is no sourceType set
  useEffect(() => {
    if (!setSourceType || sourceType) {
      return;
    }
    const datasourceMeta = datasourceMetadataResponse?.at(0);
    if (datasourceMeta) {
      setDatasourceMetadata(datasourceMeta);
      setSourceType(SourceTypeEnum.Datasource);
    } else {
      setSourceType(SourceTypeEnum.API);
    }
  }, [
    datasourceMetadataResponse,
    setDatasourceMetadata,
    setSourceType,
    sourceType,
  ]);

  useEffect(() => {
    if (datasourceTableData) {
      setDatasourceTable(datasourceTableData);
    }
  }, [datasourceTableData, setDatasourceTable]);

  useEffect(() => {
    if (
      workflowMetadata?.status === WorkflowStatusEnum.ProcessingImport &&
      !isAdmin
    ) {
      navigate('/workflows');
    }
  }, [navigate, workflowMetadata]);

  useEffect(() => {
    window.addEventListener('copy', handleCopy);
    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('copy', handleCopy);
      window.removeEventListener('paste', handlePaste);
    };
  }, [nodes, edges, handleCopy, handlePaste]);

  // Update document title when workflow name changes and restore on unmount
  useEffect(() => {
    const originalTitle = document.title;

    if (workflowMetadata?.workflowName) {
      document.title = `${workflowMetadata.workflowName} - ${
        isAdmin ? 'Admin' : 'Sola Editor'
      }`;
    } else {
      document.title = 'Sola Editor';
    }

    // Restore original title on unmount
    return () => {
      document.title = originalTitle;
    };
  }, [workflowMetadata?.workflowName]);

  const onTransformApiReq = useCallback(
    async (prompt: TemplateData, textToTransform: string) => {
      if (prompt.length > 0 && textToTransform) {
        const value = await transformApiReq?.({
          data: textToTransform,
          prompt,
        });
        return value?.processedData;
      }
      return undefined;
    },
    [transformApiReq],
  );

  const endingStatusBlockEnabled = useMemo(() => {
    if (!editingNodeId) {
      return endingStatusBlockFeatureEnabled;
    }
    const outgoingEdge = edges.find((e) => e.source === editingNodeId);
    const targetNode = nodes.find((n) => n.id === outgoingEdge?.target);
    return (!outgoingEdge || !targetNode) && endingStatusBlockFeatureEnabled;
  }, [edges, editingNodeId, endingStatusBlockFeatureEnabled, nodes]);

  const loading = useMemo(() => {
    return isFetchingWorkflowData || isFetchingNodeData;
  }, [isFetchingNodeData, isFetchingWorkflowData]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const focusNodeId = getQueryParam('focusNode');
    if (focusNodeId) {
      setTimeout(() => {
        setSelectedNode(focusNodeId);
      }, 0);
    }
  }, [loading, setSelectedNode]);

  return (
    <div
      className={clsx('flex flex-col flex-1 h-full md:!flex-row', {
        'justify-center items-center': loading,
        'readonly preview-mode': isReadonlyView,
      })}
    >
      {showVersionHistory ? (
        <VersionHistoryPanel
          downloadCloudVersion={downloadCloudVersion}
          edgeTypes={edgeTypes}
          edges={workflowVersionData?.edges ?? []}
          isLoading={versionLoading}
          nodeTypes={nodeTypes}
          nodes={workflowVersionData?.nodes ?? []}
          onCancel={() => {
            toggleVersionHistory(false);
          }}
          onLoadVersion={onLoadVersion}
          onRestore={onRestore}
          selectedVersion={selectedVersion}
          uploadLocalVersionToCloud={uploadLocalVersionToCloud}
          versions={versions}
          workflowMetadata={workflowMetadata}
        />
      ) : (
        <ReactFlowProvider>
          <div className={clsx('flex-1 h-full w-full relative flex flex-col')}>
            {Toolbar}
            {isFetchingWorkflowData ||
            isFetchingNodeData ||
            isFetchingThumbnails ? (
              <div className="w-full h-full flex items-center justify-center">
                <Spinner className="!text-black" size={32} />
              </div>
            ) : (
              <ReactFlow
                attributionPosition="top-right"
                className={clsx('relative', {
                  'readonly error-push': hasSuggestions,
                })}
                connectionLineComponent={Connector}
                connectionLineType={ConnectionLineType.Bezier}
                defaultEdgeOptions={{
                  deletable: Boolean(isAdmin && !isEditorReadonly),
                  focusable: Boolean(isAdmin && !isEditorReadonly),
                }}
                edgeTypes={edgeTypes}
                edges={adjustedEdges}
                elementsSelectable={Boolean(isAdmin && !hasSuggestions)}
                maxZoom={editorMaxZoomLevel}
                minZoom={editorMinZoomLevel}
                nodeTypes={nodeTypes}
                nodes={adjustedNodes}
                nodesConnectable={Boolean(isAdmin && !hasSuggestions)}
                onInit={(instance) => {
                  if (!currentViewport) {
                    handleDefaultViewport(instance);
                  } else {
                    instance.setViewport(currentViewport);
                  }
                }}
                onMove={(_event, data) => {
                  setCurrentViewport(data);
                }}
                {...(hasSuggestions
                  ? {
                      edgesFocusable: false,
                      edgesUpdatable: false,
                      nodesDraggable: false,
                      nodesFocusable: false,
                    }
                  : {
                      onConnect,
                      onEdgesChange,
                      onNodesChange,
                    })}
                panOnDrag={navMode !== 'trackpad' || hasSuggestions}
                panOnScroll={navMode === 'trackpad' || hasSuggestions}
                proOptions={{ hideAttribution: true }}
                selectionMode={SelectionMode.Partial}
                selectionOnDrag={navMode === 'trackpad' && !hasSuggestions}
              >
                <Background className="bg-flow-view" />
                {!isEditorReadonly ? (
                  <ActionsHeader
                    allowNodesMerging={allowNodesMerging}
                    nodes={nodes}
                    onApplyChanges={onApplyChanges}
                    onCancel={onCancelNodeSelection}
                    onEnableSelectionMode={onEnableSelectionMode}
                    setNodes={setNodes}
                    showSelectAllButton={showSelectAllButton}
                    setSelectedMode={setSelectedMode}
                    selectedMode={selectedMode}
                  />
                ) : null}
                {!selectedNode ? (
                  <FlowViewControls
                    edges={edges}
                    navMode={navMode}
                    nodes={nodes}
                    openContactModal={() => {
                      contactModalEventChannel.emit('open', {
                        workflowId,
                        workflowName: workflowMetadata?.workflowName,
                      });
                    }}
                    setNavMode={setNavMode}
                    setNodes={setNodes}
                    showFormatNodesButton={!hasSuggestions && !isReadonlyView}
                  />
                ) : null}
                {editingNodeId ? (
                  <EditNodePanel
                    workflowMetadata={workflowMetadata}
                    workflowId={workflowId}
                    addNodes={addNodes}
                    addVariable={addVariable}
                    allowBranchReordering={isAdmin}
                    continueRecordingBlockEnabled={
                      continueRecordingBlockEnabled
                    }
                    sendEmailStepEnabled={sendEmailStepEnabled}
                    datasourceMetadata={datasourceMetadata}
                    edges={edges}
                    fullRequestNodeVersion={Boolean(fullRequestNodeVersion)}
                    enabledFeatureFlags={enabledFeatureFlags}
                    stopBlockEnabled={endingStatusBlockEnabled}
                    nodeId={editingNodeId}
                    nodes={nodes}
                    onCancel={() => {
                      setEditingNodeId(undefined);
                    }}
                    onImport={onImport}
                    onUploadFile={onUploadFile}
                    onTransformApiReq={onTransformApiReq}
                    setEdges={setEdges}
                    setNodes={setNodes}
                    tableData={tableData}
                    transformApiReqStatus={transformApiReqStatus}
                    sourceType={sourceType}
                    updateNode={updateNode}
                    updateVariable={updateVariable}
                    variablesMap={variables}
                    globalVariablesMap={globalVariables}
                  />
                ) : null}
              </ReactFlow>
            )}
          </div>
        </ReactFlowProvider>
      )}
      {selectedNode &&
      nodes.length > 0 &&
      (size(variables) > 0 || size(targets) > 0)
        ? ActionView
        : null}
    </div>
  );
}
