"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DESKTOP_CUSTOM_STEP_TYPES = exports.LegacyCompatibilityOptions = void 0;
exports.isDesktopCustomStepType = isDesktopCustomStepType;
const types_shared_1 = require("types-shared");
// NOTE: As we have more requirements for performing 'legacy' actions in desktop workflows
// We'll need to modify this type to support
exports.LegacyCompatibilityOptions = types_shared_1.z.object({
    step: types_shared_1.ExecutionStep.optional(),
    stepData: types_shared_1.ExecutionStepData.optional(),
});
exports.DESKTOP_CUSTOM_STEP_TYPES = {
    screenshot: 'screenshot',
    hitl: 'hitl',
    leftMouseUp: 'leftMouseUp',
    rightMouseUp: 'rightMouseUp',
    open: 'open',
    keyboardShortcut: 'keyboardShortcut',
};
function isDesktopCustomStepType(value) {
    return Object.values(exports.DESKTOP_CUSTOM_STEP_TYPES).includes(value);
}
