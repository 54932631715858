import { Button, InputWithActiveBorder, Select, Switch, Tooltip } from 'ui-kit';
import { titleCase, useAuth, useFeatureFlag } from '../../../utils/helper';
import { WorkflowStatusEnum } from 'api-types-shared';
import { useSearchParams } from 'react-router-dom';
import { type WorkflowListFilters } from '../types';
import { isAdmin } from '../../../utils/env';
import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from '@mui/material/utils/debounce';
import {
  defaultWorkflowListFilters,
  workflowStatusMapping,
} from '../utils/constants';
import { useTeamDetails } from '../../../hooks/useTeamDetails.gql';
import { FeatureFlag } from '../../../utils/constants';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import { clsx } from 'clsx';

let workflowStatusOptions = ['All', ...Object.values(WorkflowStatusEnum)];

const adminStatuses: string[] = [
  WorkflowStatusEnum.Invalid,
  WorkflowStatusEnum.ProcessedImport,
  WorkflowStatusEnum.ReviewingImport,
];

if (!isAdmin) {
  workflowStatusOptions = workflowStatusOptions.filter(
    (status) => !adminStatuses.includes(status),
  );
  workflowStatusOptions.push('Needs review');
}

interface Props {
  filters: WorkflowListFilters;
  setFilters: (filters: WorkflowListFilters) => void;
}

export default function WorkflowListFilter({ filters, setFilters }: Props) {
  const { user } = useAuth();
  const userID = user?.sub ?? '';
  const { data: teamDetails } = useTeamDetails(userID);
  const isAuthUserAdmin = teamDetails?.isAdmin ?? false;
  const [, setSearchParams] = useSearchParams();
  const [name, setName] = useState<string>(filters.name ?? '');
  const [email, setEmail] = useState<string>(filters.email ?? '');
  const workflowFiltersEnabled = useFeatureFlag(FeatureFlag.WorkflowFilters);

  const hasFilters = useMemo(
    () => !isDeepEqual(filters, defaultWorkflowListFilters),
    [filters],
  );

  const onFilterChange = (key: string, value: string) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFilters = useCallback(debounce(onFilterChange, 300), [
    onFilterChange,
  ]);

  const onClear = () => {
    setFilters(defaultWorkflowListFilters);
    setName('');
    setEmail('');
    setSearchParams({});
  };

  useEffect(() => {
    if (!hasFilters) {
      setSearchParams({});
    }
  }, [hasFilters, setSearchParams]);

  return workflowFiltersEnabled ? (
    <div className="w-full flex items-center space-x-4 mt-10">
      <InputWithActiveBorder
        classes={{ root: 'flex-1' }}
        style={{ fontSize: '14px' }}
        isSearch
        label="Name"
        floatingLabel
        placeholder="Search by Name"
        onChange={(val) => {
          setName(val);
          debouncedSetFilters('name', val);
        }}
        value={name}
      />
      {isAuthUserAdmin || isAdmin ? (
        <InputWithActiveBorder
          classes={{ root: 'flex-1' }}
          style={{ fontSize: '14px' }}
          isSearch
          label="Owner"
          floatingLabel
          placeholder="Search by Owner"
          onChange={(val) => {
            setEmail(val);
            debouncedSetFilters('email', val);
          }}
          value={email}
        />
      ) : null}
      <Select
        className={clsx('min-w-56 !min-h-[62px] !mt-[2px]', {
          'is-active': filters.status && filters.status !== 'All',
        })}
        getLabel={(op) => {
          const title = titleCase(op);
          if (!isAdmin) {
            return workflowStatusMapping[op] ?? title;
          }
          return title;
        }}
        getValue={(op) => op}
        label="Status"
        onChange={(e) => {
          setFilters({
            ...filters,
            status: e.target.value as WorkflowStatusEnum,
          });
        }}
        options={workflowStatusOptions}
        value={filters.status}
        MenuProps={{
          BackdropProps: { style: { backgroundColor: 'transparent' } },
        }}
      />
      {isAdmin ? (
        <Tooltip
          arrow
          title="hide all the users with @sola-solutions.com email"
        >
          <div className="flex items-center space-x-1">
            <span className="min-w-max">Hide internal</span>
            <Switch
              checked={Boolean(filters.hideInternal)}
              onChange={(e, hideInternal) => {
                setFilters({
                  ...filters,
                  hideInternal,
                });
              }}
            />
          </div>
        </Tooltip>
      ) : null}
      {hasFilters ? (
        <Button color="secondary" onClick={onClear} variant="text">
          Clear Filters
        </Button>
      ) : null}
    </div>
  ) : null;
}
