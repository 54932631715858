"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardShortcutType = exports.KeyboardShortcutParameters = exports.DesktopKeyboardShortcutStepFactory = exports.OpenStepParameters = exports.createOpenStep = exports.createHitlStep = exports.createScreenshotStep = exports.createWaitStep = exports.createInputStep = exports.createKeyStep = exports.createClickStep = exports.DesktopStepJSONDeserializer = void 0;
const replay_1 = require("@puppeteer/replay");
const steps_1 = require("../../types/steps");
const checks_1 = require("../../types/checks");
const click_step_1 = require("./click-step");
const key_step_1 = require("./key-step");
const input_step_1 = require("./input-step");
const wait_step_1 = require("./wait-step");
const open_step_1 = require("./open-step");
const keyboard_shortcuts_1 = require("./keyboard-shortcuts");
class DesktopStepJSONDeserializer {
    deserialize(step, idx) {
        if (!(0, checks_1.isObject)(step)) {
            throw new Error(idx ? `Step ${String(idx)} is not an object` : 'Step is not an object');
        }
        if (!(0, checks_1.hasProperty)(step, 'type')) {
            throw new Error(idx
                ? `Step ${String(idx)} does not have a type`
                : 'Step does not have a type');
        }
        if (!(0, checks_1.hasProperty)(step, 'id')) {
            throw new Error(idx
                ? `Step ${String(idx)} does not have an id`
                : 'Step does not have a id');
        }
        if (!(typeof step.type === 'string')) {
            throw new Error(idx
                ? `Type of the step ${String(idx)} is not a string`
                : 'Type of the step is not a string');
        }
        const id = step.id;
        const puppeteerStep = step;
        const parsedStep = this.parseDesktopStep(id, puppeteerStep, idx);
        if ((0, checks_1.hasProperty)(step, 'legacyCompatibilityOptions') &&
            step.legacyCompatibilityOptions) {
            parsedStep.legacyCompatibilityOptions = step.legacyCompatibilityOptions;
        }
        return parsedStep;
    }
    parseDesktopStep(id, step, idx) {
        switch (step.type) {
            case replay_1.StepType.CustomStep:
                return parseCustomStep(id, (0, replay_1.parseStep)(step, idx));
            case replay_1.StepType.Click:
                return (0, click_step_1.parseClickStep)(id, (0, replay_1.parseStep)(step, idx));
            case replay_1.StepType.KeyDown:
            case replay_1.StepType.KeyUp:
                return (0, key_step_1.parseKeyStep)(id, (0, replay_1.parseStep)(step, idx));
            case replay_1.StepType.Change:
                return (0, input_step_1.parseInputStep)(id, (0, replay_1.parseStep)(step, idx));
            case replay_1.StepType.WaitForExpression:
                return (0, wait_step_1.parseWaitStep)(id, (0, replay_1.parseStep)(step, idx));
            default:
                return Object.assign({ id }, (0, replay_1.parseStep)(step, idx));
        }
    }
}
exports.DesktopStepJSONDeserializer = DesktopStepJSONDeserializer;
const parseCustomStep = (id, step, _idx) => {
    if (!(0, steps_1.isDesktopCustomStepType)(step.name)) {
        throw new Error(`CustomStep ${step.name} is not supported`);
    }
    switch (step.name) {
        case 'open':
            return (0, open_step_1.parseOpenStep)(id, step);
        case 'keyboardShortcut':
            return (0, keyboard_shortcuts_1.parseKeyboardShortcutStep)(id, step);
        case 'screenshot':
        case 'hitl':
        case 'leftMouseUp':
        case 'rightMouseUp':
            return Object.assign({ id }, step);
    }
};
var click_step_2 = require("./click-step");
Object.defineProperty(exports, "createClickStep", { enumerable: true, get: function () { return click_step_2.createClickStep; } });
var key_step_2 = require("./key-step");
Object.defineProperty(exports, "createKeyStep", { enumerable: true, get: function () { return key_step_2.createKeyStep; } });
var input_step_2 = require("./input-step");
Object.defineProperty(exports, "createInputStep", { enumerable: true, get: function () { return input_step_2.createInputStep; } });
var wait_step_2 = require("./wait-step");
Object.defineProperty(exports, "createWaitStep", { enumerable: true, get: function () { return wait_step_2.createWaitStep; } });
var screenshot_step_1 = require("./screenshot-step");
Object.defineProperty(exports, "createScreenshotStep", { enumerable: true, get: function () { return screenshot_step_1.createScreenshotStep; } });
var hitl_step_1 = require("./hitl-step");
Object.defineProperty(exports, "createHitlStep", { enumerable: true, get: function () { return hitl_step_1.createHitlStep; } });
var open_step_2 = require("./open-step");
Object.defineProperty(exports, "createOpenStep", { enumerable: true, get: function () { return open_step_2.createOpenStep; } });
Object.defineProperty(exports, "OpenStepParameters", { enumerable: true, get: function () { return open_step_2.OpenStepParameters; } });
var keyboard_shortcuts_2 = require("./keyboard-shortcuts");
Object.defineProperty(exports, "DesktopKeyboardShortcutStepFactory", { enumerable: true, get: function () { return keyboard_shortcuts_2.DesktopKeyboardShortcutStepFactory; } });
Object.defineProperty(exports, "KeyboardShortcutParameters", { enumerable: true, get: function () { return keyboard_shortcuts_2.KeyboardShortcutParameters; } });
Object.defineProperty(exports, "KeyboardShortcutType", { enumerable: true, get: function () { return keyboard_shortcuts_2.KeyboardShortcutType; } });
