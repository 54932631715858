import { WorkflowStatusEnum } from 'api-types-shared';
import { isAdmin } from '../../../utils/env';

export const workflowStatusMapping: Record<string, string> = {
  [WorkflowStatusEnum.Invalid]: 'Needs review',
  [WorkflowStatusEnum.ProcessedImport]: 'Needs review',
  [WorkflowStatusEnum.ProcessingImport]: 'Processing',
  [WorkflowStatusEnum.Ready]: 'Ready to run',
  [WorkflowStatusEnum.ProcessingImportError]: 'Processing error',
  [WorkflowStatusEnum.ProcessingImportErrorAuthentication]: 'Missing access',
  'Needs review': 'Needs review',
};

export const defaultWorkflowListFilters = {
  name: '',
  email: '',
  status: 'All' as WorkflowStatusEnum,
  ...(isAdmin ? { hideInternal: false } : {}),
};
