import {
  ActionsEnum,
  ActionValueCriteriaEnum,
  DocumentSourceEnum,
  type GlobalVariable,
  type VariableMap,
  type TargetMap,
  TemplateVariable,
  type Variable,
  VariableTypeEnum,
  type WorkflowAction,
  type WorkflowActionsOptions,
  parseTemplateString,
} from 'types-shared';
import { clsx } from 'clsx';
import {
  type EventBus,
  type EventMap,
  ArrowDownLeftIcon,
  ArrowUpLeftIcon,
  Chip,
  IconButton,
  Input,
  Tooltip,
  Switch,
  ConditionalIcon,
  EditIconAlt as EditIcon,
  InfoOutlined,
  Person,
  VisibilityOff,
  Checkbox,
} from 'ui-kit';
import { v4 as uuid } from 'uuid';
import ActionMenu from './Menu';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { ActionRenderer } from './ActionRenderer';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import Alert from '@mui/material/Alert';
import { isAdmin } from '../../../../../utils/env';
import useReadonlyWorkflow from '../../../hooks/useReadonlyWorkflow';
import { shallowCloneVariable } from '../../../utils/helper';
import { EditorStore } from '../../../store/EditorState';
import { useShallow } from 'zustand/react/shallow';

interface ImageNodeEvents extends EventMap {
  onActionHover: (targetId: string | null) => void;
  onTargetHover: (targetId: string | null) => void;
}

interface Props {
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  variablesMap: Record<string, Variable>;
  onEditClick?: (action: WorkflowAction) => void;
  onUpdateActionOptions: (
    action: WorkflowAction,
    options: WorkflowActionsOptions,
  ) => void;
  action: WorkflowAction;
  i: number;
  imageNodeEventChannel: EventBus<ImageNodeEvents>;
  isDragging?: boolean;
  allowDeleteAction: boolean;
  onEditTarget?: () => void;
  onMoveAction?: () => void;
  onDeleteAction?: () => void;
  onAddAction?: (action: WorkflowAction) => void;
  showManualHandleOption?: boolean;
  updateVariableData?: (
    variableId: string,
    data: Pick<TemplateVariable, 'data'>,
  ) => void;
  children?: ReactNode;
  targets: TargetMap;
  downloadEditEnabled: boolean;
  includedForBulkDelete: boolean;
  onBulkDeleteCheck: (id: string) => void;
}

const actionTypeToPreviewTitleMapping: Record<string, string> = {
  [ActionsEnum.Input]: 'Input',
  [ActionsEnum.Select]: 'Select',
  [ActionsEnum.SwitchTab]: 'Tab',
  [ActionsEnum.NewTab]: 'Tab',
  [ActionsEnum.PickFromList]: 'Selected option',
};

export function Action({
  action,
  onEditClick,
  onEditTarget,
  onMoveAction,
  onDeleteAction,
  onAddAction,
  allowDeleteAction,
  onUpdateActionOptions,
  variablesMap,
  globalVariablesMap,
  i,
  imageNodeEventChannel,
  isDragging = false,
  showManualHandleOption,
  targets,
  children,
  downloadEditEnabled,
  includedForBulkDelete,
  onBulkDeleteCheck,
}: Props) {
  const {
    actionType,
    options: {
      hidden,
      hitl,
      sitl,
      captcha,
      reCaptcha,
      mfa,
      adminOnly,
      adminManual,
      download,
      terminal,
      adminSkipped,
      nonBlocking,
    } = {},
    title,
    description = '',
    variableId = '',
    targetId = '',
    criteria,
    keyType,
  } = action;
  const { addVariable, addTarget } = EditorStore(
    useShallow((state) => ({
      addVariable: state.addVariable,
      addTarget: state.addTarget,
    })),
  );
  const isArbitraryAction = actionType === ActionsEnum.Arbitrary;
  const isInputAction = actionType === ActionsEnum.Input;
  const isDownloadAction = Boolean(download);
  const isMagicLoopAction = actionType === ActionsEnum.MagicLoop;
  const isRefreshAction = actionType === ActionsEnum.Refresh;
  const variable = variableId
    ? variablesMap[variableId] ?? globalVariablesMap[variableId]
    : null;
  const target = targets[targetId];

  const { name } = variable ?? {};
  const [selectedTargetId, setSelectedTargetId] = useState<string | null>(null);
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [descriptionText, setDescriptionText] = useState<string>(description);
  const [titleText, setTitleText] = useState<string>(title ?? '');
  const [isEditingDescription, setIsEditingDescription] =
    useState<boolean>(false);

  const isReadonlyView = useReadonlyWorkflow();

  const highlightAnnotation = () => {
    if (targetId && !terminal) {
      imageNodeEventChannel.emit('onActionHover', targetId);
    }
  };

  const removeAnnotationHighlight = () => {
    imageNodeEventChannel.emit('onActionHover', null);
  };

  const updateTitle = () => {
    setIsEditingTitle(false);
    onUpdateActionOptions(
      { ...action, title: titleText },
      { ...action.options },
    );
  };

  const onCloneAction = (originalAction: WorkflowAction) => {
    const isTabAction = [ActionsEnum.SwitchTab, ActionsEnum.NewTab].includes(
      originalAction.actionType,
    );
    let newVariableId = originalAction.variableId;
    if (originalAction.variableId && !isTabAction) {
      const newVariable = shallowCloneVariable(
        variablesMap[originalAction.variableId],
        variablesMap,
      );
      addVariable(newVariable);
      newVariableId = newVariable.id;
    }
    let newTargetId = originalAction.targetId;
    if (originalAction.targetId && !isTabAction) {
      const newTarget = {
        ...targets[originalAction.targetId],
        id: uuid(),
      };
      addTarget(newTarget);
      newTargetId = newTarget.id;
    }
    const newAction = {
      ...originalAction,
      id: uuid(),
      title: originalAction.title
        ? `${originalAction.title} (Clone)`
        : undefined,
      variableId: newVariableId,
      targetId: newTargetId,
    };
    onAddAction?.(newAction);
  };

  const updateDescription = () => {
    setIsEditingDescription(false);
    if (action.description !== descriptionText) {
      onUpdateActionOptions(
        { ...action, description: descriptionText },
        { ...action.options },
      );
    }
  };

  const isDocumentScrape = useMemo(() => {
    if (
      variable?.type === VariableTypeEnum.Document &&
      variable.data.source === DocumentSourceEnum.Execution
    ) {
      return true;
    }
    return false;
  }, [variable]);

  const actionTitle = useMemo(() => {
    if (
      isDownloadAction ||
      actionType === ActionsEnum.Download ||
      (actionType === ActionsEnum.Click && Boolean(download)) ||
      isDocumentScrape
    ) {
      return `Download${variable?.name ? ` (${variable.name})` : ''}`;
    }

    if (actionType === ActionsEnum.MagicLoop) {
      let magicLoopActionTitle = terminal
        ? 'End Magic Loop'
        : 'Begin Magic Loop';
      if (TemplateVariable.safeParse(variable).success) {
        const magicLoopVariable = variable as TemplateVariable;
        const magicLoopVariableName = parseTemplateString({
          data: magicLoopVariable.data,
          variableMap: variablesMap,
        });
        if (
          magicLoopVariableName &&
          typeof magicLoopVariableName === 'string'
        ) {
          magicLoopActionTitle += `: ${magicLoopVariableName}`;
        }
      }

      return magicLoopActionTitle;
    }

    if (actionType === ActionsEnum.MultiChoice) {
      return 'Multiple Choice';
    }

    if (actionType === ActionsEnum.KeyPress) {
      return 'Press';
    }

    if (actionType === ActionsEnum.KeyUnpress) {
      return 'Release';
    }

    if (actionType === ActionsEnum.RightClick) {
      return 'Right Click';
    }

    if (actionType === ActionsEnum.Arbitrary) {
      return title;
    }

    if (actionType === ActionsEnum.UploadDocument) {
      return 'File upload';
    }

    if (actionType === ActionsEnum.SwitchTab) {
      return 'Switch tab';
    }

    if (actionType === ActionsEnum.NewTab) {
      return 'New tab';
    }

    if (actionType === ActionsEnum.PickFromList) {
      return 'Pick from list';
    }

    if (actionType === ActionsEnum.MultiSelect) {
      return 'Multi Select';
    }

    if (mfa) {
      return 'Two-factor authentication';
    }

    if (reCaptcha) {
      return 'ReCaptcha';
    }

    if (captcha) {
      return 'Captcha';
    }

    return actionType;
  }, [
    isDownloadAction,
    actionType,
    download,
    isDocumentScrape,
    mfa,
    reCaptcha,
    captcha,
    variable,
    terminal,
    variablesMap,
    title,
  ]);

  const MagicLoopIcon = terminal ? ArrowUpLeftIcon : ArrowDownLeftIcon;

  const allowInputEdit = !isInputAction || isAdmin || !sitl;

  const editAllowed =
    !isReadonlyView &&
    (actionType !== ActionsEnum.Click ||
      (isDownloadAction && downloadEditEnabled)) &&
    (actionType !== ActionsEnum.KeyPress || isAdmin) &&
    (actionType !== ActionsEnum.KeyUnpress || isAdmin) &&
    !mfa &&
    !captcha &&
    !hidden &&
    !hitl &&
    !isRefreshAction &&
    (!isMagicLoopAction || !terminal) &&
    (!isArbitraryAction || isAdmin) &&
    allowInputEdit &&
    Boolean(onEditClick);

  useEffect(() => {
    if (!terminal) {
      imageNodeEventChannel.on('onTargetHover', setSelectedTargetId);
    }
  }, [imageNodeEventChannel, terminal]);

  const showFileUploadAlert = useMemo(() => {
    if (
      actionType !== ActionsEnum.UploadDocument ||
      variable?.type !== VariableTypeEnum.Document
    )
      return false;

    if (variable.data.source === DocumentSourceEnum.AWS) {
      return !variable.data.s3Ref?.fileId;
    }
    return !variable.data.url?.filter((a) => Boolean(a)).length;
  }, [variable, actionType]);

  const showHITLInstructions = useMemo(() => {
    return (
      hitl &&
      showManualHandleOption &&
      (captcha ||
        [
          ActionsEnum.Click,
          ActionsEnum.Input,
          ActionsEnum.MultiChoice,
          ActionsEnum.MultiSelect,
          ActionsEnum.PickFromList,
          ActionsEnum.Select,
          ActionsEnum.UploadDocument,
        ].includes(action.actionType))
    );
  }, [action.actionType, captcha, hitl, showManualHandleOption]);

  return (
    <div
      className={clsx(
        'px-3 bg-gray-100 border rounded hover:border-primary-blue cursor-pointer hover:shadow-primary transition relative',
        {
          'py-5': !isAdmin,
          'pb-5 pt-8': isAdmin,
          'border-yellow-300 !bg-yellow-50': hitl,
          'opacity-50': hidden,
          'opacity-70': isReadonlyView,
          'border-gray-800': isDragging,
          'border-primary-blue shadow-primary': targetId === selectedTargetId,
          '!bg-[#103D61] !text-white': isMagicLoopAction,
        },
      )}
      onBlur={removeAnnotationHighlight}
      onClick={() => {
        if (editAllowed) {
          onEditClick?.(action);
        }
      }}
      onFocus={highlightAnnotation}
      onMouseLeave={removeAnnotationHighlight}
      onMouseOver={highlightAnnotation}
      role="presentation"
    >
      {isAdmin ? (
        <div className="absolute top-0 right-1">
          <Checkbox
            checked={includedForBulkDelete}
            color="secondary"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              onBulkDeleteCheck(action.id);
            }}
          />
        </div>
      ) : null}
      <div
        className={clsx('flex justify-between items-center', {
          pointer: isReadonlyView,
        })}
      >
        <div className="flex space-x-4 items-center">
          {isMagicLoopAction ? (
            <MagicLoopIcon className="!w-5 !h-5 text-white" />
          ) : (
            <span className="text-xs bg-gray-300 rounded-full h-6 w-6 flex justify-center items-center">
              {i}
            </span>
          )}

          <div className="flex flex-col">
            <div className="flex space-x-2 items-center">
              {isEditingTitle ? (
                <Input
                  inputProps={{ style: { fontSize: '0.75rem' } }}
                  onBlur={updateTitle}
                  onChange={setTitleText}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      updateTitle();
                    }
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  placeholder="Enter Title"
                  value={titleText}
                />
              ) : (
                <span
                  className="font-medium text-sm"
                  onClick={(e) => {
                    if (isAdmin) {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsEditingTitle(true);
                    }
                  }}
                  role="presentation"
                >
                  {actionTitle}
                </span>
              )}
              {keyType ? (
                <span className="text-sm">
                  "{startCase(lowerCase(keyType))}"
                </span>
              ) : null}
              {Boolean(hitl) || Boolean(sitl) ? (
                <InfoOutlined className="text-yellow-500 !w-4 !h-4" />
              ) : null}
            </div>

            {Boolean(hitl) || Boolean(sitl) ? (
              <span className="text-xs text-color-grey">
                {hitl ? 'Action manually handled' : 'Action handled by Sola'}
              </span>
            ) : null}
          </div>
        </div>

        <div className="flex space-x-4 items-center">
          {action.options?.captcha && isAdmin ? (
            <IconButton className="!p-0">
              <span className="text-xs text-color-grey">is ReCaptcha?</span>

              <Switch
                size="small"
                checked={action.options.reCaptcha}
                onChange={(_, newChecked) => {
                  onUpdateActionOptions(action, {
                    ...action.options,
                    reCaptcha: newChecked,
                  });
                }}
              />
            </IconButton>
          ) : null}

          {children}
          {adminOnly ? (
            <Chip
              color="secondary"
              label="Adm Only"
              size="small"
              sx={{ height: 20, fontSize: '.65rem' }}
              variant="outlined"
            />
          ) : null}
          {adminSkipped && isAdmin ? (
            <Chip
              color="primary"
              label="Pass Through"
              size="small"
              sx={{ height: 20, fontSize: '.65rem' }}
              variant="outlined"
            />
          ) : null}
          {adminManual && isAdmin ? (
            <Chip
              color="error"
              label="Adm Manual"
              size="small"
              sx={{ height: 20, fontSize: '.65rem' }}
              variant="outlined"
            />
          ) : null}
          {nonBlocking && isAdmin ? (
            <Chip
              color="primary"
              label="Continue on fail"
              size="small"
              sx={{ height: 20, fontSize: '.65rem' }}
              variant="outlined"
            />
          ) : null}
          {editAllowed ? (
            <IconButton
              className="!p-0"
              onClick={() => {
                onEditClick?.(action);
              }}
            >
              <EditIcon
                className={clsx('!w-5 !h-5', {
                  'text-black': !isMagicLoopAction,
                  'text-white': isMagicLoopAction,
                })}
              />
            </IconButton>
          ) : null}
          {!hitl && Boolean(hidden) ? (
            <Tooltip title="Hidden Action">
              <VisibilityOff className="!w-5 !h-5" />
            </Tooltip>
          ) : null}
          {hitl ? (
            <Tooltip title="Action handled manually">
              <Person className="!w-5 !h-5" />
            </Tooltip>
          ) : null}

          {actionType !== ActionsEnum.MagicLoop || isAdmin || !terminal ? (
            <ActionMenu
              action={action}
              allowDeleteAction={allowDeleteAction}
              isReadonlyView={Boolean(isReadonlyView)}
              onDeleteAction={onDeleteAction}
              onEditTarget={onEditTarget}
              onMoveAction={onMoveAction}
              onCloneAction={onCloneAction}
              onUpdateActionOptions={onUpdateActionOptions}
              showManualHandleOption={showManualHandleOption}
            />
          ) : null}
        </div>
      </div>

      {showHITLInstructions ? (
        <div
          className={clsx('!mt-4', {
            'pointer-events-none': isReadonlyView,
          })}
        >
          <Input
            disabled={isReadonlyView}
            classes={{ wrapper: 'flex flex-col mt-10 bg-[#ffffff]' }}
            floatingLabel
            label="Instructions"
            multiline
            onBlur={updateDescription}
            onChange={setDescriptionText}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                updateDescription();
              }
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            placeholder="Describe how to handle the action during human in the loop"
            rows={5}
            value={descriptionText}
          />
        </div>
      ) : null}

      {(actionType !== ActionsEnum.Click &&
        actionType !== ActionsEnum.RightClick &&
        actionType !== ActionsEnum.KeyPress &&
        actionType !== ActionsEnum.KeyUnpress &&
        !isMagicLoopAction &&
        !isArbitraryAction &&
        !isRefreshAction &&
        !(actionType === ActionsEnum.Download || isDownloadAction) &&
        !mfa &&
        !captcha &&
        (!isInputAction || isAdmin || (!sitl && !hitl))) ||
      actionType === ActionsEnum.Download ||
      isDownloadAction ||
      isDocumentScrape ? (
        <>
          {showFileUploadAlert ? (
            <div className="mt-8">
              <Alert severity="info" variant="outlined">
                You must add a file to execute the workflow
              </Alert>
            </div>
          ) : (
            <>
              {isDocumentScrape ? (
                <div className="mt-8 rounded-lg flex text-sm">
                  <span className="p-3 text-color-grey">Value</span>
                  <ActionRenderer
                    actionType={actionType}
                    action={action}
                    criteria={criteria}
                    label={name}
                    variable={variable}
                    variablesMap={variablesMap}
                    globalVariablesMap={globalVariablesMap}
                    target={target}
                  />
                </div>
              ) : (
                <div
                  className={clsx(
                    'mt-8 rounded-lg flex text-xs',
                    'border border-gray-300',
                  )}
                >
                  <span
                    className={clsx(
                      'p-3 flex items-center',
                      'border-r border-gray-300',
                    )}
                  >
                    {title ??
                      (actionTypeToPreviewTitleMapping[actionType] || 'Value')}
                  </span>

                  {criteria === ActionValueCriteriaEnum.Condition ? (
                    <Chip
                      className="my-auto ml-3"
                      color="primary"
                      label={
                        <div className="flex items-center">
                          Determined by conditionals
                          <ConditionalIcon className="!w-4 !ml-1" />
                        </div>
                      }
                      size="small"
                    />
                  ) : (
                    <ActionRenderer
                      actionType={actionType}
                      action={action}
                      criteria={criteria}
                      label={name}
                      variable={variable}
                      variablesMap={variablesMap}
                      globalVariablesMap={globalVariablesMap}
                      target={target}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : null}

      {(Boolean(mfa) || isArbitraryAction) &&
      description &&
      !isEditingDescription ? (
        <p
          className={clsx('!mt-4 text-xs text-color-grey', {
            'pointer-events-none': isReadonlyView,
          })}
          onClick={(e) => {
            if (isAdmin) {
              e.preventDefault();
              e.stopPropagation();
              setIsEditingDescription(true);
            }
          }}
          role="presentation"
        >
          {description}
        </p>
      ) : null}

      {(isEditingDescription || !description) &&
      isAdmin &&
      (Boolean(mfa) || isArbitraryAction) ? (
        <div
          className={clsx('!mt-4', {
            'pointer-events-none': isReadonlyView,
          })}
        >
          <Input
            floatingLabel
            inputProps={{ style: { fontSize: '0.75rem' } }}
            multiline
            onBlur={updateDescription}
            onChange={setDescriptionText}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                updateDescription();
              }
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            placeholder="Enter Description"
            rows={2}
            value={descriptionText}
          />
        </div>
      ) : null}
    </div>
  );
}
