"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardActionSerializer = void 0;
const workflowTypes_1 = require("types-shared/workflowTypes");
const uuid_1 = require("uuid");
const types_shared_1 = require("types-shared");
class KeyboardActionSerializer {
    constructor(shortcutGroup, configs) {
        var _a, _b;
        this.shortcutGroup = shortcutGroup;
        this.configs = configs;
        this._adminOnly = (_b = (_a = configs === null || configs === void 0 ? void 0 : configs.leagcyOptions) === null || _a === void 0 ? void 0 : _a.adminOnly) !== null && _b !== void 0 ? _b : true;
    }
    serialize(shortcut, keySequence) {
        var _a, _b;
        const id = (0, uuid_1.v4)();
        const prefixSeq = (_a = this.configs) === null || _a === void 0 ? void 0 : _a.prefixSeq;
        return {
            id,
            actionVersion: '2.0',
            actionType: types_shared_1.ActionsEnum.KeyboardShortcut,
            shortcutType: shortcut,
            shortcutGroup: this.shortcutGroup,
            parameters: {
                keySequence: prefixSeq ? [...prefixSeq, ...keySequence] : keySequence,
                platform: workflowTypes_1.ActionPlatformEnum.Windows,
            },
            // Legacy compatibility
            title: shortcut,
            options: Object.assign({ adminOnly: this._adminOnly }, (_b = this.configs) === null || _b === void 0 ? void 0 : _b.leagcyOptions),
        };
    }
}
exports.KeyboardActionSerializer = KeyboardActionSerializer;
