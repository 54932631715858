import {
  LaunchIcon,
  Delete,
  IconButton,
  EditPencilIcon,
  Flex,
  Chip,
  modalEventChannel,
} from 'ui-kit';
import React, { useMemo } from 'react';
import {
  NodeTypesEnum,
  SourceTypeEnum,
  type SourceVariable,
  type WorkflowNode,
  WorkflowConditionalNode,
  WorkflowRetryNode,
} from 'types-shared';
import {
  getRequestNodeTitle,
  getTriggerBlockShortTitle,
  processEmailSender,
  type ScreenshotUrl,
} from '../utils';
import { isAdmin } from '../../../utils/env';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { type GetExecutionResponse } from 'api-types-shared';
import { isNull } from 'lodash';

export default function ExecutionScreenshotDetails({
  hasError,
  currentNode,
  sourceVariable,
  receiverEmail,
  setShowUpdateErrModal,
  onDeleteScreenshot,
  workflowId,
  selectedScreenshot,
  loading,
  selectedIndex,
  screenshotUrlsLength,
  canBulkDeleteScreenshots,
  onBulkDeleteScreenshots,
  deletingScreenshots,
  bulkDeleteScreenshotsCount,
}: {
  hasError: boolean;
  currentNode: WorkflowNode | undefined;
  completedStepsLength: number;
  sourceVariable: SourceVariable | undefined;
  receiverEmail: string | undefined;
  screenshotUrlsLength: number;
  selectedIndex: number | undefined;
  setShowUpdateErrModal: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteScreenshot?: (
    id: string,
    isImage: boolean,
    item: ScreenshotUrl,
  ) => void;
  selectedScreenshot?: ScreenshotUrl;
  workflowId?: string;
  loading?: boolean;
  canBulkDeleteScreenshots: boolean;
  onBulkDeleteScreenshots: () => void;
  deletingScreenshots: boolean;
  bulkDeleteScreenshotsCount: number;
  executionDetail?: GetExecutionResponse;
}) {
  const handleConfirmBulkDelete = () => {
    modalEventChannel.emit('open', {
      title: 'Confirm bulk delete',
      descriptions: [
        `Are you sure you want to delete ${bulkDeleteScreenshotsCount.toString()} screenshots?`,
      ],
      actions: [
        {
          text: 'Yes',
          variant: 'contained',
          onClick: () => {
            modalEventChannel.emit('close');
            onBulkDeleteScreenshots();
          },
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          onClick: () => {
            modalEventChannel.emit('close');
          },
        },
      ],
    });
  };
  const { nodeType, type, sortDataName, nodeId } = useMemo(() => {
    const payload: {
      nodeType?: ScreenshotUrl['nodeType'];
      type?: ScreenshotUrl['type'];
      sortDataName?: string;
      nodeId?: string;
    } = {};
    if (!selectedScreenshot?.sortData) return payload;

    payload.nodeType = selectedScreenshot.nodeType;
    payload.type = selectedScreenshot.type;
    payload.sortDataName =
      selectedScreenshot.sortData.name ?? selectedScreenshot.sortData.nodeId;
    payload.nodeId = selectedScreenshot.sortData.nodeId;

    return payload;
  }, [selectedScreenshot]);

  const timeBetweenRetries = useMemo(() => {
    const check = WorkflowRetryNode.safeParse(currentNode);
    if (!check.success) return null;

    const retryTime = check.data.data.retryInterval ?? 0;

    // Calculate total time between retries
    const totalRetryMs = retryTime * 60 * 1000;
    const retryDays = Math.floor(totalRetryMs / (24 * 60 * 60 * 1000));
    const retryRemainingAfterDays = totalRetryMs % (24 * 60 * 60 * 1000);
    const retryHours = Math.floor(retryRemainingAfterDays / (60 * 60 * 1000));
    const retryMinutes = Math.floor(
      (retryRemainingAfterDays % (60 * 60 * 1000)) / (60 * 1000),
    );

    const timeBetweenRetriesObj = {
      days: '',
      hours: '',
      minutes: '',
    };

    if (retryDays) {
      timeBetweenRetriesObj.days =
        retryDays === 1 ? '1 day ' : `${String(retryDays)} days, `;
    }
    if (retryHours) {
      timeBetweenRetriesObj.hours =
        retryHours === 1 ? '1 hour ' : `${String(retryHours)} hours, `;
    }
    if (retryMinutes) {
      timeBetweenRetriesObj.minutes =
        retryMinutes === 1 ? '1 minute' : `${String(retryMinutes)} minutes`;
    }

    return timeBetweenRetriesObj;
  }, [currentNode]);

  const nodeHeaderDetails = useMemo(() => {
    if (!currentNode) return null;

    switch (currentNode.type) {
      case NodeTypesEnum.Source:
        return sourceVariable?.data.sourceType ===
          SourceTypeEnum.EmailTrigger ? (
          <>
            <HeaderRow title="Type" value="Trigger" />
            <HeaderRow
              title="Name"
              value={getTriggerBlockShortTitle(sourceVariable)}
            />
            {receiverEmail ? (
              <HeaderRow
                title="Receiver email"
                value={processEmailSender(receiverEmail)}
              />
            ) : null}
          </>
        ) : null;

      case NodeTypesEnum.Request:
        return (
          <>
            <HeaderRow title="Type" value={getRequestNodeTitle(currentNode)} />
            <HeaderRow title="Name" value={currentNode.name} />
            <HeaderRow
              title="Description"
              value={currentNode.data.description}
            />
          </>
        );

      case NodeTypesEnum.Freeform:
        return (
          <>
            <HeaderRow title="Type" value="Freeform Step" />
            <HeaderRow title="Name" value={currentNode.name} />
          </>
        );
      case NodeTypesEnum.Email:
        return (
          <>
            <HeaderRow title="Type" value="Email integration" />
            <HeaderRow title="Name" value={currentNode.name} />
          </>
        );
      case NodeTypesEnum.Conditional:
        return WorkflowConditionalNode.safeParse(currentNode).success ? (
          <>
            <HeaderRow
              title="Type"
              value={
                currentNode.errorOverlay ? 'Error Handler' : 'Conditional Step'
              }
            />
            <HeaderRow title="Name" value={currentNode.name} />
          </>
        ) : null;

      case NodeTypesEnum.Retry:
        return (
          <>
            <HeaderRow title="Type" value="Retry step" />
            {!isNull(timeBetweenRetries) ? (
              <HeaderRow
                title="Time between retries"
                value={`${timeBetweenRetries.days}${timeBetweenRetries.hours}${timeBetweenRetries.minutes}`}
              />
            ) : null}
            <HeaderRow
              title="Maximum retry count"
              value={String(currentNode.data.maxAttempts)}
            />
          </>
        );
      default:
        return null;
    }
  }, [currentNode, receiverEmail, sourceVariable, timeBetweenRetries]);

  return (
    <div className="flex flex-col space-y-2 p-9">
      <Flex alignItems="center" justifyContent="space-between">
        <Link
          to={`/editor/${workflowId ?? ''}${nodeType === NodeTypesEnum.Image ? `?focusNode=${nodeId ?? ''}` : ''}`}
          target="_blank"
          className="flex items-center space-x-2"
        >
          <p className="text-xl font-medium">
            Step{' '}
            {nodeId ? (
              <span className="text-base ml-1">{nodeId.slice(0, 5)}</span>
            ) : null}
          </p>
          {!loading ? <LaunchIcon className="!w-5 !h-5" /> : null}
          {currentNode?.hideFromUser && isAdmin ? (
            <Chip
              color="primary"
              label="Hidden"
              size="small"
              sx={{ height: 20, fontSize: '.65rem' }}
              variant="outlined"
            />
          ) : null}
        </Link>

        <div className="flex items-center space-x-3">
          {!loading &&
          isAdmin &&
          (selectedIndex === screenshotUrlsLength - 1 ||
            selectedIndex === -1) ? (
            <IconButton
              className="!p-0"
              onClick={() => {
                setShowUpdateErrModal(true);
              }}
            >
              <div className="flex space-x-2">
                <span className="text-red-900 text-sm font-medium">
                  {hasError ? 'Update' : 'Add'} Error
                </span>

                <EditPencilIcon className="!w-5 !h-5 text-red-900" />
              </div>
            </IconButton>
          ) : null}
          {isAdmin && !loading ? (
            <>
              {deletingScreenshots ? (
                <div>
                  <CircularProgress
                    size={20}
                    thickness={5}
                    color="secondary"
                    variant="indeterminate"
                  />
                </div>
              ) : (
                <IconButton
                  className="!p-0"
                  onClick={() => {
                    if (canBulkDeleteScreenshots) {
                      if (bulkDeleteScreenshotsCount > 3) {
                        handleConfirmBulkDelete();
                      } else {
                        onBulkDeleteScreenshots();
                      }
                    } else if (
                      onDeleteScreenshot &&
                      sortDataName &&
                      selectedScreenshot
                    ) {
                      onDeleteScreenshot(
                        sortDataName,
                        type === 'blob',
                        selectedScreenshot,
                      );
                    }
                  }}
                >
                  <Delete className="!w-5 !h-5 !text-red-500" />
                </IconButton>
              )}
            </>
          ) : null}
        </div>
      </Flex>
      {nodeHeaderDetails}
    </div>
  );
}

export function HeaderRow({ title, value }: { title: string; value?: string }) {
  if (!value) return null;
  return (
    <p className="text-sm font-medium">
      <span>{title}:</span> &nbsp;
      <span className="text-slate-500">{value}</span>
    </p>
  );
}
