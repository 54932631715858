import { type ExecutionVariables } from 'types-shared';
import { DataLoader, Flex, Button, CustomDownloadIcon } from 'ui-kit';
import { useMemo, useCallback } from 'react';
import type { ExecutionDocument, GetExecutionResponse } from 'api-types-shared';
import { useParams } from 'react-router-dom';
import OutputsTable from './OutputsTable';
import { type OutputItem } from './OutputsTable/Description';
import { getInputItemsFromExecutionArtifacts } from '../utils';
import type {
  QueryObserverResult,
  RefetchOptions,
} from '@tanstack/react-query';
import { clsx } from 'clsx';

interface Props {
  downloadZippedOutput: (
    executionId: string,
    outputs: OutputItem[],
    isInput?: boolean,
  ) => Promise<void>;
  downloadLinkData: (url: string) => void;
  downloadZippedOutputStatus: 'error' | 'success' | 'pending' | 'idle';
  executionVariables?: ExecutionVariables;
  artifacts: ExecutionDocument[];
  artifactsLoading: boolean;
  fetchExecutionArtifacts: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<GetExecutionResponse>>;
  outputDetailsExportButtonEnabled: boolean;
  variableMetadata?: Record<string, string>;
}

export function RecordInputVariables({
  downloadZippedOutput,
  executionVariables,
  artifactsLoading,
  downloadLinkData,
  variableMetadata,
  fetchExecutionArtifacts,
  artifacts,
  outputDetailsExportButtonEnabled,
  downloadZippedOutputStatus,
}: Props) {
  const { executionId } = useParams();
  const isDownloadingZippedOutput = downloadZippedOutputStatus === 'pending';

  if (!executionId) {
    throw Error('executionId not found!');
  }

  const onDownloadLinkData = useCallback(
    (uri: string) => {
      downloadLinkData(uri);
    },
    [downloadLinkData],
  );

  const handleDownloadZippedOutput = useCallback(async () => {
    const { data: refreshedExecutionDetail } = await fetchExecutionArtifacts();
    if (refreshedExecutionDetail) {
      const newOutputItems = getInputItemsFromExecutionArtifacts(
        {
          variableMetadata: refreshedExecutionDetail.metadata.variableData,
          executionVariables: refreshedExecutionDetail.variableData,
          artifacts: refreshedExecutionDetail.inputFiles,
        },
        onDownloadLinkData,
      );
      void downloadZippedOutput(executionId, newOutputItems, true);
    }
  }, [
    fetchExecutionArtifacts,
    onDownloadLinkData,
    downloadZippedOutput,
    executionId,
  ]);

  const outputItems: OutputItem[] = useMemo(() => {
    return getInputItemsFromExecutionArtifacts(
      { artifacts, executionVariables, variableMetadata },
      onDownloadLinkData,
    );
  }, [executionVariables, artifacts, onDownloadLinkData, variableMetadata]);

  return artifactsLoading ? (
    <DataLoader />
  ) : (
    <div>
      <Flex alignItems="center" className="my-8" justifyContent="space-between">
        <div>
          <h2 className="text-cyan-900 text-2xl font-medium leading-9 tracking-tight">
            Inputs
          </h2>
          <span className="text-slate-500 text-sm leading-normal">
            Review values for the workflow variable inputs.
          </span>
        </div>

        <div>
          <Button
            className="!border-none"
            color="secondary"
            loading={isDownloadingZippedOutput}
            disabled={
              artifacts.length === 0 || !outputDetailsExportButtonEnabled
            }
            onClick={handleDownloadZippedOutput}
            variant="outlined"
          >
            {!isDownloadingZippedOutput ? (
              <CustomDownloadIcon
                fontSize="small"
                className={clsx({
                  'text-gray-350 mr-1':
                    artifacts.length === 0 || !outputDetailsExportButtonEnabled,
                  'text-info mr-1': !(
                    artifacts.length === 0 || !outputDetailsExportButtonEnabled
                  ),
                })}
              />
            ) : null}
            Export all documents
          </Button>
        </div>
      </Flex>

      <OutputsTable
        executionId={executionId}
        outputItems={outputItems}
        loading={artifactsLoading}
        isOutput={false}
        fetchExecutionArtifacts={fetchExecutionArtifacts}
        variables={[]}
      />
    </div>
  );
}
