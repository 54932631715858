"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TYPABLE_KEYS = exports.SPECIAL_KEYS = exports.ALT = exports.CONTROL = exports.SHIFT = exports.META = exports.MOCK_USER_ID = void 0;
exports.MOCK_USER_ID = 'google-oauth2|109435487003362441242';
exports.META = 'Meta';
exports.SHIFT = 'Shift';
exports.CONTROL = 'Control';
exports.ALT = 'Alt';
exports.SPECIAL_KEYS = [
    'Enter',
    'Backspace',
    'Delete',
    'Escape',
    'Tab',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    exports.META,
    exports.SHIFT,
    exports.CONTROL,
    exports.ALT,
    'PageUp',
    'PageDown',
    'F1',
    'F2',
    'F3',
    'F4',
    'F5',
    'F6',
    'F7',
    'F8',
    'F9',
    'F10',
    'F11',
    'F12',
];
exports.TYPABLE_KEYS = [
    // Numbers
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    // Lowercase letters
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    // Punctuation and symbols
    '-',
    '=',
    '[',
    ']',
    '\\',
    ';',
    "'",
    ',',
    '.',
    '/',
    '`',
    ' ',
];
