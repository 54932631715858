import {
  Add,
  AlertVariant,
  Button,
  DeleteOutlineIcon,
  GrayedOutInput,
  Input,
  Select,
  WarningAmberOutlined,
  notify,
} from 'ui-kit';
import SectionLabel from '../../Editor/components/EditNodePanel/SectionLabel';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  type GlobalVariable,
  type WorkflowWebhook,
} from 'types-shared/workflowTypes';
import { useUpsertWorkflowWebhook } from '../hooks';
import isNil from 'lodash/isNil';
import {
  Action,
  actions,
  convertFormValuesToWebhook,
  convertWebhookToFormValues,
  defaultForm,
  Event,
  events,
  type FormValues,
} from '../helper';
import isEqual from 'lodash/isEqual';
import { FormVariableInput } from './Input';
import { useNavigate } from 'react-router-dom';

interface SettingsFormProps {
  globalVariablesMap: Record<string, GlobalVariable>;
  workflowId: string;
  webhooks: WorkflowWebhook[];
}

export default function SettingsForm({
  globalVariablesMap,
  workflowId,
  webhooks,
}: SettingsFormProps) {
  const [settings, setSettings] = useState<FormValues>(defaultForm);
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    mutate: upsertWebhook,
    mutateAsync: upsertWebhookAsync,
    status,
  } = useUpsertWorkflowWebhook();
  const loading = status === 'pending';
  const navigate = useNavigate();

  // eslint-disable-next-line
  console.log('globalVariablesRaw', settings.headers);
  const lastWebhook = useMemo(() => webhooks[webhooks.length - 1], [webhooks]);

  const lastWebhookFormValues = useMemo(() => {
    if (isNil(lastWebhook)) {
      return defaultForm;
    }
    return convertWebhookToFormValues(lastWebhook);
  }, [lastWebhook]);

  const allowSave =
    settings.url.length > 0 && settings.event && settings.action;

  const onSave = () => {
    upsertWebhook({
      workflowId,
      webhooks: [convertFormValuesToWebhook(settings)],
    });
  };

  const onDelete = async () => {
    try {
      setIsDeleting(true);
      await upsertWebhookAsync({
        workflowId,
        webhooks: webhooks.filter((wh) => wh.id !== lastWebhook.id),
      });
      setIsDeleting(false);

      // Wait for the notification to be shown
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      notify({
        message: 'Error while deleting webhook.',
        variant: AlertVariant.WARNING,
      });
      setIsDeleting(false);
    }
  };

  const onCancel = () => {
    setSettings(lastWebhookFormValues);
    navigate(`/editor/${workflowId}`, { replace: true });
  };

  useEffect(() => {
    if (
      isEqual(settings, defaultForm) &&
      !isEqual(lastWebhookFormValues, defaultForm)
    ) {
      setSettings(lastWebhookFormValues);
    }
  }, [settings, lastWebhookFormValues]);

  return (
    <div className="flex flex-col space-y-8 w-1/2 min-w-100 xl:w-2/5">
      <h2 className="font-medium text-lg">
        Configure a webhook for a workflow event
      </h2>
      <p className="text-sm text-[#7A859C] !mt-2">
        Set up a general webhook to track common workflow events, including
        successful completions and failures.
      </p>
      <div className="flex flex-col space-y-6">
        <Input
          label="Event Name"
          floatingLabel
          placeholder="Email notification"
          value={settings.eventName}
          onChange={(val) => {
            setSettings({ ...settings, eventName: val });
          }}
        />
        <Select
          label="Event"
          options={events}
          getLabel={(opt) => opt}
          getValue={(opt) => opt}
          getIsDisabled={(opt) =>
            (opt as Event) === Event.ExecutionStatusChange
          }
          placeholder="Select event"
          value={settings.event}
          onChange={(e) => {
            setSettings({ ...settings, event: e.target.value });
          }}
        />
        <Select
          label="Action"
          options={actions}
          getLabel={(opt) => opt}
          getValue={(opt) => opt}
          getIsDisabled={(opt) => (opt as Action) === Action.SendNotification}
          placeholder="Select action"
          value={settings.action}
          onChange={(e) => {
            setSettings({ ...settings, action: e.target.value });
          }}
        />
        <SectionLabel title="Request settings" />
        <GrayedOutInput
          className="!px-3 !rounded-tl-md !rounded-tr-md"
          label="Method"
          value="POST"
        />
        <FormVariableInput
          allowAddVariable={false}
          value={settings.url}
          onChange={(newData) => {
            setSettings((_settings) => ({
              ..._settings,
              url: newData,
            }));
          }}
          variablesMap={{}}
          globalVariablesMap={globalVariablesMap}
          placeholder="Endpoint URL"
          multiline={false}
        />
        <SectionLabel title="Headers" />

        {settings.headers.map(({ id, key, value }) => {
          return (
            <div className="flex items-center w-100 justify-between" key={id}>
              <FormVariableInput
                allowAddVariable={false}
                isHalf
                value={key}
                onChange={(newData) => {
                  setSettings((_settings) => ({
                    ..._settings,
                    headers: _settings.headers.map((h) =>
                      h.id === id ? { ...h, key: newData } : h,
                    ),
                  }));
                }}
                variablesMap={{}}
                globalVariablesMap={globalVariablesMap}
                placeholder="Enter key"
                multiline={false}
              />
              <FormVariableInput
                allowAddVariable={false}
                isHalf
                value={value}
                onChange={(newData) => {
                  setSettings((_settings) => ({
                    ..._settings,
                    headers: _settings.headers.map((h) =>
                      h.id === id ? { ...h, value: newData } : h,
                    ),
                  }));
                }}
                variablesMap={{}}
                globalVariablesMap={globalVariablesMap}
                placeholder="Enter value"
                multiline={false}
              />
              <DeleteOutlineIcon
                className="hover:text-red-500 cursor-pointer"
                onClick={() => {
                  setSettings({
                    ...settings,
                    headers: settings.headers.filter((h) => h.id !== id),
                  });
                }}
              />
            </div>
          );
        })}
        <Button
          className="!max-w-min"
          color="secondary"
          onClick={() => {
            setSettings({
              ...settings,
              headers: [
                ...settings.headers,
                { id: uuid(), key: [], value: [] },
              ],
            });
          }}
          variant="outlined"
        >
          <Add />
        </Button>
        <SectionLabel title="Body" />
        <div className="w-full bg-[#FFF4E5] flex space-x-2 px-4 py-3 rounded">
          <WarningAmberOutlined className="!w-5 !h-5 !text-[#EF6C00] !mt-0.5" />
          <span className="text-sm text-[#663C00]">
            The body is not currently customizable for workflow-level settings.
            Please see the sample webhook on the right to understand what values
            are passed to the endpoint upon the workflow event occurring.
          </span>
        </div>
        <div className="flex items-center space-x-4 !mt-10">
          <Button
            color="secondary"
            disabled={!allowSave || loading}
            variant="contained"
            onClick={onSave}
          >
            {loading && !isDeleting ? 'Saving...' : 'Save Changes'}
          </Button>
          <Button
            color="secondary"
            disabled={loading}
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            disabled={loading || !lastWebhook}
            variant="outlined"
            onClick={onDelete}
          >
            {loading && isDeleting ? 'Deleting...' : 'Delete event'}
          </Button>
        </div>
      </div>
    </div>
  );
}
