"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exists = exports.isNotUndefined = exports.isNotNull = exports.mapN = void 0;
/**
 * Applies a function to a list of null/undefined values, unwrapping the null/undefined value or passing it through.
 */
var mapN = function (fn) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (!args.every(function (arg) { return arg !== undefined; })) {
        return undefined;
    }
    if (!args.every(function (arg) { return arg !== null; })) {
        return null;
    }
    return fn.apply(void 0, args);
};
exports.mapN = mapN;
/**
 * Checks to see if the provided value is not null.
 *
 * Useful for preserving types in filtering out non-null values.
 *
 * @param value - value that can be null
 * @returns
 */
var isNotNull = function (value) {
    return value !== null;
};
exports.isNotNull = isNotNull;
/**
 * Checks to see if the provided value is not undefined.
 *
 * @param value - value that can be undefined
 * @returns
 */
var isNotUndefined = function (value) {
    return value !== undefined;
};
exports.isNotUndefined = isNotUndefined;
/**
 * Checks to see if the provided value is not null or undefined.
 *
 * @param value - value that can be null or undefined
 * @returns
 */
var exists = function (value) {
    return value !== null && value !== undefined;
};
exports.exists = exists;
