import { useState } from 'react';
import {
  type NodeData,
  NodeTypesEnum,
  type WorkflowAction,
  WorkflowImageNode,
} from 'types-shared';
import { EditorStore } from '../../../store/EditorState';
import { Input, Button } from 'ui-kit';

export function ArbitraryAction({
  action,
  node,
  onSaveAndExit,
}: {
  action: WorkflowAction;
  node: WorkflowImageNode;
  onSaveAndExit: () => void;
}) {
  const editorData = EditorStore();
  const { nodes, setNodes } = editorData;

  const [data, setData] = useState<{ title?: string; description?: string }>({
    title: action.title,
    description: action.description,
  });

  const updateNodeData = (updateData: Partial<NodeData>) => {
    setNodes(
      nodes.map((_node) => {
        if (_node.type !== NodeTypesEnum.Image) return _node;
        const updateNode = WorkflowImageNode.parse(_node);
        if (updateNode.id === node.id) {
          return {
            ...updateNode,
            data: {
              ...updateNode.data,
              ...updateData,
            },
          };
        }
        return updateNode;
      }),
    );
  };

  const updateAction = () => {
    updateNodeData({
      actionData: {
        ...node.data.actionData,
        [action.id]: {
          ...action,
          title: data.title,
          description: data.description,
        },
      },
    });
  };

  const handleSaveAndExit = () => {
    updateAction();
    onSaveAndExit();
  };

  return (
    <div className="mt-10 flex flex-col gap-4 h-full justify-between">
      <div className="flex flex-col gap-4">
        <div>
          <Input
            floatingLabel
            label="Title"
            onChange={(e) => {
              setData({ ...data, title: e });
            }}
            placeholder="Enter Title"
            value={data.title}
          />
        </div>

        <div className="!mt-4">
          <Input
            floatingLabel
            label="Description"
            multiline
            onChange={(e) => {
              setData({ ...data, description: e });
            }}
            placeholder="Enter Description"
            rows={2}
            value={data.description}
          />
        </div>
      </div>

      <div className="w-full">
        <Button
          className="!text-info !border-info !mt-5 w-full"
          color="secondary"
          onClick={handleSaveAndExit}
          variant="outlined"
        >
          Save & Exit
        </Button>
      </div>
    </div>
  );
}
