"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addWorkflowScheduleSchema = void 0;
var types_shared_1 = require("types-shared");
exports.addWorkflowScheduleSchema = {
    request: types_shared_1.z.object({
        params: types_shared_1.z.object({
            workflowId: types_shared_1.z.string(),
        }),
        body: types_shared_1.z.object({
            cronExpressions: types_shared_1.z.array(types_shared_1.z.string()),
            variableData: types_shared_1.z.record(types_shared_1.z.string(), types_shared_1.z.coerce.string()).default({}),
            pauseOnFailure: types_shared_1.z.boolean().nullish(),
        }),
    }),
    response: types_shared_1.z.object({
        scheduleId: types_shared_1.z.string(),
    }),
};
