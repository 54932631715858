import { sendToBackgroundViaRelay } from '@plasmohq/messaging';
import * as Sentry from '@sentry/react';
import { type DashboardSyncResponse } from 'api-types-shared';
import { type PostHog, usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { SentryBrowser, defaultBrowserOptions } from 'sentry-browser-shared';
import { type User, useEnvironment } from 'ui-kit';
import { isAdmin } from '../utils/env';
import { useAuth } from '../utils/helper';

type UsePosthog = () => PostHog;

const usePosthog: UsePosthog = isAdmin ? () => ({}) as PostHog : usePostHog;

/**
 * Sets up analytics. Only call this file ONCE.
 */
export const useAnalytics = (): void => {
  const { selectedEnv } = useEnvironment();

  // Google Analytics
  // useEffect(() => {
  //   window.gtag?.('event', 'page_view', {
  //     page_path: location.pathname + location.search,
  //     page_location: location.key ?? window.location.href,
  //     page_title: document.title,
  //   });
  // }, [location.key, location.pathname, location.search]);
  const { user } = useAuth();

  const posthog = usePosthog();

  useEffect(() => {
    // TODO(michael): Set Sentry user to userId after auth
    Sentry.getCurrentScope().setUser(null);
  }, []);

  useEffect(() => {
    Sentry.setTag('environment', selectedEnv);
    Sentry.setUser({ ...user });
  }, [selectedEnv, user]);

  useEffect(() => {
    if (user) {
      posthog.identify(user.email, user);
      if (user.email) {
        posthog.group('domain', user.email.split('@')[1], {});
      }
      void syncDashboard(user);
    }
  }, [posthog, user]);

  useEffect(() => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      const sentryCfg = defaultBrowserOptions();
      const integrations = [...SentryBrowser.getDefaultIntegrations(sentryCfg)];

      // Only enable replays in production and for non-admin users
      if (process.env.NODE_ENV !== 'development' && !isAdmin) {
        integrations.push(
          Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
          }),
          Sentry.replayCanvasIntegration(),
        );
      }

      if (!isAdmin) {
        integrations.push(
          posthog.sentryIntegration({
            organization: 'sola-solutions',
            projectId: Number(sentryCfg.dsn?.split('/').pop() ?? 0), // The last part of the DSN is the project ID
          }),
        );
      }

      if (isAdmin || process.env.NODE_ENV === 'development') {
        const sentryFeedbackBtn = document.querySelector(
          '#sentry-feedback-btn',
        );
        if (sentryFeedbackBtn) {
          sentryFeedbackBtn.classList.add('!block');
          const feedback = Sentry.feedbackIntegration({
            autoInject: false,
          });
          integrations.push(feedback);
          feedback.attachTo(sentryFeedbackBtn, {
            formTitle: 'Report a Bug!',
          });
        }
      }

      Sentry.init({
        tracesSampleRate: 0.2,
        release: selectedEnv,
        replaysOnErrorSampleRate: 1.0,
        tunnel: isAdmin
          ? undefined
          : 'https://sentry.sola-solutions.com/tunnel',
        ...sentryCfg,
        integrations,
      });
    }
  }, [posthog, selectedEnv]);
};

const syncDashboard = async (user: User, maxAttempts = 10, timeout = 100) => {
  let res: DashboardSyncResponse = false;
  let attempts = 0;
  while (!res && attempts < maxAttempts) {
    res = await Promise.race<DashboardSyncResponse>([
      sendToBackgroundViaRelay({
        name: 'onDashboardSync.handler' as never,
        body: {
          userData: {
            id: user.email,
            name: user.name,
            email: user.email,
          },
          logout: false,
        },
      }),
      new Promise<DashboardSyncResponse>((resolve) => {
        setTimeout(() => {
          resolve(false);
        }, timeout);
      }),
    ]);
    if (!res) {
      await new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    }
    attempts++;
  }
};
