import { useState, useMemo } from 'react';
import {
  type WorkflowNode,
  type WorkflowEdge,
  NodeTypesEnum,
  type WorkflowAction,
} from 'types-shared/workflowTypes';
import { SignalTypeEnum } from 'api-types-shared';
import { Button, Select } from 'ui-kit';
import { getAllNodesAfter } from '../../Editor/utils/helper';
import { getActionTitle } from '../utils';
import values from 'lodash/values';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { autocompleteFilterOptions } from '../../../utils/constants';

interface Props {
  nodeId: string | undefined;
  nodes: WorkflowNode[];
  edges: WorkflowEdge[];
  onResume: (signal: SignalTypeEnum, payload?: object) => () => void;
  isLoading: boolean | undefined;
  signalNotAllowed: boolean;
}

interface Form {
  nextNodeId: string | undefined;
  nextActionId: string | undefined;
}

export default function BranchResume({
  nodes,
  edges,
  onResume,
  isLoading,
  signalNotAllowed,
}: Props) {
  const [form, setForm] = useState<Form>({
    nextNodeId: undefined,
    nextActionId: undefined,
  });

  const nodeIds = useMemo(() => {
    const node = nodes.find((n) => n.type === NodeTypesEnum.Source);
    if (!node) return nodes.map((n) => n.id);
    return getAllNodesAfter(node, nodes, edges);
  }, [nodes, edges]);

  const selectedNode = useMemo(() => {
    return nodes.find((n) => n.id === form.nextNodeId);
  }, [nodes, form.nextNodeId]);

  const {
    actionIds,
    actionTitleMapping,
  }: { actionIds: string[]; actionTitleMapping: Record<string, string> } =
    useMemo(() => {
      const node = nodes.find((n) => n.id === form.nextNodeId);
      if (!node || node.type !== NodeTypesEnum.Image) {
        return {
          actionIds: [],
          actionTitleMapping: {},
        };
      }
      const nodeData = node.data;
      const titleMapping = values(nodeData.actionData).reduce(
        (acc: Record<string, string>, action: WorkflowAction) => {
          acc[action.id] =
            `${getActionTitle(action)} - ${action.id.slice(0, 5)}`;
          return acc;
        },
        {},
      );
      return {
        actionIds: nodeData.actionOrder,
        actionTitleMapping: titleMapping,
      };
    }, [nodes, form.nextNodeId]);

  const handleSubmit = () => {
    onResume(SignalTypeEnum.Resume, {
      nextNodeId: form.nextNodeId,
      nextActionId: form.nextActionId,
    })();
  };

  return (
    <div className="space-x-2 mb-4 relative max-w-max ml-auto flex">
      <span className="absolute -left-36 top-1/2 -translate-y-1/3 font-medium">
        Resume at Action
      </span>
      <Autocomplete
        className="!mt-3 min-w-48 !max-w-48"
        classes={{ popper: 'px-sm' }}
        filterOptions={autocompleteFilterOptions}
        freeSolo
        onChange={(_, newValue) => {
          if (newValue) {
            setForm((prev) => ({ ...prev, nextNodeId: newValue }) as Form);
          }
        }}
        options={nodeIds}
        value={form.nextActionId}
        renderInput={(params) => (
          <TextField {...params} placeholder="Search for a node" />
        )}
      />

      <Select<string>
        label="Action"
        className="!min-w-48 !max-w-48 !mt-2"
        disabled={
          selectedNode?.type !== NodeTypesEnum.Image || signalNotAllowed
        }
        getLabel={(option) => actionTitleMapping[option]}
        getValue={(option) => option}
        onChange={(e) => {
          setForm((prev) => ({ ...prev, nextActionId: e.target.value }));
        }}
        options={actionIds}
        value={form.nextActionId}
      />

      <Button
        className="!mt-2"
        color="secondary"
        disabled={
          !form.nextNodeId ||
          (selectedNode?.type === NodeTypesEnum.Image && !form.nextActionId) ||
          signalNotAllowed
        }
        loading={isLoading}
        onClick={handleSubmit}
        variant="contained"
      >
        Submit
      </Button>
    </div>
  );
}
