import React, { type ReactNode } from 'react';
import MuiSelect, { type SelectProps } from '@mui/material/Select';
import Checkbox from '../Input/Checkbox';
import MenuItem from '../Menu/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Wrapper from '../Input/Wrapper';
import type { Classes } from '../Input/types';

interface ModifiedInputBaseProps {
  value: string | string[];
  doesInputHaveValue: (value: string | string[]) => boolean;
}

const ModifiedInputBase = styled(InputBase)<ModifiedInputBaseProps>(({
  theme,
  value,
  doesInputHaveValue,
}) => {
  const inputHasValue = doesInputHaveValue(value);

  return {
    '& .MuiInputBase-input': {
      border: `1px solid ${inputHasValue ? theme.palette.secondary.light : '#e6e7eb'}`,
      backgroundColor: inputHasValue ? '#f7f9ff' : '#ffffff',
      fontSize: 14,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      width: '9rem',
      padding: '1.25rem 1.625rem 0.875rem 0.75rem',
      height: 'auto',
      minHeight: '1.4em',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      userSelect: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      font: 'inherit',
      letterSpacing: 'inherit',
      animationDuration: '10ms',

      '&:focus': {
        borderRadius: 4,
        borderColor: 'primary.light',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  };
});

interface CheckboxSelectProps {
  options: string[];
  isChecked: (item: string) => boolean;
  getFilterTitle: (item: string) => string;
  classes?: Classes;
  label?: string | ReactNode;
  floatingLabel?: boolean;
  errorText?: string;
  showErrorText?: boolean;
  required?: boolean;
}

type ExtendedCheckboxSelectProps = CheckboxSelectProps &
  Pick<ModifiedInputBaseProps, 'doesInputHaveValue'>;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    sx: {
      '& .MuiTypography-body1': {
        fontSize: 14,
      },
    },
  },
  BackdropProps: {
    classes: { root: 'no-backdrop' },
  },
};

export default function CheckboxSelect(
  props: ExtendedCheckboxSelectProps & SelectProps,
) {
  const {
    options,
    isChecked,
    getFilterTitle,
    doesInputHaveValue,
    classes,
    label,
    floatingLabel,
    errorText,
    showErrorText,
    required,
    id,
    error,
    ...rest
  } = props;

  return (
    <Wrapper
      className={classes?.wrapper}
      error={error}
      errorText={errorText}
      floatingLabel={floatingLabel}
      id={id}
      label={label}
      required={required}
      showErrorText={showErrorText}
    >
      <MuiSelect
        {...rest}
        input={
          <ModifiedInputBase
            doesInputHaveValue={doesInputHaveValue}
            value={rest.value as string | string[]}
          />
        }
        style={{ fontSize: '14px' }}
        multiple
        MenuProps={MenuProps}
      >
        {options.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox
              color="secondary"
              checked={isChecked(item)}
              classes={{ root: 'mr-2' }}
            />
            <ListItemText
              primary={getFilterTitle(item)}
              style={{ fontSize: '14px' }}
            />
          </MenuItem>
        ))}
      </MuiSelect>
    </Wrapper>
  );
}
