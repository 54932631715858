import {
  type DatasourceMetadata,
  type DatasourceTable,
  type Variable,
  type VariableMap,
  type WorkflowEmailNode,
  type GlobalVariable,
} from 'types-shared';

interface CreateContactActionProps {
  variablesMap: VariableMap;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  updateCreateContactAction: (
    data: WorkflowEmailNode['data']['createContactDetails'],
  ) => void;
  createContactAction: WorkflowEmailNode['data']['createContactDetails'];
  updateVariable: (variable: Variable) => void;
  datasourceMetadata: DatasourceMetadata | null;
  tableData: DatasourceTable | null;
}

export default function SendEmailAction(
  {
    // variablesMap,
    // globalVariablesMap,
    // updateCreateContactAction,
    // createContactAction,
    // updateVariable,
    // datasourceMetadata,
  }: CreateContactActionProps,
) {
  return <div />;
}
