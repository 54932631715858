"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesktopKeyboardShortcutStepFactory = exports.parseKeyboardShortcutStep = void 0;
const replay_1 = require("@puppeteer/replay");
const steps_1 = require("../../../types/steps");
const types_1 = require("./types");
const parseKeyboardShortcutStep = (id, step) => {
    return {
        id,
        type: replay_1.StepType.CustomStep,
        name: steps_1.DESKTOP_CUSTOM_STEP_TYPES.keyboardShortcut,
        parameters: types_1.KeyboardShortcutParameters.parse(step.parameters),
    };
};
exports.parseKeyboardShortcutStep = parseKeyboardShortcutStep;
var factory_1 = require("./factory");
Object.defineProperty(exports, "DesktopKeyboardShortcutStepFactory", { enumerable: true, get: function () { return factory_1.DesktopKeyboardShortcutStepFactory; } });
__exportStar(require("./types"), exports);
