import React, { useMemo } from 'react';
import {
  Menu,
  MenuItem,
  ContentCopy,
  Delete,
  Edit,
  SpellcheckIcon,
  PlusOneIcon,
} from 'ui-kit';
import { workflowProcessingStatuses } from '../../../utils/constants';
import { isAdmin } from '../../../utils/env';

import { type WorkflowListMenuProps } from '../types';

// TODO Paul: Memoize this
export default function WorkflowListMenu({
  menuProps,
  editWorkflowEnabled,
  enableWorkflowCloning,
  onCloseMenu,
  onEditWorkflow,
  onDeleteWorkflow,
  onOpenDuplicateModal,
  onRenameWorkflow,
  onUpdateConcurrency,
}: {
  menuProps: WorkflowListMenuProps | null;
  editWorkflowEnabled: boolean;
  enableWorkflowCloning: boolean;
  onCloseMenu: () => void;
  onEditWorkflow: () => void;
  onDeleteWorkflow: () => void;
  onOpenDuplicateModal: () => void;
  onRenameWorkflow: () => void;
  onUpdateConcurrency: () => void;
}) {
  const { hideDuplicateWorkflow, hideEditWorkflow } = useMemo(() => {
    const payload = {
      hideDuplicateWorkflow: false,
      hideEditWorkflow: false,
    };
    if (!menuProps?.status || isAdmin) return payload;

    payload.hideEditWorkflow =
      workflowProcessingStatuses.processingAndInaccessible.includes(
        menuProps.status,
      );

    payload.hideDuplicateWorkflow =
      workflowProcessingStatuses.allProcessing.includes(menuProps.status);

    return payload;
  }, [menuProps?.status]);

  return (
    <Menu
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
      anchorEl={menuProps?.anchorEl ?? null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={onCloseMenu}
      open={Boolean(menuProps)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {editWorkflowEnabled && !hideEditWorkflow ? (
        <MenuItem className="group min-w-72 h-12" onClick={onEditWorkflow}>
          <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
            Edit workflow
          </span>
          <Edit className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
        </MenuItem>
      ) : null}
      {enableWorkflowCloning && !hideDuplicateWorkflow ? (
        <MenuItem
          className="group min-w-72 h-12"
          onClick={onOpenDuplicateModal}
        >
          <span className="font-normal leading-6 mr-4 group-hover:text-primary-blue">
            Duplicate Workflow
          </span>
          <ContentCopy className="!w-4 !h-4 !ml-auto group-hover:text-primary-blue" />
        </MenuItem>
      ) : null}
      {isAdmin ? (
        <MenuItem className="group min-w-72 h-12" onClick={onUpdateConcurrency}>
          <span className="font-normal leading-6 group-hover:text-primary-blue">
            Set Concurrency Limit
          </span>
          <PlusOneIcon className="!4-5 !h-4 !w-4 !ml-auto group-hover:text-primary-blue" />
        </MenuItem>
      ) : null}
      <MenuItem className="group min-w-72 h-12" onClick={onRenameWorkflow}>
        <span className="font-normal leading-6 group-hover:text-primary-blue">
          Rename Workflow
        </span>
        <SpellcheckIcon className="!4-5 !h-4 !w-4 !ml-auto group-hover:text-primary-blue" />
      </MenuItem>
      <MenuItem className="group min-w-72 h-12" onClick={onDeleteWorkflow}>
        <span className="font-normal leading-6 group-hover:text-primary-blue">
          Delete Workflow
        </span>
        <Delete className="!4-5 !h-4 !w-4 !ml-auto group-hover:text-primary-blue" />
      </MenuItem>
    </Menu>
  );
}
