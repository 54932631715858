import {
  type ActionMap,
  type ActionsEnum,
  type ExtensionData,
  TabVariable,
  type Target,
  type TargetMap,
  Variable,
  type VariableMap,
  type WorkflowAction,
  BrowserTabUpdateAction,
  ExtensionMultiSelectAction,
  type DocumentVariable,
  DocumentSourceEnum,
  ScrapeTypeEnum,
} from 'types-shared';
import {
  Actions,
  BrowserDownloadAction,
  BrowserInitialTabAction,
  BrowserNewTabAction,
  BrowserSwitchTabAction,
  ExtensionAction,
  ExtensionInputAction,
  ExtensionKeyPressAction,
  ExtensionMultiChoiceAction,
  ExtensionScrapeAction,
  ExtensionSelectAction,
  VariableType,
  handleZodCheck,
} from 'types-shared';
import { v4 as uuidv4 } from 'uuid';
import { calculateRelativeCoordinates } from './image';
import { createTarget } from '../../Editor/utils/helper';

// Define the structure of the return type of parseActions
export interface ParseActionsReturnType {
  variableStore: VariableMap;
  targetStore: TargetMap;
  actionDataArray: {
    actionData: ActionMap;
    nodeUrls: string[];
    actionOrder: string[];
  }[];
}

export const parseActions = (
  actions: ExtensionData['actions'],
  stateDims: { width: number; height: number }[],
): ParseActionsReturnType => {
  const variableStore: VariableMap = {};
  const targetStore: TargetMap = {};

  const flattenedActions = actions.flatMap((action, nodeIx) =>
    action.map((subAction) => ({ ...subAction, nodeIx })),
  );
  const outputActions: { action: WorkflowAction; nodeIx: number }[] = [];
  const tabMap: Record<
    string,
    Variable | { type: 'separate' | 'move' } | null
  > = {};
  let scrapeIx = 1;
  let currentTarget: string | null = null;

  for (const action of flattenedActions) {
    handleZodCheck(action, BrowserInitialTabAction, (_action) => {
      const { url, title } = _action.data;
      const newTabVariable: TabVariable = {
        id: uuidv4(),
        type: VariableType.enum.Tab,
        data: {
          url: [url ?? ''],
        },
        name: title ?? url,
      };
      tabMap[_action.data.id] = newTabVariable;

      const newAction: WorkflowAction = {
        actionType: Actions.enum.NewTab,
        id: uuidv4(),
        variableId: newTabVariable.id,
      };
      outputActions.push({ action: newAction, nodeIx: action.nodeIx });
    });

    handleZodCheck(action, BrowserNewTabAction, (_action) => {
      const { openerTabId, active, id } = _action.data;
      if (openerTabId) {
        if (active) {
          tabMap[id] = { type: 'move' };
        } else {
          tabMap[id] = { type: 'separate' };
        }
      } else {
        tabMap[_action.data.id] = null;
      }
    });

    handleZodCheck(action, BrowserSwitchTabAction, (_action) => {
      const { id, url, title } = _action.data;
      if (!url || url.includes('chrome://')) {
        return;
      }

      if (!(id in tabMap)) {
        const newTabVariable: TabVariable = {
          id: uuidv4(),
          type: VariableType.enum.Tab,
          name: title ?? url,
          data: {
            url: [url],
          },
        };
        tabMap[id] = newTabVariable;
        const newAction: WorkflowAction = {
          actionType: Actions.enum.NewTab,
          id: uuidv4(),
          variableId: newTabVariable.id,
        };
        outputActions.push({ action: newAction, nodeIx: action.nodeIx });
      } else {
        const tabVariableParse = Variable.safeParse(tabMap[id]);
        if (tabVariableParse.success) {
          const newAction: WorkflowAction = {
            actionType: Actions.enum.SwitchTab,
            id: uuidv4(),
            variableId: tabVariableParse.data.id,
          };
          outputActions.push({ action: newAction, nodeIx: action.nodeIx });
        }
      }
    });

    handleZodCheck(action, BrowserTabUpdateAction, (_action) => {
      const { url, id, title } = _action.data;
      if (!url || url.includes('chrome://')) {
        return;
      }

      const tabVariable: TabVariable = {
        id: uuidv4(),
        type: VariableType.enum.Tab,
        name: title ?? url,
        data: {
          url: [url],
        },
      };

      if (id in tabMap) {
        const prevVariable = Variable.safeParse(tabMap[id]);
        if (tabMap[id] === null) {
          tabMap[id] = tabVariable;
          const newAction: WorkflowAction = {
            actionType: Actions.enum.NewTab,
            id: uuidv4(),
            variableId: tabVariable.id,
          };
          outputActions.push({ action: newAction, nodeIx: action.nodeIx });
        } else if (prevVariable.success) {
          /* Variable's already been set for an initial tab */
        } else {
          const { type } = tabMap[id] as { type: 'separate' | 'move' };
          tabMap[id] = tabVariable;

          const newAction: WorkflowAction = {
            actionType:
              type === 'separate'
                ? Actions.enum.NewTab
                : Actions.enum.SwitchTab,
            id: uuidv4(),
            variableId: tabVariable.id,
            options: {
              submitted: true,
            },
          };
          outputActions.push({ action: newAction, nodeIx: action.nodeIx });
        }
      }
    });

    handleZodCheck(action, BrowserDownloadAction, (_action) => {
      // Find the last action that's a click
      let lastClickActionIndex = outputActions.length - 1;
      while (
        lastClickActionIndex >= 0 &&
        outputActions[lastClickActionIndex].action.actionType !==
          Actions.enum.Click
      ) {
        lastClickActionIndex--;
      }

      // If no click action is found, add a new click action
      if (lastClickActionIndex === -1) {
        const newTargetId = uuidv4();
        const newTarget = createTarget(newTargetId);
        targetStore[newTargetId] = newTarget;

        const newClickAction: WorkflowAction = {
          actionType: Actions.enum.Click,
          id: uuidv4(),
          variableId: uuidv4(),
          targetId: newTargetId,
        };
        outputActions.push({ action: newClickAction, nodeIx: action.nodeIx });
        lastClickActionIndex = outputActions.length - 1;
      }

      const lastClickAction = outputActions[lastClickActionIndex];
      const lastClickActionOptions = lastClickAction.action.options;
      outputActions[lastClickActionIndex].action.options = {
        ...lastClickActionOptions,
        download: [_action.data],
      };

      const prevVariableId = lastClickAction.action.variableId;
      const newDocumentVariable: DocumentVariable = {
        id: prevVariableId ?? uuidv4(),
        type: VariableType.enum.Document,
        data: {
          source: DocumentSourceEnum.Execution,
        },
      };
      if (prevVariableId) {
        variableStore[prevVariableId] = newDocumentVariable;
      } else {
        variableStore[newDocumentVariable.id] = newDocumentVariable;
        outputActions[lastClickActionIndex].action.variableId =
          newDocumentVariable.id;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-loop-func
    handleZodCheck(action, ExtensionAction, (extensionAction) => {
      const { target, id } = extensionAction;
      const relativeCoordinates = calculateRelativeCoordinates(
        stateDims[action.nodeIx],
        target.coordinates,
      );

      const targetPath =
        target.labelText?.xpathFull ?? target.xpathFull ?? target.cssPath;

      const newTarget: Target = {
        id: uuidv4(),
        name: target.name,
        ref: target,
        coordinates: relativeCoordinates,
      };

      let newVariable: Variable = {
        id: uuidv4(),
        type: VariableType.enum.Template,
        data: [''],
      };

      const newAction: WorkflowAction = {
        actionType: Actions.enum.Click,
        targetId: newTarget.id,
        variableId: newVariable.id,
        id,
      };

      let newActionType: ActionsEnum = Actions.enum.Click;

      handleZodCheck(
        extensionAction,
        ExtensionMultiChoiceAction,
        (multiChoiceAction) => {
          const { target: multiChoiceTarget } = multiChoiceAction;
          const multiChoiceOptions = multiChoiceTarget.multiChoiceOptions;
          const selectedChoiceIndex = multiChoiceOptions.findIndex(
            (option) => option.cssPath === multiChoiceTarget.cssPath,
          );
          newVariable = {
            id: uuidv4(),
            type: VariableType.enum.MultiChoice,
            multiChoiceOptions,
            selectedChoiceIx:
              selectedChoiceIndex !== -1 ? selectedChoiceIndex : null,
            data: [String(selectedChoiceIndex)],
          };
          newActionType = Actions.enum.MultiChoice;
        },
      );

      handleZodCheck(extensionAction, ExtensionSelectAction, (selectAction) => {
        newVariable = {
          id: uuidv4(),
          type: VariableType.enum.Select,
          selectOptions: selectAction.target.selectOptions,
          data: [selectAction.currentSelected.text || ''],
        };
        newActionType = Actions.enum.Select;
      });

      handleZodCheck(
        extensionAction,
        ExtensionMultiSelectAction,
        (multiSelectAction) => {
          newVariable = {
            id: uuidv4(),
            type: VariableType.enum.MultiSelect,
            multiSelectedOptions: multiSelectAction.currentSelected,
            data: [],
          };
          newActionType = Actions.enum.MultiSelect;
        },
      );

      handleZodCheck(extensionAction, ExtensionInputAction, (inputAction) => {
        newVariable = {
          id: uuidv4(),
          type: VariableType.enum.Template,
          data: [inputAction.currentText ?? ''],
        };
        newActionType = Actions.enum.Input;
      });

      handleZodCheck(extensionAction, ExtensionScrapeAction, (scrapeAction) => {
        newVariable = {
          id: uuidv4(),
          type: VariableType.enum.Scrape,
          data: {
            scrapedText: scrapeAction.scrapedText,
            outputType: ScrapeTypeEnum.InnerText,
            excludeFromOutputs: false,
          },
          name: target.name ?? `Scrape ${(scrapeIx++).toString()}`,
        };
        newActionType = Actions.enum.Scrape;
      });

      handleZodCheck(
        extensionAction,
        ExtensionKeyPressAction,
        (keyPressAction) => {
          newVariable = {
            id: uuidv4(),
            type: VariableType.enum.Template,
            data: [keyPressAction.keyType],
          };
          newActionType = Actions.enum.KeyPress;
          newAction.keyType = keyPressAction.keyType;
        },
      );

      const lastAction = outputActions[outputActions.length - 1];

      if (
        currentTarget === targetPath &&
        lastAction.action.actionType === newActionType &&
        (newActionType as ActionsEnum) !== Actions.enum.Click
      ) {
        const { targetId, variableId } = lastAction.action;
        if (!variableId || !targetId) {
          throw new Error('Variable or target ID is missing');
        }
        variableStore[variableId] = { ...newVariable, id: variableId };
        targetStore[targetId] = { ...newTarget, id: targetId };
      } else if (newAction.id === lastAction.action.id) {
        // This is for the case where we have iframe actions, so there was a duplicate action step with the same ID
        // Since we got the initial action, then the delayed iframe action, we should update the target store
        const { targetId } = lastAction.action;
        if (!targetId) {
          throw new Error('Target ID is missing');
        }
        targetStore[targetId] = { ...newTarget, id: targetId };
      } else {
        variableStore[newVariable.id] = newVariable;
        targetStore[newTarget.id] = newTarget;

        newAction.actionType = newActionType;

        newAction.variableId = newVariable.id;
        newAction.targetId = newTarget.id;

        outputActions.push({ action: newAction, nodeIx: action.nodeIx });
        currentTarget = targetPath;
      }
    });
  }
  // Generate actionDataArray to properly place the actions based on nodeIx
  const maxNodeIx = Math.max(...outputActions.map((action) => action.nodeIx));
  const actionDataArray: ParseActionsReturnType['actionDataArray'] = [];
  for (let i = 0; i <= maxNodeIx; i++) {
    actionDataArray.push({ actionData: {}, nodeUrls: [], actionOrder: [] });
  }
  for (const action of outputActions) {
    const { action: workflowAction, nodeIx } = action;
    actionDataArray[nodeIx].actionData[workflowAction.id] = workflowAction;
    actionDataArray[nodeIx].actionOrder.push(workflowAction.id);
  }
  for (const tabVariable of Object.values(tabMap)) {
    const tabVariableParse = TabVariable.safeParse(tabVariable);
    if (tabVariableParse.success) {
      variableStore[tabVariableParse.data.id] = tabVariableParse.data;
    }
  }
  return { variableStore, targetStore, actionDataArray };
};

export const extensionDataParse = ({
  actions: originalActions,
  scans: originalScans,
  config,
}: ExtensionData) => {
  const actions = originalActions;
  const screenshots = originalScans
    .filter((_screenshots) => _screenshots.length > 0)
    .map((_screenshots) => _screenshots[0]);
  const parsedActions = parseActions(actions, screenshots);
  return { actions: parsedActions, states: screenshots, config };
};
