import { Button, IconButton, Select, CloseCircle } from 'ui-kit';
import { useEffect, useState, useMemo, useRef } from 'react';
import {
  type Comparator,
  type Condition,
  type DatasourceMetadata,
  getLogicTypes,
  isVariableAllowedToAddInInput,
  parseTemplateString,
  SourceTypeEnum,
  StringComparatorEnum,
  type TemplateData,
  typeComparatorMap,
  type Variable,
  type VariableMap,
  VariableString,
  type TemplateVariable,
} from 'types-shared';
import { ConditionalLabel } from './UiElements';
import {
  VariableInput,
  type VariableInputRef,
} from '../VariableTypes/VariableInput';
import { comparatorToLabel } from '../../utils/helper';
import {
  openAddVariableModal,
  openPreviewVariableModal,
} from '../../../../utils/helper';
import { handleException } from 'sentry-browser-shared';

interface Props {
  condition: Condition;
  updateCondition: (condition: Partial<Condition>) => void;
  variablesMap: VariableMap;
  globalVariablesMap: VariableMap;
  datasourceMetadata: DatasourceMetadata | null;
  sourceType?: SourceTypeEnum;

  label?: string;
  edgeName?: string;
  updateVariable: (variable: Variable) => void;
  showDelete?: boolean;
  showAdd?: boolean;
  onDelete?: () => void;
  onAdd?: () => void;
}

const getComparatorOptions = (
  data: TemplateData,
  variablesMap: VariableMap,
) => {
  const strings = VariableString.parse(
    parseTemplateString({
      data,
      variableMap: variablesMap,
      handleException,
    }),
  );

  const logicTypes = getLogicTypes(strings);

  return logicTypes.flatMap((type) => {
    return typeComparatorMap[type];
  });
};

export default function SingleCondition({
  condition,
  updateCondition,
  variablesMap,
  globalVariablesMap,
  datasourceMetadata,
  label,
  showAdd,
  showDelete,
  onDelete,
  onAdd,
  sourceType,
  updateVariable,
}: Props) {
  const inputRef1 = useRef<VariableInputRef>();
  const inputRef2 = useRef<VariableInputRef>();
  const conditionFieldVariableData = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (variablesMap[condition.field.variableId] as TemplateVariable).data ?? [], // This may not exist sometimes.
    [variablesMap, condition.field.variableId],
  );

  const conditionValueVariableData = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (variablesMap[condition.value.variableId] as TemplateVariable).data ?? [], // This may not exist sometimes.
    [variablesMap, condition.value.variableId],
  );

  const [comparatorOptions, setComparatorOptions] = useState<Comparator[]>([]);

  const combinedVariablesMap = useMemo(
    () => ({
      ...variablesMap,
      ...globalVariablesMap,
    }),
    [variablesMap, globalVariablesMap],
  );

  useEffect(() => {
    const options = getComparatorOptions(
      conditionFieldVariableData,
      combinedVariablesMap,
    );
    setComparatorOptions(options);
  }, [conditionFieldVariableData, combinedVariablesMap]);

  const hasDatasource = Boolean(datasourceMetadata);

  const [isEditingField, setIsEditingField] = useState(false);

  const handleUpdateTemplateData = (
    newData: TemplateData,
    variableId: string,
  ) => {
    const variableToUpdate = variablesMap[variableId];

    updateVariable({ ...variableToUpdate, data: newData } as TemplateVariable);
  };

  const handleOnChangeComparator = (comparatorData: Comparator) => {
    updateCondition({
      comparator: comparatorData,
    });
  };

  const updateBranchData = (variableToAdd: Variable) => {
    if (!isVariableAllowedToAddInInput(variableToAdd)) {
      return;
    }

    const templateVariableId = isEditingField
      ? condition.field.variableId
      : condition.value.variableId;
    const templateVariable = variablesMap[
      templateVariableId
    ] as TemplateVariable;

    const newItems: TemplateData = [
      ...templateVariable.data,
      { id: variableToAdd.id },
    ];

    handleUpdateTemplateData(newItems, templateVariableId);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        {label ? <ConditionalLabel>{label}</ConditionalLabel> : null}
        {onDelete && showDelete ? (
          <IconButton
            className="!text-info-dark cursor-pointer"
            onClick={onDelete}
          >
            <CloseCircle />
          </IconButton>
        ) : null}
      </div>
      <div className="pt-6">
        <VariableInput
          ref={inputRef1}
          allowAddVariable={
            hasDatasource ||
            sourceType === SourceTypeEnum.API ||
            sourceType === SourceTypeEnum.EmailTrigger
          }
          value={conditionFieldVariableData}
          label="Field 1"
          onClickAddNew={(indexForVariableInsert) => {
            setIsEditingField(true);

            openAddVariableModal({
              insertVariable(newVariable: Variable) {
                inputRef1.current?.addVariable(
                  newVariable,
                  indexForVariableInsert,
                );
              },
              updateBranchData,
              isCondition: true,
            });
          }}
          onChange={(newData) => {
            handleUpdateTemplateData(newData, condition.field.variableId);
          }}
          onClickVariableChip={(id) => {
            setIsEditingField(true);
            openPreviewVariableModal({
              variableId: id,
              isCondition: true,
              updateBranchData,
            });
          }}
          placeholder="Select a variable"
          variablesMap={variablesMap}
          globalVariablesMap={globalVariablesMap}
        />
      </div>
      <div className="pt-6">
        <Select
          className="w-full"
          getLabel={(opt: Comparator) => {
            return comparatorToLabel(opt);
          }}
          getValue={(opt: Comparator) => opt}
          label="Condition"
          onChange={(p) => {
            handleOnChangeComparator(p.target.value as Comparator);
          }}
          options={comparatorOptions}
          placeholder="Select a condition"
          value={condition.comparator ?? ''}
        />
      </div>
      {condition.comparator !== StringComparatorEnum.DoesNotExist &&
        condition.comparator !== StringComparatorEnum.Exists && (
          <div className="pt-6">
            <VariableInput
              ref={inputRef2}
              allowAddVariable={hasDatasource}
              value={conditionValueVariableData}
              label="Field 2"
              onClickAddNew={(indexForVariableInsert) => {
                setIsEditingField(false);
                openAddVariableModal({
                  insertVariable(newVariable: Variable) {
                    inputRef2.current?.addVariable(
                      newVariable,
                      indexForVariableInsert,
                    );
                  },
                  isCondition: true,
                });
              }}
              onChange={(newData) => {
                handleUpdateTemplateData(newData, condition.value.variableId);
              }}
              onClickVariableChip={(id) => {
                setIsEditingField(false);
                openPreviewVariableModal({
                  variableId: id,
                  isCondition: true,
                });
              }}
              placeholder="Select value"
              variablesMap={variablesMap}
              globalVariablesMap={globalVariablesMap}
            />
          </div>
        )}
      {onAdd && showAdd ? (
        <div className="pt-6">
          <Button color="secondary" onClick={onAdd} variant="text">
            Add condition
          </Button>
        </div>
      ) : null}
    </div>
  );
}
