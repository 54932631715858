import { useState } from 'react';
import { createContainer } from 'unstated-next';

export type NodeEnv = 'development' | 'production';

type IBaseApi = Readonly<{
  python: string;
  serverless: string;
  autolinkDemo: string;
}>;

const REST_APIGW_ID =
  process.env.REACT_APP_REST_APIGW_ID ??
  process.env.PLASMO_PUBLIC_REST_APIGW_ID;

const BACKEND_STAGE =
  process.env.REACT_APP_BACKEND_STAGE ??
  process.env.PLASMO_PUBLIC_BACKEND_STAGE ??
  'development';

const SOLA_API_URL = String(
  process.env.REACT_APP_SOLA_API_URL ??
    process.env.PLASMO_PUBLIC_SOLA_API_URL ??
    'https://api.dev.sola.ai',
);

const baseApi: { [E in NodeEnv]: IBaseApi } = {
  development: {
    python: 'http://localhost:8000',
    serverless: `http://localhost:4566/restapis/${REST_APIGW_ID ?? 'UNDEFINED'}/local/_user_request_`,
    autolinkDemo: 'http://localhost:8000',
  },
  production: {
    python: 'http://localhost:8000',
    serverless: SOLA_API_URL,
    autolinkDemo: 'https://autolink.dev.blobs.lol',
  },
};

export type IEndpoints = Readonly<{
  env: NodeEnv;
  pythonApi: string;
  autolinkDemoApi: string;
  fileApiV1: string;
  userApiV1: string;
  workflowApiV1: string;
  datasourceApiV1: string;
  miscApiV1: string;
  executionApiV1: string;
  transformApiV1: string;
  temporalBaseUrl: string;
}>;

export const apiEndpoints: { [E in NodeEnv]: IEndpoints } = {
  development: {
    env: 'development',
    autolinkDemoApi: baseApi.development.autolinkDemo,
    pythonApi: baseApi.development.python,
    fileApiV1: `${baseApi.development.serverless}/v1/file`,
    userApiV1: `${baseApi.development.serverless}/v1/user`,
    workflowApiV1: `${baseApi.development.serverless}/v1/workflow`,
    datasourceApiV1: `${baseApi.development.serverless}/v1/datasource`,
    miscApiV1: `${baseApi.development.serverless}/v1/misc`,
    executionApiV1: `${baseApi.development.serverless}/v1/execution`,
    transformApiV1: `${baseApi.development.serverless}/v1/transform`,
    temporalBaseUrl:
      'https://tut.dev.sola-solutions.com/namespaces/default/workflows',
  },
  production: {
    env: 'production',
    autolinkDemoApi: baseApi.production.autolinkDemo,
    pythonApi: 'http://localhost:8000',
    fileApiV1: `${baseApi.production.serverless}/v1/file`,
    userApiV1: `${baseApi.production.serverless}/v1/user`,
    workflowApiV1: `${baseApi.production.serverless}/v1/workflow`,
    datasourceApiV1: `${baseApi.production.serverless}/v1/datasource`,
    miscApiV1: `${baseApi.production.serverless}/v1/misc`,
    executionApiV1: `${baseApi.production.serverless}/v1/execution`,
    transformApiV1: `${baseApi.production.serverless}/v1/transform`,
    temporalBaseUrl:
      BACKEND_STAGE === 'production'
        ? 'https://cloud.temporal.io/namespaces/production-legacy-execution.riamh/workflows'
        : 'https://tut.dev.sola-solutions.com/namespaces/default/workflows',
  },
} as const;

interface UseEnvironment {
  selectedEnv: NodeEnv;
  setSelectedEnv: React.Dispatch<React.SetStateAction<NodeEnv>>;
  environments: string[];
}

const DEFAULT_NODE_ENV =
  process.env.REACT_APP_DEFAULT_ENV ?? process.env.NODE_ENV ?? 'production';

const useEnvironmentInternal = (): UseEnvironment => {
  const [selectedEnv, setSelectedEnv] = useState<NodeEnv>(
    DEFAULT_NODE_ENV as NodeEnv,
  );

  return {
    selectedEnv,
    setSelectedEnv,
    environments: Object.keys(apiEndpoints),
  };
};

export const { useContainer: useEnvironment, Provider: EnvironmentProvider } =
  createContainer(useEnvironmentInternal);
