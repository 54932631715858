import {
  type NotificationConfig,
  type NotificationConfigPayload,
} from './helper';
import { type TypedDocumentNode, useMutation, useQuery } from '@apollo/client';
import { graphql } from 'hasura-gql';
import { handleException } from 'sentry-browser-shared';
import { type SlackPlatformMetadata } from 'types-shared/notificationTypes';

const CREATE_NOTIFICATION = graphql(`
  mutation CREATE_NOTIFICATION(
    $workflowId: uuid!
    $platform: notifications_enum_platform_enum!
    $platformMetadata: jsonb!
    $events: [notifications_workflow_configuration_events_insert_input!]!
    $ownerId: String
  ) {
    insert_notifications_workflow_configurations_one(
      object: {
        workflowId: $workflowId
        platform: $platform
        platformMetadata: $platformMetadata
        events: { data: $events }
        ownerId: $ownerId
      }
    ) {
      id
      platform
      platformMetadata
      events {
        configurationId
        event
      }
    }
  }
`) as TypedDocumentNode<
  {
    insert_notifications_workflow_configurations_one: {
      id: string;
      platform: string;
      platformMetadata: SlackPlatformMetadata;
      events: {
        configurationId: string;
        event: string;
      }[];
    };
  },
  {
    workflowId: string;
    platform: string;
    platformMetadata: SlackPlatformMetadata;
    events: {
      event: string;
    }[];
    ownerId?: string;
  }
>;

export const useCreateWorkflowNotification = () => {
  const [createNotification, { data, loading, error }] =
    useMutation(CREATE_NOTIFICATION);

  const createNotificationConfig = async (
    payload: NotificationConfigPayload,
  ) => {
    const variables = {
      workflowId: payload.workflowId,
      platform: payload.platform,
      platformMetadata: payload.platformMetadata,
      events: payload.events,
    } as const;

    return createNotification({
      variables: {
        ...variables,
        ...(payload.ownerId && { ownerId: payload.ownerId }),
      },
    });
  };

  return {
    createNotificationConfig,
    data,
    loading,
    error,
  };
};

const UPDATE_NOTIFICATION = graphql(`
  mutation UPDATE_NOTIFICATION(
    $configurationId: uuid!
    $platformMetadata: jsonb!
    $events: [notifications_workflow_configuration_events_insert_input!]!
  ) {
    update_notifications_workflow_configurations_by_pk(
      pk_columns: { id: $configurationId }
      _set: { platformMetadata: $platformMetadata }
    ) {
      id
      workflowId
      ownerId
      platform
      platformMetadata
    }

    delete_notifications_workflow_configuration_events(
      where: { configurationId: { _eq: $configurationId } }
    ) {
      affected_rows
    }

    insert_notifications_workflow_configuration_events(objects: $events) {
      returning {
        event
        configurationId
      }
    }
  }
`) as TypedDocumentNode<
  {
    update_notifications_workflow_configurations_by_pk: {
      id: string;
      workflowId: string;
      ownerId: string;
      platform: string;
      platformMetadata: SlackPlatformMetadata;
    };
    delete_notifications_workflow_configuration_events: {
      affected_rows: number;
    };
    insert_notifications_workflow_configuration_events: {
      returning: {
        event: string;
        configurationId: string;
      }[];
    };
  },
  {
    configurationId: string;
    platformMetadata: SlackPlatformMetadata;
    events: {
      event: string;
      configurationId: string;
    }[];
  }
>;

export const useUpdateNotification = () => {
  const [updateNotification, { data, loading, error }] =
    useMutation(UPDATE_NOTIFICATION);

  const updateNotificationConfig = async (
    notificationPayload: NotificationConfig,
    configurationId: string,
  ) => {
    return updateNotification({
      variables: {
        configurationId,
        platformMetadata: notificationPayload.platformMetadata,
        events: notificationPayload.events,
      },
    });
  };

  return {
    updateNotificationConfig,
    data,
    loading,
    error,
  };
};

const DELETE_NOTIFICATION = graphql(`
  mutation DELETE_NOTIFICATION($configurationId: uuid!) {
    delete_notifications_workflow_configuration_events(
      where: { configurationId: { _eq: $configurationId } }
    ) {
      affected_rows
    }
    delete_notifications_workflow_configurations_by_pk(id: $configurationId) {
      id
    }
  }
`) as TypedDocumentNode<
  {
    delete_notifications_workflow_configuration_events: {
      affected_rows: number;
    };
    delete_notifications_workflow_configurations_by_pk: {
      id: string;
    };
  },
  {
    configurationId: string;
  }
>;

export const useDeleteNotification = () => {
  const [deleteNotification, { data, loading, error }] =
    useMutation(DELETE_NOTIFICATION);

  const deleteNotificationConfig = async (configurationId: string) => {
    return deleteNotification({
      variables: {
        configurationId,
      },
    });
  };

  return {
    deleteNotificationConfig,
    data,
    loading,
    error,
  };
};

const GET_WORKFLOW_NOTIFICATIONS = graphql(`
  query GET_WORKFLOW_NOTIFICATIONS($workflowId: uuid!) {
    notifications_workflow_configurations(
      where: { workflow: { id: { _eq: $workflowId } } }
    ) {
      id
      platform
      platformMetadata
      workflowId
      events {
        event
        configurationId
      }
    }
  }
`) as TypedDocumentNode<
  {
    notifications_workflow_configurations: {
      id: string;
      platform: string;
      platformMetadata: SlackPlatformMetadata;
      workflowId: string;
      events: {
        event: string;
        configurationId: string;
      }[];
    }[];
  },
  {
    workflowId: string;
  }
>;

export const useGetWorkflowNotifications = (workflowId: string) => {
  const { data, loading, error } = useQuery(GET_WORKFLOW_NOTIFICATIONS, {
    variables: { workflowId },
    skip: !workflowId,
  });

  return {
    notifications: data?.notifications_workflow_configurations || [],
    loading,
    error,
  };
};

const GET_TEAM_SLACK_CHANNEL_IDS = graphql(`
  query GetTeamSlackChannelIds($workflowId: uuid!) {
    workflow: workflows_by_pk(id: $workflowId) {
      owner {
        memberships {
          team {
            id
            slackChannelId
          }
        }
      }
    }
  }
`) as TypedDocumentNode<
  {
    workflow: {
      owner: {
        memberships: {
          team: {
            id: string;
            slackChannelId: string;
          };
        }[];
      };
    };
  },
  {
    workflowId: string;
  }
>;

export const useGetTeamSlackChannelId = (workflowId: string) => {
  const { data, loading, error } = useQuery(GET_TEAM_SLACK_CHANNEL_IDS, {
    variables: { workflowId },
    skip: !workflowId,
  });

  const channelId = data?.workflow.owner.memberships[0]?.team?.slackChannelId;

  if (data && data.workflow.owner.memberships.length > 1) {
    handleException(new Error('Multiple Slack channel IDs found. Expected 1'), {
      name: 'Multiple Slack Channels',
      source: 'useGetTeamSlackChannelIds',
      extra: {
        workflowId,
        data,
      },
    });
  }

  return {
    channelId: channelId || '',
    loading,
    error,
  };
};

const GET_CONVERSATION = graphql(`
  query GetConversation($conversationId: String!) {
    getConversation(conversationId: $conversationId) {
      name
    }
  }
`) as TypedDocumentNode<
  {
    getConversation: {
      name: string;
    };
  },
  {
    conversationId: string;
  }
>;

export const useGetSlackChannelName = (channelId: string) => {
  const { data, loading, error } = useQuery(GET_CONVERSATION, {
    variables: { conversationId: channelId },
    skip: !channelId,
  });

  return {
    channelName: data?.getConversation.name || '',
    loading,
    error,
  };
};
