"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createClickStep = exports.parseClickStep = void 0;
const replay_1 = require("@puppeteer/replay");
const option_utils_1 = require("option-utils");
const xpath_1 = require("../../utils/selectors/xpath");
const parseClickStep = (id, step, idx) => {
    const xpathSelectors = step.selectors
        .map((selector) => {
        try {
            return (0, xpath_1.getXpathSelectorValue)(selector);
        }
        catch (_a) {
            return null;
        }
    })
        .filter(option_utils_1.isNotNull);
    const xpathSelector = xpathSelectors.at(0);
    if (!xpathSelector) {
        throw new Error(idx
            ? `No xpath found for step ${String(idx)}`
            : 'No xpath found for step');
    }
    return Object.assign({ id,
        xpathSelector }, step);
};
exports.parseClickStep = parseClickStep;
const createClickStep = ({ id, xpathSelector, isRightClick, }) => {
    return {
        id,
        type: replay_1.StepType.Click,
        button: isRightClick ? 'secondary' : 'primary',
        xpathSelector,
        selectors: [[`xpath/${xpathSelector}`]],
        offsetX: 0,
        offsetY: 0,
    };
};
exports.createClickStep = createClickStep;
