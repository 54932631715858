import { DateTimePicker, RadioSelect } from 'ui-kit';
import { useCallback, useState } from 'react';
import dayjs from '../../../../config/dayjs';

enum DateFilterTitleEnum {
  Today = 'Today',
  Yesterday = 'Yesterday',
  'Last week' = 'Last week',
  'Last month' = 'Last month',
  Range = 'Range',
}

export interface DateFilterValue {
  dateRange?: [Date | string | undefined, Date | string | undefined];
  chosenFilter?: DateFilterTitleEnum;
}

interface ExecutionListDateFilterProps {
  setFilterValue: (value: DateFilterValue | undefined) => void;
  filterValue?: DateFilterValue;
}

const filterOptions = Object.values(DateFilterTitleEnum);
const defaultStatus = 'Filter by date';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      width: 300,
    },
    sx: {
      '& .MuiTypography-body1': {
        fontSize: 14,
      },
    },
  },
  BackdropProps: {
    classes: { root: 'no-backdrop' },
  },
};

export default function ExecutionListDateFilter({
  filterValue,
  setFilterValue,
}: ExecutionListDateFilterProps) {
  const [internalDateRange, setInternalDateRange] = useState<
    [string | undefined, string | undefined]
  >([undefined, undefined]);

  const handleDateChange = (index: 0 | 1, value: string) => {
    const newRange: [string | undefined, string | undefined] = [
      ...internalDateRange,
    ] as [string | undefined, string | undefined];

    newRange[index] = value;
    setInternalDateRange(newRange);

    if (newRange[0] && newRange[1]) {
      setFilterValue({
        ...filterValue,
        chosenFilter: DateFilterTitleEnum.Range,
        dateRange: newRange,
      });
    }
  };

  const handleGetFilterListValue = (title: DateFilterTitleEnum) => {
    if (title === DateFilterTitleEnum.Range) {
      return (
        <div className="flex flex-col gap-2 h-min-10 pt-4">
          <p className="pl-2 pb-2">Range</p>
          <div
            role="presentation"
            className="flex flex-row gap-2 w-full items-center"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DateTimePicker
              disablePast={false}
              labelBackground={
                filterValue?.chosenFilter === DateFilterTitleEnum.Range
                  ? '#ededed'
                  : '#ffffff'
              }
              label="Date"
              onAccept={(e) => {
                handleDateChange(0, e.toISOString());
              }}
              value={
                filterValue?.dateRange?.[0]
                  ? dayjs(filterValue.dateRange[0])
                  : undefined
              }
            />

            <span>to</span>

            <DateTimePicker
              disablePast={false}
              labelBackground={
                filterValue?.chosenFilter === DateFilterTitleEnum.Range
                  ? '#ededed'
                  : '#ffffff'
              }
              label="Date"
              onAccept={(e) => {
                handleDateChange(1, e.toISOString());
              }}
              value={
                filterValue?.dateRange?.[1]
                  ? dayjs(filterValue.dateRange[1])
                  : undefined
              }
            />
          </div>
        </div>
      );
    }
    return <p className="pl-2">{title}</p>;
  };

  const handleGetFilterTitle = useCallback(
    (title: DateFilterTitleEnum) => {
      if (title === DateFilterTitleEnum.Range) {
        const start = filterValue?.dateRange?.[0]
          ? dayjs(filterValue.dateRange[0]).format('MM/DD/YYYY hh:mm A')
          : 'N/A';
        const end = filterValue?.dateRange?.[1]
          ? dayjs(filterValue.dateRange[1]).format('MM/DD/YYYY hh:mm A')
          : 'N/A';
        return `From ${start} to ${end}`;
      }
      return title;
    },
    [filterValue],
  );

  return (
    <div>
      <RadioSelect
        label="Filter by date"
        style={{ fontSize: '14px' }}
        floatingLabel
        classes={{
          wrapper: 'flex-col space-y-1',
        }}
        sx={{
          '& .MuiSelect-select': {
            width:
              filterValue?.chosenFilter === DateFilterTitleEnum.Range
                ? '22rem'
                : '10rem',
          },
        }}
        placeholder={defaultStatus}
        value={
          filterValue?.chosenFilter ? filterValue.chosenFilter : defaultStatus
        }
        doesInputHaveValue={(value) => {
          let valueExists = false;
          if (typeof value === 'string') {
            valueExists = value !== defaultStatus && Boolean(value.length);
          }
          return valueExists;
        }}
        getFilterTitle={(item) =>
          handleGetFilterTitle(item as DateFilterTitleEnum)
        }
        getFilterListValue={(item) =>
          handleGetFilterListValue(item as DateFilterTitleEnum)
        }
        isChecked={(item) =>
          filterValue?.chosenFilter === (item as DateFilterTitleEnum)
        }
        options={filterOptions}
        onChange={(event) => {
          const newValue = event.target.value;
          if (newValue === defaultStatus) return;

          if (newValue !== DateFilterTitleEnum.Range) {
            setFilterValue({
              ...filterValue,
              chosenFilter: newValue as DateFilterTitleEnum,
              dateRange: undefined,
            });
          }
        }}
        renderValue={(selected) => {
          if (selected === defaultStatus) return <>{selected}</>;
          const title = handleGetFilterTitle(selected as DateFilterTitleEnum);
          return <>{title}</>;
        }}
        MenuProps={MenuProps}
      />
    </div>
  );
}
