// Handle ui display for outputs from email step
import React, { type ReactNode } from 'react';
import {
  type GlobalVariable,
  type Variable,
  type VariableMap,
  TemplateVariable,
  type WorkflowEmailNode,
  type VariableIdContainer,
  parseVariable,
  VariableString,
  QueryVariable,
  type ExecutionVariables,
  type DocumentVariable,
  ParseVariableMode,
  EmailProviderEnum,
} from 'types-shared';
import { Output } from './RecordOutputs';
import {
  extractFilename,
  getEmailStepDownload,
  parseEmailStepQueryVariable,
} from '../../../utils';
import { handleException } from 'sentry-browser-shared';
import { type GetExecutionResponse } from 'api-types-shared';
import { capitalizeFirstLetter } from '../../../../Workflows/utils/helper';

interface EmailStepOutputsProps {
  currentNode: WorkflowEmailNode;
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  variablesMap: Record<string, Variable>;
  executionVariables: ExecutionVariables;
  variableMetadata: Record<string, string>;
  onDownloadLinkData?: (url: string) => void;
  artifacts?: GetExecutionResponse['inputFiles'];
}

export default function EmailStepOutputs({
  currentNode,
  executionVariables,
  variableMetadata,
  variablesMap,
  globalVariablesMap,
  onDownloadLinkData,
  artifacts,
}: EmailStepOutputsProps) {
  return (
    <div className="flex flex-col gap-2 py-4 px-6 overflow-auto max-h-full">
      <EmailIntegrationSettings data={currentNode} />
      {currentNode.data.sendEmailDetails ? (
        <SendEmailDetails
          data={currentNode.data.sendEmailDetails}
          globalVariablesMap={globalVariablesMap}
          variablesMap={variablesMap}
          executionVariables={executionVariables}
          variableMetadata={variableMetadata}
          onDownloadLinkData={onDownloadLinkData}
          artifacts={artifacts}
        />
      ) : null}
    </div>
  );
}

// TODO @Paul: Use email provider, action and email type from the node after we stop using default values for these
function EmailIntegrationSettings({ data }: { data: WorkflowEmailNode }) {
  return (
    <div className="flex flex-col gap-2 py-4 px-6 rounded-lg border border-indigo-light m-6">
      <p className="text-info-dark text-sm font-medium">
        Email Integration settings
      </p>

      <p className="text-info-dark text-sm font-medium pt-5">Email provider</p>
      <div className="flex flex-row gap-2">
        <div className="w-6 flex items-center justify-center">
          <img
            alt="logo"
            className="w-5 bg-white"
            src={
              data.data.emailProvider === EmailProviderEnum.Gmail
                ? '/gmail.png'
                : '/outlook.svg'
            }
          />
        </div>
        <span className="text-gray-500 text-sm font-medium">
          {capitalizeFirstLetter(data.data.emailProvider ?? '')}
        </span>
      </div>

      <LabelAndValue label="Action" value="Send Email" />
      <LabelAndValue label="Email type" value="New email message" />
    </div>
  );
}

// Only for the send email details object
function SendEmailDetails({
  data,
  variablesMap,
  globalVariablesMap,
  executionVariables,
  variableMetadata,
  onDownloadLinkData,
  artifacts,
}: {
  data: WorkflowEmailNode['data']['sendEmailDetails'];
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap;
  variablesMap: Record<string, Variable>;
  executionVariables: ExecutionVariables;
  variableMetadata: Record<string, string>;
  onDownloadLinkData?: (url: string) => void;
  artifacts?: GetExecutionResponse['artifacts'];
}) {
  if (!data) {
    return null;
  }

  return (
    <>
      <div className="flex flex-col gap-2 py-4 px-6 rounded-lg border border-indigo-light m-6">
        <p className="text-info-dark text-sm font-medium">Email Header</p>

        <LabelAndValue
          label="Sender"
          value={mapTemplateArrayContent(
            data.sender,
            globalVariablesMap,
            variablesMap,
            executionVariables,
            variableMetadata,
          )}
        />
        <LabelAndValue
          label="Recipients"
          value={mapTemplateArrayContent(
            data.recipients,
            globalVariablesMap,
            variablesMap,
            executionVariables,
            variableMetadata,
          )}
        />
        {data.ccRecipients ? (
          <LabelAndValue
            label="CC Recipients"
            value={mapTemplateArrayContent(
              data.ccRecipients,
              globalVariablesMap,
              variablesMap,
              executionVariables,
              variableMetadata,
            )}
          />
        ) : null}
        {data.bccRecipients ? (
          <LabelAndValue
            label="BCC Recipients"
            value={mapTemplateArrayContent(
              data.bccRecipients,
              globalVariablesMap,
              variablesMap,
              executionVariables,
              variableMetadata,
            )}
          />
        ) : null}
        <LabelAndValue
          label="Subject"
          value={mapTemplateArrayContent(
            data.subject,
            globalVariablesMap,
            variablesMap,
            executionVariables,
            variableMetadata,
          )}
        />
      </div>

      <div className="flex flex-col gap-2 py-4 px-6 rounded-lg border border-indigo-light m-6">
        <LabelAndValue
          label="Email Body"
          value={mapTemplateArrayContent(
            data.emailBody,
            globalVariablesMap,
            variablesMap,
            executionVariables,
            variableMetadata,
          )}
        />
      </div>

      {artifacts?.length &&
      data.attachments &&
      (variablesMap[data.attachments.variableId] as TemplateVariable).data
        .length &&
      onDownloadLinkData ? (
        <div className="flex flex-col gap-2 py-4 px-6 rounded-lg border border-indigo-light m-6">
          <LabelAndValue
            label="Attachments"
            value={mapDownloads(
              artifacts,
              data.attachments.variableId,
              onDownloadLinkData,
              variablesMap,
            )}
          />
        </div>
      ) : null}
    </>
  );
}

function LabelAndValue({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) {
  return (
    <>
      <p className="text-info-dark text-sm font-medium pt-5">{label}</p>
      <p className="text-gray-500 text-sm font-medium">{value}</p>
    </>
  );
}

// Handle template data for input fields
const mapTemplateArrayContent = (
  element: VariableIdContainer,
  globalVariablesMap: Record<string, GlobalVariable> | VariableMap,
  variablesMap: Record<string, Variable>,
  executionVariables: ExecutionVariables,
  variableMetadata: Record<string, string>,
) => {
  const dataVariable = variablesMap[element.variableId];
  const check = TemplateVariable.safeParse(dataVariable);

  if (check.success) {
    const data = check.data.data;

    const content = data.map((val) => {
      if (typeof val === 'object') {
        const variable = variablesMap[val.id] ?? globalVariablesMap[val.id];

        const isInputVariable = QueryVariable.safeParse(variable);

        if (isInputVariable.success) {
          const asInputVariable = isInputVariable.data;
          return parseEmailStepQueryVariable({
            executionVariables,
            variableMetadata,
            variableId: asInputVariable.id,
          });
        }

        const payload = parseVariable({
          variable,
          variableMap: { ...variablesMap, ...globalVariablesMap },
          handleException,
          mode: ParseVariableMode.Execution,
        });

        return VariableString.parse(
          Array.isArray(payload) ? payload.join(' ') : payload,
        );
      }
      return <React.Fragment key={val}>{val}</React.Fragment>;
    });

    return content;
  }
};

// Extract and map attachments or any future similar fields
const mapDownloads = (
  artifacts: GetExecutionResponse['artifacts'],
  variableId: string,
  onDownloadLinkData: (url: string) => void,
  variablesMap: Record<string, Variable>,
) => {
  const attachmentsVariable = variablesMap[variableId] as TemplateVariable;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (attachmentsVariable) {
    const data = attachmentsVariable.data.filter((v) => typeof v !== 'string');

    return data
      .map((d) => variablesMap[d.id] as DocumentVariable)
      .map((dVar) => {
        const attachmentArtifact = getEmailStepDownload(
          { artifacts, variableId: dVar.id },
          onDownloadLinkData,
        );
        if (!attachmentArtifact) return null;

        return (
          <Output
            className="!max-w-[100%] !mt-2"
            action="download"
            description={extractFilename(attachmentArtifact.title)}
            key={dVar.id}
            title="Email attachment"
            onDownloadLinkData={onDownloadLinkData}
            uri={attachmentArtifact.uri}
          />
        );
      });
  }
};
