import {
  AlertVariant,
  Download,
  IconButton,
  modalEventChannel,
  MoreVert,
  notify,
  Spinner,
  WarningAmberOutlined,
} from 'ui-kit';
import { useGetFileUrl, useDeleteWorkflowFile } from './hooks';

interface Props {
  fileId: string;
  filename: string;
}

export function FileManager({ fileId, filename }: Props) {
  const { isLoading, refetch } = useGetFileUrl(fileId, filename);
  const { mutate: deleteFile } = useDeleteWorkflowFile();

  const onDeleteFile = () => {
    modalEventChannel.emit('open', {
      title: 'Are you sure you want to delete the file?',
      titleClassName: '!text-[#103D61] !font-medium !text-lg',
      contentClassName: '!w-[50rem]',
      actions: [
        {
          text: 'yes,  Delete file',
          onClick: () => {
            notify({
              message: 'Deleting file...',
              variant: AlertVariant.INFO,
              timeoutInMs: 3000,
            });
            modalEventChannel.emit('close');
            deleteFile(fileId);
          },
          color: 'error',
        },
        {
          text: 'Cancel',
          variant: 'outlined',
          onClick: () => {
            modalEventChannel.emit('close');
          },
        },
      ],
      children: (
        <div className="bg-warning-light flex flex-row gap-2 rounded-lg px-4 py-3 mt-8 my-10">
          <WarningAmberOutlined className="text-warning" />
          <p className="text-warning-dark text-base">
            Deleting a file used in the workflow could result in a workflow
            error.
          </p>
        </div>
      ),
    });
  };

  return isLoading ? (
    <Spinner className="!text-info !mr-2.5" size={20} />
  ) : (
    <div className="flex items-center space-x-1">
      <IconButton color="secondary" onClick={() => refetch()}>
        <Download className="!text-info !h-6 !w-6" />
      </IconButton>
      <IconButton color="secondary" onClick={onDeleteFile}>
        <MoreVert className="!text-info !h-6 !w-6" />
      </IconButton>
    </div>
  );
}
