import {
  ApiRequestOptionIcon,
  ChatIcon,
  CloseIcon,
  FreeformOptionIcon,
  JumpToStepOptionIcon,
  RecordingOptionIcon,
  RetryOptionIcon,
  StopOptionIcon,
  Button,
  Tooltip,
  GoogleIcon,
  MailIcon,
  Spinner,
} from 'ui-kit';
import React, {
  type ReactNode,
  type SyntheticEvent,
  useMemo,
  useState,
} from 'react';
import {
  NodeTypesEnum,
  type RequestIntegrationTypeEnum,
  type WorkflowNode,
} from 'types-shared';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { clsx } from 'clsx';
import { type SvgIconProps } from '@mui/material/SvgIcon';
import sortBy from 'lodash/sortBy';
import { contactModalEventChannel } from '../../../../utils/contactModal';
import { FeatureFlag } from '../../../../utils/constants';
import { isAdmin } from '../../../../utils/env';
import {
  useGetParagonUserToken,
  useParagonAuth,
} from '../../../Integrations/hooks';
import { Link } from 'react-router-dom';

interface Props {
  workflowId: string;
  continueRecordingBlockEnabled?: boolean;
  stopBlockEnabled?: boolean;
  enabledFeatureFlags: string[];
  sendEmailStepEnabled?: boolean;
  node: WorkflowNode;
  onContinue: (
    nodeType: NodeTypesEnum,
    integrationType?: RequestIntegrationTypeEnum,
  ) => void;
  onCancel: () => void;
  updateNode: (node: WorkflowNode) => void;
  fullRequestNodeVersion?: boolean;
  useEmailNodeIntegrationCheck: boolean;
}

type TabId = 'utilities' | 'integrations';

export function OptionsBlock({
  workflowId,
  continueRecordingBlockEnabled,
  enabledFeatureFlags,
  onCancel,
  onContinue,
  fullRequestNodeVersion,
  sendEmailStepEnabled,
  useEmailNodeIntegrationCheck,
}: Props) {
  const { data, isLoading } = useGetParagonUserToken();
  const { user } = useParagonAuth(data?.token);

  const [selectedTab, setSelectedTab] = useState<TabId>('utilities');

  const userHasEmailIntegrations = useMemo(() => {
    if (!useEmailNodeIntegrationCheck) return true;
    const gmailEnabled = Boolean(user?.integrations.gmail?.enabled);
    const outlookEnabled = Boolean(user?.integrations.outlook?.enabled);
    return gmailEnabled || outlookEnabled;
  }, [user, useEmailNodeIntegrationCheck]);

  const handleNodeTypeChange = (
    type: NodeTypesEnum,
    integrationType?: RequestIntegrationTypeEnum,
  ) => {
    onContinue(type, integrationType);
  };

  const handleCancel = () => {
    onCancel();
  };

  const steps: {
    title: string;
    description: string;
    value: NodeTypesEnum;
    Icon?: (props: SvgIconProps) => React.JSX.Element;
    disabled?: boolean;
    disabledMessage?: string;
    hidden?: boolean;
  }[] = useMemo(() => {
    const opts = [
      {
        title: 'Conditionals',
        description: 'Add logic and branches onto your workflow',
        value: NodeTypesEnum.Conditional,
        disabled: false,
        Icon: JumpToStepOptionIcon,
      },
      {
        title: 'Retry',
        description: 'Retry the workflow with specified settings',
        value: NodeTypesEnum.Retry,
        disabled:
          !isAdmin && !enabledFeatureFlags.includes(FeatureFlag.RetryBlock),
        disabledMessage:
          'Utilize the retry feature to configure when and how the workflow retries. Contact sales to learn more!',
        Icon: RetryOptionIcon,
      },
      {
        title: 'Stop',
        description: 'Stop the workflow and update its status',
        value: NodeTypesEnum.Stop,
        Icon: StopOptionIcon,
      },
      {
        title: 'Add recording',
        description: 'Import additional recordings into the workflow',
        value: NodeTypesEnum.Continue,
        disabled: !continueRecordingBlockEnabled,
        disabledMessage:
          'Build your workflow out by recording further portions. Contact sales to learn more!',
        Icon: RecordingOptionIcon,
      },
      {
        title: 'Freeform step',
        description: 'Write the requirements for this step',
        value: NodeTypesEnum.Freeform,
        disabled:
          !isAdmin && !enabledFeatureFlags.includes(FeatureFlag.FreeformBlock),
        disabledMessage:
          'Use the freeform step to provide open-ended instructions for the bot to act on. Contact sales to learn more!',
        Icon: FreeformOptionIcon,
      },
      {
        title: 'API request',
        description: 'Call an API endpoint to get and receive information',
        value: NodeTypesEnum.Request,
        disabled: !isAdmin && !fullRequestNodeVersion,
        disabledMessage:
          'Integrate with external APIs seamlessly during execution. Contact sales to learn more!',
        Icon: ApiRequestOptionIcon,
      },
      {
        title: 'Temporal workflow',
        description: 'Execute a Temporal workflow',
        value: NodeTypesEnum.Temporal,
        disabled: !isAdmin,
        disabledMessage:
          'Execute Temporal workflows during execution. Contact sales to learn more!',
        Icon: GoogleIcon,
        hidden: !isAdmin,
      },

      // TODO: put the display of these options behind a feature flag
      // {
      //   title: 'Document',
      //   description: 'Extract information and process documents',
      //   value: NodeTypesEnum.Document,
      //   disabled: true,
      //   Icon: DocumentOptionIcon,
      // },
      // {
      //   title: 'Loop',
      //   description: 'Iterate over steps in your workflow',
      //   value: NodeTypesEnum.Request,
      //   disabled: true,
      //   Icon: LoopOptionIcon,
      // },
    ];

    return sortBy(opts, 'disabled');
  }, [
    fullRequestNodeVersion,
    continueRecordingBlockEnabled,
    enabledFeatureFlags,
  ]);

  const integrations: {
    title: string;
    description: string;
    value: NodeTypesEnum;
    Icon?: (props: SvgIconProps) => React.JSX.Element;
    disabled?: boolean;
    disabledMessage?: ReactNode;
    hidden?: boolean;
    loading?: boolean;
  }[] = useMemo(() => {
    return [
      {
        title: 'Send email',
        description: 'Set up email actions with a connected email integration',
        value: NodeTypesEnum.Email,
        disabled:
          (!isAdmin && !sendEmailStepEnabled) ||
          (!isAdmin && !userHasEmailIntegrations),
        disabledMessage: !userHasEmailIntegrations ? (
          <>
            Make sure you connect your email on the{' '}
            <Link
              to="/integrations"
              className="font-bold underline"
              target="_blank"
            >
              Integrations page
            </Link>{' '}
            to send emails.
          </>
        ) : (
          'Send email with your connected accounts. Contact sales to learn more!'
        ),
        Icon: MailIcon,
        loading: isLoading,
      },
    ];
  }, [sendEmailStepEnabled, userHasEmailIntegrations, isLoading]);

  const handleChange = (event: SyntheticEvent, newValue: TabId) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="zoom-adjusted-container absolute left-2 top-2 bottom-2 w-120 bg-white rounded-lg z-[10] flex flex-col items-stretch space-y-5">
      <div className="flex justify-between items-center px-8 pt-8">
        <h2 className="text-lg font-medium text-info-dark">Step inventory</h2>
        <Button
          className="!min-w-min h-10 w-10 flex justify-center items-center !p-0 !rounded-lg"
          color="secondary"
          onClick={handleCancel}
          variant="outlined"
        >
          <CloseIcon className="text-info" />
        </Button>
      </div>
      <div className="border-b border-gray-300">
        <Tabs
          className="!px-6"
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#2196F3',
            },
            '& .Mui-selected': {
              color: '#2196F3 !important',
            },
          }}
          value={selectedTab}
        >
          <Tab label="STEPS" value="utilities" />
          <Tab label="INTEGRATIONS" value="integrations" />
        </Tabs>
      </div>
      <div className="overflow-auto flex flex-col h-full">
        <div className="flex-1 node-type-list">
          {selectedTab === 'utilities' ? (
            <div className="p-6 flex flex-col gap-4">
              {steps.map(
                ({
                  title,
                  description,
                  value,
                  Icon,
                  disabled,
                  disabledMessage,
                  hidden,
                }) =>
                  hidden ? null : (
                    <Option
                      key={value}
                      title={title}
                      description={description}
                      onClick={() => {
                        handleNodeTypeChange(value);
                      }}
                      Icon={Icon}
                      disabled={disabled}
                      disabledMessage={disabledMessage}
                    />
                  ),
              )}
            </div>
          ) : null}

          {selectedTab === 'integrations' ? (
            <div className="p-6 flex flex-col gap-4">
              {integrations.map(
                ({
                  title,
                  description,
                  value,
                  Icon,
                  disabled,
                  disabledMessage,
                  hidden,
                }) =>
                  hidden ? null : (
                    <Option
                      key={value}
                      title={title}
                      description={description}
                      onClick={() => {
                        handleNodeTypeChange(value);
                      }}
                      Icon={Icon}
                      disabled={disabled}
                      disabledMessage={disabledMessage}
                    />
                  ),
              )}
              {/* <Option
                title="Salesforce integration"
                description="Find, create, and update records"
                onClick={() => {
                  handleNodeTypeChange(
                    NodeTypesEnum.Request,
                    RequestIntegrationTypeEnum.Salesforce,
                  );
                }}
                ImageIcon={<img alt="logo" src="/salesforce.png" />}
              /> */}
              {/*<Option*/}
              {/*  title="Gmail integration"*/}
              {/*  description="Find, create, and update records"*/}
              {/*  onClick={() => {*/}
              {/*    handleNodeTypeChange(*/}
              {/*      NodeTypesEnum.Request,*/}
              {/*      RequestIntegrationTypeEnum.Gmail,*/}
              {/*    );*/}
              {/*  }}*/}
              {/*  disabled*/}
              {/*/>*/}
            </div>
          ) : null}
        </div>

        <div className="px-6 pb-6 pt-4">
          <div className="border border-blue-500 p-4 rounded-md bg-blue-500/5">
            <div className="flex gap-2 items-center">
              <ChatIcon className="text-blue-500" />
              <span className="text-info-dark text-sm font-medium">
                Can't find what you need?
              </span>
            </div>
            <div className="text-slate-500 text-sm mt-2">
              Contact us to figure out steps necessary to your workflow.
            </div>

            <div className="mt-4">
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={() => {
                  contactModalEventChannel.emit('open', {
                    problemType: 'General Issue',
                    workflowId,
                  });
                }}
              >
                Contact us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface OptionProps {
  title: string;
  description: string;
  onClick?: () => void;
  disabled?: boolean;
  disabledMessage?: ReactNode;
  Icon?: (props: SvgIconProps) => React.JSX.Element;
  ImageIcon?: React.ReactNode;
  loading?: boolean;
}

export function Option({
  title,
  description,
  Icon,
  ImageIcon,
  onClick,
  disabled,
  disabledMessage,
  loading,
}: OptionProps) {
  return (
    <Tooltip
      arrow
      title={
        disabled
          ? disabledMessage ?? 'Contact sales to access this feature.'
          : undefined
      }
    >
      <div
        className={clsx({
          'group p-2 rounded-md flex items-center border border-slate-300 w-full relative':
            true,
          'bg-neutral-50 cursor-pointer': !disabled,
          'hover:bg-blue-500/5 hover:border-blue-500 transition-all duration-200':
            !disabled,
          'bg-white': disabled,
        })}
        onClick={disabled ? undefined : onClick}
        role="presentation"
      >
        {loading ? (
          <div className="absolute top-2 right-2">
            <Spinner />
          </div>
        ) : null}
        {ImageIcon ? (
          <div className="max-w-12 pl-5 py-5 mr-5">{ImageIcon}</div>
        ) : null}
        {Icon ? (
          <Icon
            className={clsx(
              'm-4 max-w-5',
              disabled
                ? 'text-gray-400'
                : 'text-info-dark group-hover:text-blue-500  transition-all duration-200',
            )}
          />
        ) : null}
        <div>
          <div
            className={clsx(
              disabled ? 'text-gray-400' : 'text-info-dark',
              'text-md font-medium',
            )}
          >
            {title}
          </div>
          <div
            className={clsx(
              disabled ? 'text-gray-400' : 'text-gray-500',
              'text-sm',
            )}
          >
            {description}
          </div>
        </div>
      </div>
    </Tooltip>
  );
}
