import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Modal, CustomTypography } from 'ui-kit';
import { handleException } from 'sentry-browser-shared';
import {
  useGetWorkflowConcurrencyLimit,
  useUpdateWorkflowConcurrentLimit,
} from '../hooks/useWorkflowConcurrency.gql';

interface Props {
  onCancel: () => void;
  workflowId: string;
}

export default function ConcurrencyLimitModal({ onCancel, workflowId }: Props) {
  const [limit, setLimit] = useState<string>('');
  const { data, loading: loadingLimit } =
    useGetWorkflowConcurrencyLimit(workflowId);
  const { mutateAsync, loading: updatingLimit } =
    useUpdateWorkflowConcurrentLimit();

  const canSubmit = Number(limit) >= 0 || limit === '';

  const onSubmit = useCallback(async () => {
    try {
      await mutateAsync({
        workflowId,
        limit: limit === '' ? null : Number(limit),
      });
    } catch (error) {
      handleException(error, {
        name: 'Set concurrency limit',
        source: 'ConcurrencyLimitModal',
        extra: {
          workflowId,
          limit,
        },
      });
    }
    onCancel();
  }, [onCancel, mutateAsync, workflowId, limit]);

  useEffect(() => {
    if (data !== undefined) {
      setLimit(data.toString());
    }
  }, [data]);

  return (
    <Modal className="w-full !max-w-3xl" onClose={onCancel} open>
      <CustomTypography variant="h5">Set concurrency limit</CustomTypography>

      <div className="mt-7">
        <InputLabel className="!text-primary">
          Maximum Concurrent Executions
        </InputLabel>
        <TextField
          color="secondary"
          focused
          fullWidth
          onChange={(e) => {
            setLimit(e.target.value);
          }}
          size="medium"
          value={limit}
          variant="outlined"
        />
      </div>

      <Flex className="gap-4 mt-10">
        <Button
          color="secondary"
          loading={loadingLimit || updatingLimit}
          disabled={!canSubmit}
          onClick={onSubmit}
          variant="contained"
        >
          Update
        </Button>
        <Button color="secondary" onClick={onCancel} variant="outlined">
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
}
