"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebhookResults = exports.WebhookResult = void 0;
var zod_1 = require("zod");
exports.WebhookResult = zod_1.z.object({
    url: zod_1.z.string(),
    method: zod_1.z.string(),
    requestHeaders: zod_1.z.record(zod_1.z.string(), zod_1.z.string()),
    requestBody: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()),
    responseStatus: zod_1.z.number(),
    responseHeaders: zod_1.z.record(zod_1.z.string(), zod_1.z.string()),
    responseBody: zod_1.z.unknown(),
    timestamp: zod_1.z.string(),
});
exports.WebhookResults = zod_1.z.array(exports.WebhookResult);
