import { type ReactNode } from 'react';

export default function SectionLabel({
  title,
  children,
  extraClasses,
}: {
  title: ReactNode;
  children?: ReactNode;
  extraClasses?: string;
}) {
  return (
    <div className={`mt-4 flex items-center space-x-4 ${extraClasses ?? ''}`}>
      <span className="text-cyan-900 text-sm font-medium leading-normal">
        {title}
      </span>
      {children}
    </div>
  );
}
