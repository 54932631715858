import dayjs from 'dayjs';
import { useMemo } from 'react';
import { type WorkflowRetryNode, type ExecutionProgress } from 'types-shared';
import { type ScreenshotUrl } from '../../../utils';

interface RetryInputsProps {
  currentNode: WorkflowRetryNode;
  completedSteps: ExecutionProgress;
  currentAttempt?: number;
  selectedIndex: number;
  screenshotUrls: ScreenshotUrl[];
}

export default function RetryInputs({
  currentNode,
  completedSteps,
  selectedIndex,
  screenshotUrls,
  currentAttempt,
}: RetryInputsProps) {
  const attemptNumber = useMemo(() => {
    const attempts = screenshotUrls
      .filter((s) => s.sortData.nodeId === currentNode.id && s.type === 'icon')
      .sort((a, b) => {
        const aTime = a.sortData.timestamp ?? '';
        const bTime = b.sortData.timestamp ?? '';
        return new Date(aTime).getTime() - new Date(bTime).getTime();
      });

    const thisAttempt = screenshotUrls[selectedIndex];

    const thisAttemptNumber = attempts.findIndex(
      (a) => a.sortData.timestamp === thisAttempt.sortData.timestamp,
    );
    return thisAttemptNumber + 1;
  }, [screenshotUrls, currentNode.id, selectedIndex]);

  const { timeLeftToNextRetry } = useMemo(() => {
    const allRetries = completedSteps.filter(
      (step) => step.nodeId === currentNode.id,
    );
    const timeStamp = allRetries[allRetries.length - 1].timestamp;
    const retryTime = currentNode.data.retryInterval ?? 0;
    const timeStampDate = new Date(timeStamp ?? 0).getTime();

    const now = new Date().getTime();
    const timeSinceRetry = now - (timeStamp ? timeStampDate : now);
    const timeLeft = Math.max(0, retryTime * 60 * 1000 - timeSinceRetry);
    return {
      timeLeftToNextRetry: dayjs()
        .add(timeLeft, 'milliseconds')
        .format('h:mm A [on] MMMM D, YYYY'),
    };
  }, [currentNode, completedSteps]);

  return (
    <div className="flex flex-col gap-2 py-4 px-6 overflow-auto rounded-lg border border-indigo-light m-6">
      <p className="text-info-dark text-sm font-medium">Attempt</p>
      <p className="text-slate-500 text-sm font-normal pb-4">
        {attemptNumber === 0 ? currentAttempt : attemptNumber} out of{' '}
        {currentNode.data.maxAttempts}
      </p>

      <p className="text-info-dark text-sm font-medium">Retry time:</p>
      <p className="text-slate-500 text-sm font-normal">
        {timeLeftToNextRetry}
      </p>
    </div>
  );
}
