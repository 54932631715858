import {
  type ExecutionVariables,
  EmailTriggerVariableEnum,
  type WorkflowSourceNode,
  NodeTypesEnum,
  SourceVariable,
  SourceTypeEnum,
} from 'types-shared';
import { useMemo } from 'react';
import { Label } from 'ui-kit';
import { Output } from './RecordOutputs';
import {
  extractFilename,
  extractSourceOutputsData,
  processEmailSender,
} from '../../../utils';
import type { ExecutionDocument } from 'api-types-shared';

interface Props {
  node: WorkflowSourceNode;
  executionVariables: ExecutionVariables;
  onDownloadLinkData?: (url: string) => void;
  executionArtifacts: ExecutionDocument[];
  inputFiles: ExecutionDocument[];
}

const customTitleMap = {
  From: 'From',
  'Email Data': 'Extracted Data from Email',
} as Record<string, string>;

export function SourceOutputs({
  node,
  executionVariables,
  onDownloadLinkData,
  executionArtifacts,
  inputFiles,
}: Props) {
  const isEmailTriggerSourceNode = useMemo(() => {
    const sourceVariable = Object.values(executionVariables).find(
      (v) => SourceVariable.safeParse(v).success,
    );

    if (sourceVariable) {
      return (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        node.type === NodeTypesEnum.Source &&
        (sourceVariable as SourceVariable).data.sourceType ===
          SourceTypeEnum.EmailTrigger
      );
    }

    return false;
  }, [node, executionVariables]);

  const outputs = useMemo(() => {
    const allOutputs = extractSourceOutputsData(executionVariables);
    const allPossibleTitles = Object.values(EmailTriggerVariableEnum);

    const finalOutputs = allOutputs.filter((o) =>
      allPossibleTitles.includes(o.title as never),
    );

    if (isEmailTriggerSourceNode) {
      return finalOutputs
        .filter((o) => o.title !== 'To')
        .map((o) =>
          o.title === 'From'
            ? {
                ...o,
                // eslint-disable-next-line prefer-named-capture-group
                description: `"${o.description.replace(/<(.+)>/, '($1)').replaceAll('"', '')}"`,
              }
            : o,
        );
    }
    return finalOutputs;
  }, [executionVariables, isEmailTriggerSourceNode]);

  return (
    <div className="flex flex-col gap-4 py-4 px-6 overflow-auto max-h-full">
      {outputs
        // .filter((o) => o.title !== 'From')
        .map(({ id, title, description, scrapeText }) => {
          const attachmentArtifacts =
            title === 'Attachments'
              ? [...executionArtifacts, ...inputFiles].filter((e) =>
                  description.includes(e.s3Key),
                )
              : undefined;

          return (
            <div
              className="rounded-lg border border-slate-300 px-4 py-4 flex-col flex space-y-6"
              key={id}
            >
              <Label>
                {customTitleMap[title] ? customTitleMap[title] : title}
              </Label>

              {scrapeText ? (
                <>
                  {!(isEmailTriggerSourceNode && title === 'Email Data') ? (
                    <Label>Extracted data?</Label>
                  ) : (
                    ''
                  )}
                  <div
                    className="!mt-2 text-slate-500 text-sm break-all"
                    dangerouslySetInnerHTML={{ __html: scrapeText }}
                  />
                </>
              ) : null}

              {description ? (
                <>
                  {/* Hide content title of "From" on email trigger source node */}
                  {isEmailTriggerSourceNode ? null : <Label>Content</Label>}
                  {[...(attachmentArtifacts ? attachmentArtifacts : [])].map(
                    (attachmentArtifact) => (
                      <Output
                        className="!max-w-[100%] !mt-2"
                        action="download"
                        description={extractFilename(attachmentArtifact.s3Key)}
                        key={id}
                        title="Email attachment"
                        onDownloadLinkData={onDownloadLinkData}
                        uri={attachmentArtifact.uri}
                      />
                    ),
                  )}
                  {description &&
                  !attachmentArtifacts?.length &&
                  !(isEmailTriggerSourceNode && title === 'Email Data') ? (
                    <div
                      className="text-slate-500 text-sm !mt-2 break-all [&>a]:text-blue-700 [&>a]:underline"
                      dangerouslySetInnerHTML={{
                        __html:
                          isEmailTriggerSourceNode && title === 'From'
                            ? processEmailSender(description)
                            : description.replaceAll('\n', '<br/>'),
                      }}
                    />
                  ) : null}
                </>
              ) : null}

              {title === 'Email Data' && !scrapeText && (
                <span className="!mt-2 text-slate-500 text-sm">
                  No extracted data across entire email
                </span>
              )}
            </div>
          );
        })}
    </div>
  );
}
