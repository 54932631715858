import { clsx } from 'clsx';
import React, {
  forwardRef,
  type HTMLAttributes,
  type JSX,
  type CSSProperties,
} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  value?: string | JSX.Element | JSX.Element[];
  height?: number;
  labelClassName?: string;
  valueClassName?: string;
  valueStyle?: CSSProperties;
  labelStyle?: CSSProperties;
  style?: CSSProperties;
  isSelect?: boolean;
  hideSpace?: boolean;
}

function GrayedOutInput({
  label = '',
  value = '',
  height,
  labelClassName,
  valueClassName,
  isSelect,
  hideSpace,
  ...props
}: Props) {
  return (
    <div
      className={clsx(
        props.className,
        'bg-gray-100 p-2 rounded-t-lg border-b border-dotted border-b-gray-400 border-b-dotted',
      )}
      style={
        height
          ? {
              maxHeight: height,
              minHeight: height,
              position: 'relative',
              ...props.style,
            }
          : { position: 'relative', ...props.style }
      }
    >
      {label ? (
        <p
          className={clsx(labelClassName, 'text-xs text-gray-500 mb-1')}
          style={{
            ...props.labelStyle,
          }}
        >
          {label}
        </p>
      ) : null}

      <p
        className={clsx(
          valueClassName,
          'text-normal text-gray-600 min-h-[1rem] leading-6',
        )}
        style={{
          ...props.valueStyle,
        }}
      >
        {value}
        {hideSpace ? null : <>&nbsp;</>}
      </p>
      {isSelect ? (
        <ArrowDropDownIcon
          className="text-gray-500"
          style={{ position: 'absolute', right: 5, top: '40%' }}
        />
      ) : null}
    </div>
  );
}

export default forwardRef(GrayedOutInput);
