import { isNil } from 'lodash';
import { isUUID } from '../pages/Editor/utils/helper';
import { isAdmin } from './env';

class PersistedStorage {
  private _storage: Storage;

  constructor() {
    this._storage = window.localStorage;
  }

  getAll(): string[] {
    return Object.keys(this._storage).filter(
      (key) => !isNil(this._storage.getItem(key)),
    );
  }

  get(key: string): string | null {
    return this._storage.getItem(key);
  }

  delete(key: string): void {
    this._storage.removeItem(key);
  }

  clear(): void {
    this._storage.clear();
  }

  _deleteOldestWorkflow(): void {
    const oldestWorkflow = this.getAll() // gets list of all items saved in local storage
      .filter((key) => isUUID(key)) // filters out items that are not saved workflows
      .sort() // sorts the list of workflow ids in ascending order
      .reverse()[0]; // gets the oldest workflow id
    if (oldestWorkflow) {
      this.delete(oldestWorkflow);
    }
  }

  set(key: string, value: string): void {
    try {
      this._storage.setItem(key, value);
    } catch (error) {
      if (
        error instanceof DOMException &&
        error.name === 'QuotaExceededError' &&
        isAdmin
      ) {
        this._deleteOldestWorkflow();
        this.set(key, value);
      }
    }
  }
}

export const storage = new PersistedStorage();
