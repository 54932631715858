import { type TypedDocumentNode, useMutation, useQuery } from '@apollo/client';
import { graphql } from 'hasura-gql';
import { AlertVariant, notify } from 'ui-kit';

const GET_WORKFLOW_CONCURRENCY_LIMIT = graphql(`
  query GET_WORKFLOW_CONCURRENCY_LIMIT($workflowId: uuid!) {
    workflows(where: { id: { _eq: $workflowId } }) {
      maxConcurrentExecutions
    }
  }
`) as TypedDocumentNode<
  { workflows?: { maxConcurrentExecutions: number }[] },
  { workflowId: string }
>;

export function useGetWorkflowConcurrencyLimit(workflowId: string) {
  const { data, loading, error } = useQuery(GET_WORKFLOW_CONCURRENCY_LIMIT, {
    variables: { workflowId },
    fetchPolicy: 'network-only',
  });
  return {
    data: data?.workflows?.[0]?.maxConcurrentExecutions,
    loading,
    error,
  };
}

const SET_WORKFLOW_CONCURRENCY_LIMIT = graphql(`
  mutation SET_WORKFLOW_CONCURRENCY_LIMIT($workflowId: uuid!, $limit: Int) {
    update_workflows(
      where: { id: { _eq: $workflowId } }
      _set: { maxConcurrentExecutions: $limit }
    ) {
      returning {
        id
      }
    }
  }
`) as TypedDocumentNode<
  { update_workflows?: { returning: unknown } },
  { workflowId: string; limit: number | null }
>;

export function useUpdateWorkflowConcurrentLimit() {
  const [mutate, { loading, error }] = useMutation(
    SET_WORKFLOW_CONCURRENCY_LIMIT,
  );

  const mutateAsync = async (variables: {
    workflowId: string;
    limit: number | null;
  }) => {
    await mutate({
      variables,
    });
    notify({
      message: 'Workflow concurrency limit updated',
      variant: AlertVariant.SUCCESS,
    });
  };

  return { mutateAsync, loading, error };
}
