import CircularProgress from '@mui/material/CircularProgress';
import { Switch } from 'ui-kit';
import { EXECUTIONS_LIST_REFETCH_INTERVAL } from '../utils/constants';

interface Props {
  timeToRefresh: number;
  autoRefresh: boolean;
  setAutoRefresh: (value: boolean) => void;
  setTimeToRefresh: (value: number) => void;
}

export default function ExecutionsRefetch({
  timeToRefresh,
  setTimeToRefresh,
  autoRefresh,
  setAutoRefresh,
}: Props) {
  return (
    <div className="flex flex-row items-center gap-2">
      <span className="text-sm text-gray-500 min-w-max">Auto-updating</span>
      <Switch
        color="secondary"
        checked={autoRefresh}
        onChange={() => {
          if (!autoRefresh) {
            setTimeToRefresh(EXECUTIONS_LIST_REFETCH_INTERVAL);
          }
          setAutoRefresh(!autoRefresh);
        }}
      />
      {autoRefresh ? (
        <CircularProgress
          color="secondary"
          size={20}
          thickness={5}
          sx={{ scale: '-1 1' }}
          variant="determinate"
          value={((20 - timeToRefresh) / 20) * 100}
        />
      ) : null}
    </div>
  );
}
