import Alert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { type GridColDef } from '@mui/x-data-grid/models';
import {
  MoreVert,
  TrashIcon,
  Button,
  ContentContainer,
  IconButton,
  Menu,
  MenuItem,
  modalEventChannel,
  ScrollableContainer,
  Tooltip,
  CustomTypography,
} from 'ui-kit';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { type ApiKey } from 'types-shared/userTypes';
import { ApiKeysManagerSkeleton } from '../components/ApiKeysManagerSkeleton';
import { useFetchApiKeysList, useDeleteApiKey } from '../hooks';
import { FeatureFlag } from '../../../utils/constants';
import { NavigationPanel } from '../../../components/NavigationPanel';
import { NoDataFound } from '../../../components/NoDataFound';
import { useFeatureFlag } from '../../../utils/helper';
import { getTabTitle } from '../../../utils/tabTitle';
import { isAdmin } from '../../../utils/env';

interface MenuProps extends ApiKey {
  anchorEl: HTMLButtonElement;
}

export default function ApiKeysManager() {
  const navigate = useNavigate();
  const { data, isLoading } = useFetchApiKeysList();
  const { mutateAsync: deleteApiKey } = useDeleteApiKey();
  const [menuProps, setMenuProps] = useState<MenuProps | null>(null);

  const apiKeysManagerPageEnabled = useFeatureFlag(FeatureFlag.ApiKeysManager);

  const closeMenu = () => {
    setMenuProps(null);
  };

  const onDelete = () => {
    closeMenu();
    modalEventChannel.emit('open', {
      title: 'Delete API Key',
      descriptions: [
        `This API Key will immediately be disabled.
        API requests made using this key will be rejected,
        which could cause dependent workflows to break.
        Once revoked, you will no longer be able to view or modify this API Key.`,
      ],
      actions: [
        {
          text: 'Delete API Key',
          onClick: () => {
            if (menuProps?.keyId) {
              void deleteApiKey(menuProps.keyId);
              modalEventChannel.emit('close');
            }
          },
          color: 'error',
        },
        {
          text: 'Cancel',
          variant: 'text',
          onClick: () => {
            modalEventChannel.emit('close');
          },
        },
      ],
      children: menuProps?.name ? (
        <div className="mb-8 mt-6">
          <p className="text-sx text-slate-500">Name</p>
          <p className="text-cyan-900 text-base leading-loose tracking-tight">
            {menuProps.name}
          </p>
        </div>
      ) : undefined,
    });
  };

  const columns: GridColDef<ApiKey>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 4,
      },
      {
        field: 'action',
        headerName: '',
        align: 'right',
        renderCell: (params) => (
          <span className="flex items-center gap-4">
            <Link
              className="px-3 py-1.5 uppercase rounded border border-primary-light-blue text-primary-light-blue font-medium text-sm"
              to={`/api-keys-manager/edit/${params.row.keyId}`}
            >
              Edit
            </Link>
            <IconButton
              className="!p-0"
              onClick={(e) => {
                setMenuProps({
                  anchorEl: e.target as HTMLButtonElement,
                  ...params.row,
                });
              }}
            >
              <MoreVert className="text-secondary-blue" />
            </IconButton>
          </span>
        ),
        flex: 1,
        sortable: false,
      },
    ],
    [],
  );

  const addDisabled = (data?.length ?? 0) >= 5;

  useEffect(() => {
    if (apiKeysManagerPageEnabled === false) {
      navigate('/');
    }
  }, [apiKeysManagerPageEnabled, navigate]);

  useEffect(() => {
    document.title = getTabTitle('API Keys', isAdmin);
  }, []);

  return (
    <ScrollableContainer>
      <div className="w-full h-full flex">
        <NavigationPanel />

        <ContentContainer>
          <div className="flex justify-between w-full items-center">
            <div className="w-4/6">
              <CustomTypography variant="h4">API Keys</CustomTypography>
              <div className="mt-2">
                <span className="font-normal text-sm">
                  View and manage all API Keys for this account.
                </span>
              </div>
            </div>
            <Link to="/api-keys-manager/create">
              <Tooltip
                title={
                  addDisabled
                    ? 'You can only have 5 API Keys at a time.'
                    : undefined
                }
              >
                <Button
                  color="secondary"
                  disabled={addDisabled}
                  variant="contained"
                >
                  Create API Key
                </Button>
              </Tooltip>
            </Link>
          </div>
          <div className="mt-2">
            <Alert
              severity="warning"
              classes={{ root: 'items-start !py-4', message: '!p-0 !my-auto' }}
            >
              Do not share your API Key with others, or expose it in the browser
              or other client-side code. In order to protect the security of
              your account, Sola may also automatically disable any API Key that
              has leaked publicly.
            </Alert>
          </div>
          <div className="mt-11">
            {isLoading ? (
              <ApiKeysManagerSkeleton columns={columns} />
            ) : (
              <>
                {data?.length ? (
                  <DataGrid
                    columns={columns}
                    getRowId={(row) => row.keyId}
                    hideFooter
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'createdAt', sort: 'desc' }],
                      },
                    }}
                    rows={data}
                  />
                ) : (
                  <NoDataFound
                    heading="You don’t have API Keys yet"
                    subHeading="You don’t have API Keys yet. Click on create API Key button to add a new API Key."
                  />
                )}
              </>
            )}
          </div>
        </ContentContainer>
      </div>
      <Menu
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
        anchorEl={menuProps?.anchorEl ?? null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={closeMenu}
        open={Boolean(menuProps)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem className="group min-w-72 h-12" onClick={onDelete}>
          <span className="font-normal leading-6 group-hover:text-red-500">
            Delete
          </span>
          <TrashIcon className="!ml-auto text-cyan-900 group-hover:text-red-500" />
        </MenuItem>
      </Menu>
    </ScrollableContainer>
  );
}
